import * as React from 'react';
import { useContext } from 'react';

import { Box, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ThemeProvider } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import { getLanguageObj } from '../../System/Locales';
import { RouteContext } from '../../System/RouteData';
import { SessionContext } from '../../System/Session';

import RouteUpload from '../../System/RouteUpload';
import { themeBtnAll, typeImportDialogOpen } from './RouteEditorColumnLeft';

type typeFileInfo = {
    filename:string;
    data:any;
}

export const fileInfo:typeFileInfo = {
    filename:'',
    data:{}
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RouteEditorImport(
  {dialogOpen,setImportDialogOpen,t}:
  {dialogOpen:typeImportDialogOpen,setImportDialogOpen:React.Dispatch<React.SetStateAction<typeImportDialogOpen>>,t:any}) {
  const {sessionInfo} = useContext(SessionContext);
  const {setPointComp} = React.useContext(RouteContext);
  const GoogleFonts = getLanguageObj(sessionInfo.language);

  const handleAgree = (agree:boolean) => {
    if(agree){
      setImportDialogOpen({
        open:true,
        imported:false,
        confirm:false
    })
    }else{
      setImportDialogOpen({
        open:false,
        imported:false,
        confirm:false
      })
    }
  }

  const handleClose = (clicked:boolean) => {
    if(clicked){
      setImportDialogOpen({
        open:false,
        imported:false,
        confirm:false
      })
    }
  }

  React.useEffect(() => {
    // console.log(fileData)
  },[]);

  return (
    <React.Fragment>

      <Dialog
        open={(dialogOpen.open && !dialogOpen.confirm)}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>handleClose(false)}
      >
        <DialogTitle><span className={GoogleFonts.className}>{t('routeEditor.dialog.import.title')}</span></DialogTitle>
        <DialogContent>
          <Box sx={{width:'100%',padding:'20px 60px 0px 60px'}}>
            <ThemeProvider theme={themeBtnAll}>
              <RouteUpload setPointComp={setPointComp} setImportDialogOpen={setImportDialogOpen} />
            </ThemeProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(true)}><span className={GoogleFonts.className}>{t('routeEditor.dialog.import.Cancel')}</span></Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={(dialogOpen.open && dialogOpen.confirm)}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>handleAgree(false)}
      >
        <DialogTitle><span className={GoogleFonts.className}>{t('routeEditor.dialog.import.confirmTitle')}</span></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className={GoogleFonts.className}>{t('routeEditor.dialog.import.confirm')}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleAgree(false)}><span className={GoogleFonts.className}>{t('routeEditor.dialog.import.confirmNo')}</span></Button>
          <Button onClick={()=>handleAgree(true)}><span className={GoogleFonts.className}>{t('routeEditor.dialog.import.confirmYes')}</span></Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
}