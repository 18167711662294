import { FormControl, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import * as React from 'react';
import { useContext } from 'react';

import "/node_modules/flag-icons/css/flag-icons.min.css";

import { SessionContext, baseUri, sessionIO } from '../System/Session';

export const locales = [
    'en',
    'it',
    'ja'
];

export type typeEachLnag = {
    code:string;
    disp:string;
    icon:string;
    className:string; // for Google Fonts
    // selected:boolean;
}
export function pulldownObject(selected = 'en'){
    let arr:typeEachLnag[] = [];
    for(let i=0;i<locales.length;i++){
        let disp = '';
        let locale = locales[i];
        let icon = '';
        let className = '';
        switch (locale){
            case 'en':{ // 1
                disp = 'English';
                icon = 'gb';
                className = 'font-en-cycroute';
                break;
            }
            case 'ja':{ // 2
                disp = '日本語';
                icon = 'jp';
                className = 'font-ja-cycroute';
                break;
            }
            case 'it':{ // 3
                disp = 'Italiano';
                icon = 'it';
                className = 'font-en-cycroute';
                break;
            }
            case 'fr':{ // 4
                disp = 'Français';
                icon = 'fr';
                className = 'font-en-cycroute';
                break;
            }
            case 'es':{ // 5
                disp = 'Español';
                icon = 'es';
                className = 'font-en-cycroute';
                break;
            }
            case 'de':{ // 6
                disp = 'Deutsch';
                icon = 'de';
                className = 'font-en-cycroute';
                break;
            }
            case 'pt':{ // 7
                disp = 'Português';
                icon = 'pt';
                className = 'font-en-cycroute';
                break;
            }
            case 'ru':{ // 8
                disp = 'Русский';
                break;
            }
            case 'ar':{ // 9
                disp = 'العربية';
                break;
            }
            case 'zh':{ // 10
                disp = '中文';
                break;
            }
            default:{
                break;
            }
        }
        if(disp !== ''){
            className = (className === '')?'josefin-sans-cycroute':className;
            arr.push({code:locale,disp:disp,icon:icon,className:className});    
        }
    }
    return arr;
}

export function getLanguageObj(lang:string = 'en'){
    let arr = pulldownObject();
    let obj:typeEachLnag = {
        code:'en',
        disp:'English',
        icon:'gb',
        className:'font-en-cycroute'
    };
    for(let i=0;i<arr.length;i++){
        if(arr[i].code === lang){
            obj = {code:arr[i].code,disp:arr[i].disp,icon:arr[i].icon,className:arr[i].className};
        }
    }
    return obj;
}

const localName = 'lang';

export function detectLang(){
    let langOnLocal = window.localStorage.getItem(localName);
    let lang = langOnLocal??(navigator.language??'en');
    let temp = lang.split('-');
    if(temp.length > 1){
        lang = temp[0];
    }
    return lang;
}

export function detectUnit(){
    let langOnLocal = window.localStorage.getItem(localName);
    let lang = langOnLocal??(navigator.language??'en');
    let unit = 'km';
    if((lang==='en-GB')||(lang==='en-US')){
        unit = 'mile';
    }
    return unit;
}

export function setLang(lang:string){
    window.localStorage.setItem(localName,lang);
}

//フォント(言語)ごとの上下位置微調整
export function adjustFontPosition(lang:string){
    const isAlphabetic = 
    (lang === 'en') ||
    (lang === 'it');
    const isJapanized = (lang === 'ja');
    const styleBtnText = {
        position:'relative',
        top:isAlphabetic?'2px':isJapanized?'-1px':'0px'
    } as React.CSSProperties;
    return styleBtnText;
}

const GoogleFontEn = getLanguageObj('en');
const GoogleFontJp = getLanguageObj('ja');
export const GoogleSeriousFontClassName = 'font-en-serious-cycroute';

//言語ごとのフォント指定
export function fontDetect(language:string){
    const GoogleFonts:typeEachLnag = 
    (language === 'ja')?GoogleFontJp:
    (language === 'en')?GoogleFontEn:GoogleFontEn;
    return GoogleFonts;
}

//言語選択プルダウン
export function LanguagePulldown(){
    const {sessionInfo,setSessionInfo} = useContext(SessionContext);
    const pulldown = pulldownObject(sessionInfo.language);
    const GoogleFontEn = getLanguageObj('en');
    const GoogleFontJp = getLanguageObj('ja');
    const handleChangeLanguage = (event:SelectChangeEvent) =>{
        let language = event.target.value;
        setLang(language);
        setSessionInfo((prev)=>({...prev,...{language:language}}));
        if(sessionInfo.hasSession){
            let mySession = new sessionIO();
            let req = baseUri + "setlanguage?language=" + encodeURIComponent(language);
            fetch(
                req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                // console.log('content : ',content);
            }).catch(err => {
                console.log('err : ',err);
            }).finally(() => {
            });
        }
    }
    return (
        <React.Fragment>
            <FormControl fullWidth sx={{color:'#000000',backgroundColor:'transparent'}} size="small">
                <Select label="" value={sessionInfo.language} sx={{backgroundColor:'#ffffff'}} inputProps={{MenuProps:{MenuListProps:{sx:{color:'#000000',backgroundColor:'#ffffff'}}}}} onChange={handleChangeLanguage}>
                    {pulldown.map((one,index) => {return (
                        <MenuItem key={index} value={one.code} sx={{color:'#000000',backgroundColor:'#ffffff'}}><span className={`fi fi-${one.icon}`}></span>&nbsp;
                            {(one.code === 'en') && 
                                <span className={GoogleFontEn.className}>{one.disp}</span>
                            }
                            {(one.code === 'ja') && 
                                <span className={GoogleFontJp.className}>{one.disp}</span>
                            }
                            {((one.code !== 'en')&&(one.code !== 'ja')) && 
                                <span className={GoogleFontEn.className}>{one.disp}</span>
                            }
                        </MenuItem>
                    )})}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}

const locationList = {
    mtButtertubs:{lat:54.36009265242106,lng:-2.1951733318612345}, //UK
    mtFuji:{lat:35.36062630809333,lng:138.72736159097892}, //Japan
    mtGavia:{lat:46.343545732981745, lng:10.484848478139421}, //Italia
}

export function getDefaultLocation(){
    let myLocation = {lat:0,lng:0};
    const location = (navigator.language??'en');
    if(location === 'en-GB'){
        myLocation = locationList.mtButtertubs
    }else if(location.startsWith('ja')){
        myLocation = locationList.mtFuji
    }else if(location.startsWith('it')){
        myLocation = locationList.mtGavia
    }else{
        myLocation = locationList.mtButtertubs
    }
    return myLocation;
}

//タイムゾーン選択プルダウン
export function TimezonePulldown(){
    const {sessionInfo,setSessionInfo} = useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    // const GoogleFontEn = getLanguageObj('en');
    // const GoogleFontJp = getLanguageObj('ja');
    const [timezones, setTimezones] = React.useState<any[]>([]);
    const handleChangeTimezone = (event:SelectChangeEvent) => {
        let timezone = event.target.value;
        setSessionInfo((prev)=>({...prev,...{timezone:timezone}}));
        if(sessionInfo.hasSession){
            let mySession = new sessionIO();
            let req = baseUri + "settimezone?timezone=" + encodeURIComponent(timezone);
            fetch(
                req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                // console.log('content : ',content);
            }).catch(err => {
                console.log('err : ',err);
            }).finally(() => {
            });
        }

    }
    React.useEffect(()=>{
        const tzArray = moment.tz.names();
        setTimezones(tzArray);
    },[]);
    return (
        <React.Fragment>
            <FormControl fullWidth sx={{color:'#000000',backgroundColor:'transparent'}} size="small">
                {timezones.length > 0 &&
                    <Select label="" value={sessionInfo.timezone} sx={{backgroundColor:'#ffffff'}} inputProps={{MenuProps:{MenuListProps:{sx:{color:'#000000',backgroundColor:'#ffffff'}}}}} onChange={handleChangeTimezone}>
                        {timezones.map((timezone, index) => (
                            <MenuItem key={index} value={timezone}>
                                <span className={GoogleFonts.className}>{timezone}</span>
                            </MenuItem>
                        ))}
                    </Select>
                }
            </FormControl>
        </React.Fragment>
    )
}

const datetimeFormatArr = [
    "dd-MM-yyyy HH:mm",
    "yyyy-MM-dd HH:mm",
    "MM-dd-yyyy HH:mm",
    "MMMM d, yyyy 'at' HH:mm"
];
const dateFormatArr = [
    "dd-MM-yyyy",
    "yyyy-MM-dd",
    "MM-dd-yyyy",
    "MMMM dd, yyyy"
];
const dayjsFormatArr = [
    "DD-MM-YYYY",
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "MMMM DD, YYYY"
];
export function datetimeFormatToDayjsFormat(input:String){
    let index = datetimeFormatArr.findIndex((element)=>{
        return element === input;
    });
    if(index < 0){
        index = 1
    }
    return dayjsFormatArr[index]
}

export function datetimeFormatToDateFormat(input:String){
    let index = datetimeFormatArr.findIndex((element)=>{
        return element === input;
    });
    if(index < 0){
        index = 1
    }
    return dateFormatArr[index]
}

export function getDefaultDatetimeFormat(){
    let defFormat = "yyyy-MM-dd HH:mm";
    const location = (navigator.language??'en');
    if(location === 'en-GB'){
        defFormat = "dd-MM-yyyy HH:mm"
    }else if(location === 'en-US'){
        defFormat = "MMMM d, yyyy 'at' HH:mm"
    }else if(location.startsWith('ja')){
        defFormat = "yyyy-MM-dd HH:mm"
    }else if(location.startsWith('it')){
        defFormat = "dd-MM-yyyy HH:mm"
    }else{
        defFormat = "dd-MM-yyyy HH:mm"
    }
    return defFormat;
}

function getDateFormatWithSample(){
    let formatArr:any[] = [];
    const now = new Date();
    for(let i=0;i<datetimeFormatArr.length;i++){
        formatArr.push({
            format:datetimeFormatArr[i],
            sample:format(now,datetimeFormatArr[i])
        })
    }
    return formatArr;
}

//日時フォーマット選択プルダウン
export function DatetimeFormatPulldown(){
    const {sessionInfo,setSessionInfo} = useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    // const GoogleFontEn = getLanguageObj('en');
    // const GoogleFontJp = getLanguageObj('ja');
    const formatArr = getDateFormatWithSample();
    const handleChangeTimezone = (event:SelectChangeEvent) => {
        let newFormat = event.target.value;
        setSessionInfo((prev)=>({...prev,...{datetimeFormat:newFormat}}));
        if(sessionInfo.hasSession){
            let mySession = new sessionIO();
            let req = baseUri + "setdatetimeformat?datetimeformat=" + encodeURIComponent(newFormat);
            fetch(
                req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                // console.log('content : ',content);
            }).catch(err => {
                console.log('err : ',err);
            }).finally(() => {
            });
        }
    }
    return (
        <React.Fragment>
            <FormControl fullWidth sx={{color:'#000000',backgroundColor:'transparent'}} size="small">
                <Select label="" value={sessionInfo.datetimeFormat} sx={{backgroundColor:'#ffffff'}} inputProps={{MenuProps:{MenuListProps:{sx:{color:'#000000',backgroundColor:'#ffffff'}}}}} onChange={handleChangeTimezone}>
                {formatArr.map((one, index) => (
                    <MenuItem key={index} value={one.format}>
                        <span className={GoogleFonts.className}>{one.format} ({one.sample})</span>
                    </MenuItem>
                ))}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}