import { isMobile, isTablet } from "react-device-detect";

const border = '1px solid #acacac'

export const styleOuter = {
    zIndex:800,
    margin:0,
    padding:0,
    position:'relative',
    // backgroundColor:'#ffffff'
};

export const styleInner = {
    margin:0,
    overflowY:'auto',
    overscrollBehaviorY:'none',
    position:'absolute',
    left:0,top:0,bottom:0,
    backgroundColor:'#ffffff'
};

export const styleInnerLeft = {...styleInner,...{
    borderRight:border,
    padding:(isMobile || isTablet)?'0px 6px 5px 5px':'5px 6px 5px 5px',
    // minHeight:'593px'
}};

export const styleInnerRight = {...styleInner,...{
    borderLeft:border,
    padding:(isMobile || isTablet)?'0px 5px 5px 6px':'5px 5px 5px 6px',
}};