//マーカーおよびキューシートアイコンや
//ポイント追加ダイアログのプルダウンや
//ルートのmodifier判定などはここで一元管理しています。
import L from 'leaflet';
import 'leaflet.awesome-markers';
//↓必ず呼び出し元でimportしておくこと ( ここで読み込むと遅い )
// import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
// import './fontawesome.css';

//Font Awesomeアイコンの接頭文字指定
L.AwesomeMarkers.Icon.prototype.options.prefix = 'fa';

 //対応カラー : "green" | "red" | "darkgreen" | "darkred" | "orange" | "blue" | "purple" | "darkpurple" | "cadetblue"
const arrowColor = 'cadetblue';

const leftMarker = L.AwesomeMarkers.icon({icon:'arrow-left',markerColor:arrowColor});
const rightMarker = L.AwesomeMarkers.icon({icon:'arrow-right',markerColor:arrowColor});
const upMarker = L.AwesomeMarkers.icon({icon:'arrow-up',markerColor:arrowColor});
const uturnMarker = L.AwesomeMarkers.icon({icon:'rotate-right',markerColor:arrowColor});

const startMarker = L.AwesomeMarkers.icon({icon:'play',markerColor:'green'});
const endMarker = L.AwesomeMarkers.icon({icon:'circle',markerColor:'blue'});

const otherColor = 'orange';
const otherFontColor = '#000000';

const climbMarker = L.AwesomeMarkers.icon({icon:'mountain',markerColor:otherColor,iconColor:otherFontColor});
const foodMarker = L.AwesomeMarkers.icon({icon:'utensils',markerColor:otherColor,iconColor:otherFontColor});
const informationMarker = L.AwesomeMarkers.icon({icon:'info',markerColor:otherColor,iconColor:otherFontColor});
const restMarker = L.AwesomeMarkers.icon({icon:'bed',markerColor:otherColor,iconColor:otherFontColor});
const dangerMarker = L.AwesomeMarkers.icon({icon:'skull-crossbones',markerColor:otherColor,iconColor:otherFontColor});
const otherMarker = L.AwesomeMarkers.icon({icon:'exclamation',markerColor:otherColor,iconColor:otherFontColor});
// const elevationMarker = L.AwesomeMarkers.icon({icon:'caret-down',markerColor:otherColor,iconColor:otherFontColor});
// const elevationMarker = L.AwesomeMarkers.icon({icon:'chevron-down',markerColor:otherColor,iconColor:otherFontColor});
// const elevationMarker = L.AwesomeMarkers.icon({icon:'down-long',markerColor:otherColor,iconColor:otherFontColor});
// const elevationMarker = L.AwesomeMarkers.icon({icon:'face-smile-wink',markerColor:otherColor,iconColor:otherFontColor});
const elevationMarker = L.AwesomeMarkers.icon({icon:undefined,markerColor:otherColor,iconColor:otherFontColor});

//マーカーのアイコン
export const customMarkerIcon = (type:string) => {
    if(type === 'left'){ return leftMarker }
    else if(type === 'right'){ return rightMarker }
    else if(type === 'straight'){ return upMarker }
    else if(type === 'uturn'){ return uturnMarker }
    else if(type === 'start'){ return startMarker }
    else if(type === 'end'){ return endMarker }
    else if(type === 'climb'){ return climbMarker }
    else if(type === 'food'){ return foodMarker }
    else if(type === 'information'){ return informationMarker }
    else if(type === 'rest'){ return restMarker }
    else if(type === 'danger'){ return dangerMarker }
    else if(type === 'elevation'){ return elevationMarker }
    else{ return otherMarker }
}

//キューシートのアイコン
export function CueIcon({type}:{type?:string}){
    if(type === 'left'){ return <i className="fa-solid fa-arrow-left"></i> }
    else if(type === 'right'){ return <i className="fa-solid fa-arrow-right"></i> }
    else if(type === 'straight'){ return <i className="fa-solid fa-arrow-up"></i> }
    else if(type === 'uturn'){ return <i className="fa-solid fa-rotate-right"></i> }
    else if(type === 'start'){ return <i className="fa-solid fa-play"></i> }
    else if(type === 'end'){ return <i className="fa-solid fa-circle"></i> }
    else if(type === 'climb'){ return <i className="fa-solid fa-mountain"></i> }
    else if(type === 'food'){ return <i className="fa-solid fa-utensils"></i> }
    else if(type === 'information'){ return <i className="fa-solid fa-info"></i> }
    else if(type === 'rest'){ return <i className="fa-solid fa-bed"></i> }
    else if(type === 'danger'){ return <i className="fa-solid fa-skull-crossbones"></i> }
    else{ return <i className="fa-solid fa-exclamation"></i> }
}

//内部的 "modifier" isTurn = true
export const modifierListTurn:string[] = [
    "left","right","straight","uturn"
];

//内部的 "modifier" isTurn = false
export const modifierListExt:string[] = [
    "climb","food","information","rest","danger","other"
];

//内部的 "modifier" 全マージ
export const modifierList:string[] = [
    ...modifierListTurn,...modifierListExt
];

//isTurn判定処理
export function detectIsTurn(modifier:string){
    let newIsTurn = false;
    let newModifier = '';
    modifierListTurn.forEach((one)=>{
        if(modifier.includes(one)){
            newIsTurn = true;
            newModifier = one;
        }
    })
    return {newIsTurn,newModifier};
}