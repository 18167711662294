import Dexie, { Table } from 'dexie';
import { typeMapContainerLatLng, typePublicRoute } from '../System/PublicData';
import { typePointComp } from '../System/RouteData';
import { typeRoute, typeSearch } from '../UI/MyRoutes/MyRoutes';

export const DBenabled = true;

export const dbname = 'CycrouteDB';

//ルートエディタ用のkey
const editorKey = 'editor';

//マイルート用のkey
const myroutesKey = {
    list:'list',
    search:'search'
}

//公開ルート用のkey
const publicKey = {
    list:'list',
    search:'search',
    bound:'bound',
    selected:'selected'
}

export interface Points {
    key: string;
    data: any;
}

export class MySubClassedDexie extends Dexie {
    points!: Table<Points>; //ルートエディタ用
    myroures!: Table<Points>; //マイルート用
    public!: Table<Points>; //マイルート用
    constructor() {
        super(dbname);
        this.version(1).stores({
            points: 'key',
            myroures: 'key',
            public: 'key'
        });
    }
}

export const db = new MySubClassedDexie();

//ルートエディタのデータ保存
export async function saveDataToDB(data:any){
    let result = false;
    try{
        const lastPoints = await db.points.get(editorKey);
        if(lastPoints){
            await db.points.put({key: editorKey,data});
        }else{
            await db.points.add({key: editorKey,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//ルートエディタのデータ取得
export async function getDataFromDB(setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>) {
    const points = await db.points.where({key:editorKey}).first();
    if(points){
        setPointComp(points?.data as typePointComp);
    }
}

//ルートエディタのデータクリア
export function removeDataFromDB() {
    db.points.clear();
}

//マイルート一覧の一時保管
export async function keepMyRoutesListToDB(data:any){
    let result = false;
    try{
        const myRouresList = await db.myroures.get(myroutesKey.list);
        if(myRouresList){
            await db.myroures.put({key: myroutesKey.list,data});
        }else{
            await db.myroures.add({key: myroutesKey.list,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//マイルート一覧のデータ取得 ( ここでsetまではやらないので注意 )
export async function getMyRoutesListFromDB() {
    const myRouresList = await db.myroures.where({key:myroutesKey.list}).first();
    if(myRouresList){
        return myRouresList?.data as typeRoute[]|null;
    }else{
        return null;
    }
}

//マイルート一覧検索条件の一時保管
export async function keepMyRoutesSearchToDB(data:any){
    let result = false;
    try{
        const myRouresSearch = await db.myroures.get(myroutesKey.search);
        if(myRouresSearch){
            await db.myroures.put({key: myroutesKey.search,data});
        }else{
            await db.myroures.add({key: myroutesKey.search,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//マイルート一覧検索条件のデータ取得 ( ここでsetまではやらないので注意 )
export async function getMyRoutesSearchFromDB() {
    const myRouresSearch = await db.myroures.where({key:myroutesKey.search}).first();
    if(myRouresSearch){
        return myRouresSearch.data as typeSearch;
    }else{
        return null;
    }
}

//マイルート一覧のKEEP指定更新
export async function setMyRoutesSearchKeepFlag(FLG_KEEP:boolean) {
    const myRouresSearch = await db.myroures.where({key:myroutesKey.search}).first();
    if(myRouresSearch){
        let FLG_SET = FLG_KEEP;
        if(FLG_KEEP){
            // const temp = myRouresSearch.data as typeSearch;
            let diffMilliSecond = new Date().getTime() - (myRouresSearch.data as typeSearch).time;
            if(diffMilliSecond < (5 * 60 * 1000)){ //検索時から5分以内であればfetchしないで一覧に戻る
                FLG_SET = true;
            }else{
                FLG_SET = false;
            }
        }
        const data = {...myRouresSearch.data as typeSearch,...{keep:FLG_SET}} as any;
        await db.myroures.put({key: myroutesKey.search,data});
    }
}

//公開ルート地図検索の四角座標情報一時保管
export async function keepPublicMapBoundToDB(data:any){
    let result = false;
    try{
        const publicRouresBound = await db.public.get(publicKey.bound);
        if(publicRouresBound){
            await db.public.put({key: publicKey.bound,data});
        }else{
            await db.public.add({key: publicKey.bound,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//公開ルート地図検索の四角座標情報のデータ取得 ( ここでsetまではやらないので注意 )
export async function getPublicMapBoundFromDB() {
    const publicRouresBound = await db.public.where({key:publicKey.bound}).first();
    if(publicRouresBound){
        return publicRouresBound?.data as typeMapContainerLatLng;
    }else{
        return null;
    }
}

//公開ルート選択情報の一時保管
export async function keepPublicSelectedToDB(data:any){
    let result = false;
    try{
        const publicRouresSelected = await db.public.get(publicKey.selected);
        if(publicRouresSelected){
            await db.public.put({key: publicKey.selected,data});
        }else{
            await db.public.add({key: publicKey.selected,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//公開ルート選択情報のデータ取得 ( ここでsetまではやらないので注意 )
export async function getPublicSelectedFromDB() {
    const publicRouresSelected = await db.public.where({key:publicKey.selected}).first();
    if(publicRouresSelected){
        return publicRouresSelected?.data as number|null;
    }else{
        return null;
    }
}

//公開ルート一覧の一時保管
export async function keepPublicRoutesListToDB(data:any){
    let result = false;
    try{
        const publicRouresList = await db.public.get(publicKey.list);
        if(publicRouresList){
            await db.public.put({key: publicKey.list,data});
        }else{
            await db.public.add({key: publicKey.list,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//公開ルート一覧のデータ取得 ( ここでsetまではやらないので注意 )
export async function getPublicRoutesListFromDB() {
    const publicRouresList = await db.public.where({key:publicKey.list}).first();
    if(publicRouresList){
        return publicRouresList?.data as typePublicRoute[]|null;
    }else{
        return null;
    }
}

//公開ルート一覧検索条件の一時保管
export async function keepPublicRoutesSearchToDB(data:any){
    let result = false;
    try{
        const publicRouresSearch = await db.public.get(publicKey.search);
        if(publicRouresSearch){
            await db.public.put({key: publicKey.search,data});
        }else{
            await db.public.add({key: publicKey.search,data});
        }
        result = true;
    }catch(error){
        console.log(error);
    }
    return result;
}

//公開ルート一覧検索条件のデータ取得 ( ここでsetまではやらないので注意 )
export async function getPublicRoutesSearchFromDB() {
    const publicRouresSearch = await db.public.where({key:publicKey.search}).first();
    if(publicRouresSearch){
        return publicRouresSearch.data as typeSearch;
    }else{
        return null;
    }
}

//公開ルート一覧のKEEP指定更新
export async function setPublicRoutesSearchKeepFlag(FLG_KEEP:boolean) {
    const publicRouresSearch = await db.public.where({key:publicKey.search}).first();
    if(publicRouresSearch){
        let FLG_SET = FLG_KEEP;
        if(FLG_KEEP){
            // const temp = publicRouresSearch.data as typeSearch;
            let diffMilliSecond = new Date().getTime() - (publicRouresSearch.data as typeSearch).time;
            if(diffMilliSecond < (5 * 60 * 1000)){ //検索時から5分以内であればfetchしないで一覧に戻る
                FLG_SET = true;
            }else{
                FLG_SET = false;
            }
        }
        const data = {...publicRouresSearch.data as typeSearch,...{keep:FLG_SET}} as any;
        await db.public.put({key: publicKey.search,data});
    }
}

//DB削除 ( 主にログアウト用 )
export function removeDB(){
    const deleteRequest = indexedDB.deleteDatabase(dbname);
    deleteRequest.onsuccess = () => {
        console.log(`Database ${dbname} deleted successfully`);
    };
    deleteRequest.onerror = (event) => {
        console.error(`Error deleting database ${dbname}:`, event);
    };
    deleteRequest.onblocked = () => {
        console.warn(`Deletion of database ${dbname} is blocked. Please close all connections.`);
    };
}