import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Box} from '@mui/material';

import {Turnstile} from '@marsidev/react-turnstile'
import type {TurnstileInstance} from '@marsidev/react-turnstile'

import {SessionContext} from './Session';
import {getLanguageObj,adjustFontPosition} from './Locales';
import dummyImage from './turnstile_dummy_ok.png';

const stieKey = '0x4AAAAAAAY7oJyD5nllQHg6';

const retryByttonOuterStyle = {
    marginTop:'1px',
    float:'right'
}
const retryButtonStyle = {
    padding:'6px 24px',
} as React.CSSProperties;

export default function TurnstileWidget({setSuccess}:{setSuccess:React.Dispatch<React.SetStateAction<boolean>>}){

    const [status,setStatus] = React.useState<string>(
        (process.env.REACT_APP_REV==='production')?'before':'solved');

    React.useEffect(()=>{
        setSuccess((status==='solved'));
        // eslint-disable-next-line
    },[status]);

    return (
        <React.Fragment>
            {process.env.REACT_APP_REV==='production' &&
                <TurnstileCommon status={status} setStatus={setStatus} />
            }
            {process.env.REACT_APP_REV!=='production' &&
                <TurnstileDummy />
            }
        </React.Fragment>
    )
}

function TurnstileCommon({status,setStatus}:{status:string,setStatus:React.Dispatch<React.SetStateAction<string>>}){
    const {t} = useTranslation();
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const ref = React.useRef<TurnstileInstance>(null)
  
    const handleRetry = () => {
        setStatus('before')
        ref.current?.reset()
    }
    return (
        <React.Fragment>
            <Box sx={{width:'300px'}}>
                <Turnstile ref={ref} siteKey={stieKey}
                    onError={() => setStatus('error')}
                    onExpire={() => setStatus('expired')}
                    onSuccess={() => setStatus('solved')}
                >
                </Turnstile>
                <Box sx={retryByttonOuterStyle}>
                    <button style={retryButtonStyle} onClick={handleRetry} disabled={(status==='before')}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t('cloudflare.btnRetry')}</span>
                    </button>
                </Box>
            </Box>
        </React.Fragment>
    )
}

function TurnstileDummy(){
    const {t} = useTranslation();
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const dummyDivStyle = {
        position:'relative',
        width:'300px',
        height:'65px',
        lineHeight:'65px',
        textAlign:'center',
        fontSize:'1.2em',
        overflow:'hidden',
        backgroundColor:'#fafafa',
        border:'1px solid #e0e0e0'
    }
    const dummyImgStyle = {
        position:'absolute',top:'16px',left:'10px'
    } as React.CSSProperties;
    return (
        <React.Fragment>
            <Box sx={{width:'300px'}}>
                <Box sx={dummyDivStyle} className={GoogleFonts.className}>
                    <img src={dummyImage} style={dummyImgStyle} alt='dummy pic'/>
                    Dummy object
                </Box>
                <Box sx={retryByttonOuterStyle}>
                    <button style={retryButtonStyle}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t('cloudflare.btnRetry')}</span>
                    </button>
                </Box>
            </Box>
        </React.Fragment>
    )
}
