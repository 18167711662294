import { Divider, Menu, MenuItem, MenuList } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { Popup } from 'react-leaflet';

import { adjustFontPosition, getLanguageObj } from '../../System/Locales';
import { RouteContext } from '../../System/RouteData';
import { SessionContext } from '../../System/Session';

import { saveDefaultLocation, typeMobileContext } from './RouteEditor';
import './RouteEditor.css';

//ロングタップ判定時間 ( ミリ秒 )
export const longTapMilliseconds = 950;

//PC向けコンテキストメニュー ( Polyline上専用 )
export default function RouteEditorContextMenu() {
    const {t} = useTranslation();
    const {sessionInfo,setSessionInfo,setBackdrop} = React.useContext(SessionContext);
    const {contextMenu,setContextMenu,setEditDialog} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整

    const handleAddPoint = () => {
        // console.log("context: " + contextMenu.native.lat + ", " + contextMenu.native.lng);
        setEditDialog((prev)=>({...prev,...{open:false,preopen:true,isNew:true,lat:contextMenu.data.lat,lng:contextMenu.data.lng,parentIdx:-1,childIdx:-1,native:{lat:contextMenu.native.lat,lng:contextMenu.native.lng}}}));
        handleClose();
    }
    const handleClose = () => {
        setContextMenu((prev:any)=>({...prev,...{open:false,hover:false}}));
    };
    const handleCopy = () => {
        const lng = fixLng(contextMenu.data.lng);
        const latlng = `${contextMenu.data.lat}, ${lng}`;
        if (window.location.protocol === 'http:') {
            console.log('impossible to use clipboard on HTTP');
            console.log(`lat and lng is ${latlng}`);
        } else if (window.location.protocol === 'https:') {
            navigator.clipboard.writeText(latlng);
        }
        handleClose();
    }
    const handleSetDefaultLocation = () => {
        setSessionInfo((prev)=>({...prev,...{defaultLocation:{
            lat:contextMenu.data.lat,
            lng:contextMenu.data.lng,
        }}}));
        saveDefaultLocation(contextMenu.data.lat,contextMenu.data.lng,setBackdrop);
        handleClose();
    }

    let disp = {lat:0,lng:0}
    if(contextMenu.data !== null){
        disp.lat = (Math.round(contextMenu.data.lat*10000000)/10000000);
        // disp.lng = (Math.round(contextMenu.data.lng*10000000)/10000000);
        const lng = (Math.round(contextMenu.data.lng*10000000)/10000000);
        disp.lng = fixLng(lng);
    }
    return (
        <Menu
            open={contextMenu.open}
            onClose={handleClose}
            onContextMenu={(e)=>{e.stopPropagation();e.preventDefault()}}
            anchorReference="anchorPosition"
            anchorPosition={
                contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
            onMouseOut={(e)=>{

            }}
            onMouseOver={(e)=>{

            }}
        >
                {(contextMenu.out !== null && !contextMenu.out) &&
                    <MenuItem onClick={handleAddPoint}><span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.contextmenu.addPoint')}</span></MenuItem>
                }
                {(contextMenu.data !== null) &&
                    <MenuItem onClick={handleCopy}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{`${disp.lat}, ${disp.lng} `}{'('+t('routeEditor.contextmenu.copy')+')'}</span>
                    </MenuItem>
                }
                {(contextMenu.data !== null) &&
                    <MenuItem onClick={handleSetDefaultLocation}><span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.contextmenu.setDefaultLocation')}</span></MenuItem>
                }
            <Divider />
            <MenuItem onClick={handleClose}><span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.contextmenu.cancel')}</span></MenuItem>
        </Menu>
    );
}

//モバイル用コンテキストメニュー ( Polyline付近をロングタップで表示 )
export function RouteEditorContextMenuMobile(
    {mobileContext,setMobileContext,map}:
    {
        mobileContext:typeMobileContext,
        setMobileContext:React.Dispatch<React.SetStateAction<typeMobileContext>>
        map:L.Map|null
    }){
    const {t} = useTranslation();
    const {sessionInfo,setSessionInfo,setBackdrop} = React.useContext(SessionContext);
    const {setEditDialog} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整

    //単語が長い言語用の調整
    let styleBtnTextFix = {...styleBtnText};
    if(sessionInfo.language === 'it'){
        styleBtnTextFix.fontSize = '0.9em';
    }

    const handleAddPoint = () => {
        setEditDialog((prev)=>({...prev,...{open:false,preopen:true,isNew:true,lat:mobileContext.lat,lng:mobileContext.lng,parentIdx:-1,childIdx:-1,idx:mobileContext.idx}}));
        handleClose();
    }

    const handleClose = () => {
        setMobileContext((prev)=>({...prev,...{trigger:!prev.trigger,open:false,lat:0,lng:0,idx:-1}}));
    }
    const handleCopy = () => {
        const latlng = `${mobileContext.lat}, ${mobileContext.lng}`;
        if (window.location.protocol === 'http:') {
            console.log('impossible to use clipboard on HTTP');
            console.log(`lat and lng is ${latlng}`);
        } else if (window.location.protocol === 'https:') {
            navigator.clipboard.writeText(latlng);
        }
        handleClose();
    }
    const handleSetDefaultLocation = () => {
        setSessionInfo((prev)=>({...prev,...{defaultLocation:{
            lat:mobileContext.lat,
            lng:mobileContext.lng,
        }}}));
        saveDefaultLocation(mobileContext.lat,mobileContext.lng,setBackdrop);
        handleClose();
    }
    const disp = {
        lat:(Math.round(mobileContext.lat*10000000)/10000000),
        lng:(Math.round(mobileContext.lng*10000000)/10000000)
    }
    return (
        <React.Fragment>
            <Popup autoPan={false} minWidth={300} className='leaflet-contextmenu-mobile'>
            <MenuList>
                {(mobileContext.distance > 0) &&
                    <MenuItem onClick={handleAddPoint}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.contextmenu.addPoint')}</span>
                    </MenuItem>
                }
                <MenuItem onClick={handleCopy}>
                    <span className={GoogleFonts.className} style={styleBtnText}>{`${disp.lat}, ${disp.lng} `}{'('+t('routeEditor.contextmenu.copy')+')'}</span>
                </MenuItem>
                <MenuItem onClick={handleSetDefaultLocation}>
                    <span className={GoogleFonts.className} style={styleBtnTextFix}>{t('routeEditor.contextmenu.setDefaultLocation')}</span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.contextmenu.cancel')}</span>
                </MenuItem>
            </MenuList>
            </Popup>
        </React.Fragment>
    )
}

//モバイル用コンテキストメニューを表示するかどうかをdistanceとzoomで判定
export function MobileContextMenuVisibleDetection(distance:number,zoom:number|undefined){
    const detector = [ //ズーム倍率ごとの最大許容誤差
        {zoom:7,distanceMax:6000},
        {zoom:8,distanceMax:3000},
        {zoom:9,distanceMax:2000},
        {zoom:10,distanceMax:1600},
        {zoom:11,distanceMax:1400},
        {zoom:12,distanceMax:550},
        {zoom:13,distanceMax:400},
        {zoom:14,distanceMax:250},
        {zoom:15,distanceMax:160},
        {zoom:16,distanceMax:150},
        {zoom:17,distanceMax:80},
        {zoom:18,distanceMax:30},
    ];
    if(zoom === undefined){
        return false
    }else{
        console.log('zoom='+zoom+',distance='+distance)
        let result = false;
        detector.forEach((one)=>{
            if(one.zoom === zoom){
                if(distance <= one.distanceMax){
                    result = true
                }
            }
        })
        return result
    }
}

function fixLng(INPUT:number){
    let lng = INPUT;
    if(lng > 180){
        lng -= 360;
    }else if(lng < -180){
        lng += 360;
    }
    return lng;
}