import { Box, Button, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import { GoogleSeriousFontClassName, LanguagePulldown, adjustFontPosition, getLanguageObj } from '../System/Locales';
import { SessionContext } from '../System/Session';
import { setTitle } from '../System/Title';
import { themeBtnAll } from './RouteEditor/RouteEditorColumnRight';

const commonShadow = '0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000';

const topLinkStyle = {
    color:'#ffffff',
    fontSize:'20px',
    textShadow:commonShadow,
    textDecoration:'underline',
    textUnderlineOffset:'3px',
    cursor:'pointer'
} as React.CSSProperties;

export default function TermsOfService(){
    //背景切り替え
    if(isMobile&&!isTablet){
        document.body.classList.add('cycroute-mobile');
        document.body.classList.remove('cycroute-pc');
    }else{
        document.body.classList.add('cycroute-pc');
        document.body.classList.remove('cycroute-mobile');
    }
    const {t} = useTranslation();
    setTitle(t('termsOfService.title'));
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const GoogleFontEn = getLanguageObj('en'); //ミニロゴ用
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/')
    }
    const handleClose = () => {
        window.close();
    }
    const seriousFontClassName = (sessionInfo.language==='ja')?GoogleFonts.className:GoogleSeriousFontClassName;
    return (
        <React.Fragment>
            
            <Box width="100vw" display="flex" flexDirection="column" sx={{backgroundColor:'transparent'}}>

                <Box sx={{position:'absolute',top:'0px',left:'0px',width:'100%',height:'100%',backgroundColor:'rgba(0, 0, 0, 0.6)',zIndex:-1}}>&nbsp;</Box>

                <Box sx={{width:(isMobile&&!isTablet)?'300px':'600px',margin:'24px auto 0px auto',textAlign:'left'}} className="no-print">
                    <span onClick={handleBack} className={GoogleFontEn.className} style={topLinkStyle}>Cycroute</span>
                </Box>

                <Box sx={{maxWidth:(isMobile&&!isTablet)?'300px':'600px',margin:'60px auto 00px auto',position:'relative'}}>
                    <div className={`cycroute-serious-text ${seriousFontClassName}`}>
                        <div style={{fontSize:'1.5em',fontWeight:'bold',marginBottom:'30px'}}>{t('termsOfService.title')}</div>
                        <span style={{fontSize:'1.2em',lineHeight:'1.8em'}}>{t('termsOfService.message')}</span>
                        <TermsOfServiceText />
                    </div>
                    <Grid container spacing={0.7} columns={{xs:12}} sx={{marginTop:'60px'}}>
                        <Grid item xs={6}>
                            <LanguagePulldown />
                        </Grid>
                        <Grid item xs={6}>
                            <ThemeProvider theme={themeBtnAll}>
                                <Button className='no-print' sx={{position:'absolute',right:'0px'}} size="large" variant="contained" onClick={handleClose}>
                                    <span className={GoogleFonts.className} style={styleBtnText}>{t('privacy.btnClose')}</span>
                                </Button>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{width:'10px',height:'40px'}}></Box>

            </Box>
        </React.Fragment>
    )
}

function TermsOfServiceText(){
    return (
        <React.Fragment>
            <div style={{color:'#ffffff'}}>
                1. Introduction<br />
                <br />
                These Terms of Service (hereinafter referred to as "Terms") govern the use of the web service provided by Cycroute (hereinafter referred to as "Service"). Please read these Terms carefully before using the Service.<br />
                <br />
                2. Definitions<br />
                <br />
                In these Terms, "User" refers to any individual who accesses or uses the Service.<br />
                <br />
                3. Description of Service<br />
                <br />
                The Service is provided for the purpose of [brief description of the service]. Cycroute reserves the right to modify, suspend, or terminate the Service at any time without prior notice.<br />
                <br />
                4. Intellectual Property Rights<br />
                <br />
                All intellectual property rights in the Service, including but not limited to copyrights, trademarks, and proprietary information, are owned by Cycroute or third parties with legitimate rights. Unauthorized reproduction, distribution, modification, or any other use of the Service’s content is prohibited.<br />
                <br />
                5. User Responsibilities<br />
                <br />
                Users shall use the Service at their own risk. Cycroute shall not be liable for any damages arising from the use of the Service. Users are responsible for ensuring that their use of the Service complies with all applicable laws and regulations.<br />
                <br />
                6. Prohibited Activities<br />
                <br />
                Users shall not engage in the following activities:<br />
                <br />
                <ul style={{paddingLeft:'20px'}}>
                    <li>Any action that violates laws, regulations, or public order and morals.</li>
                    <li>Defamation, harassment, or threats against other users, third parties, or Cycroute.</li>
                    <li>Disruption or interference with the operation of the Service.</li>
                    <li>Any other activities deemed inappropriate by Cycroute.</li>
                </ul>
                <br />
                7. Data Protection and Privacy<br />
                <br />
                Cycroute is committed to protecting the privacy and personal data of Users in compliance with the UK Data Protection Act 2018 and the EU General Data Protection Regulation (GDPR). Personal data will be processed in accordance with the Privacy Policy of Cycroute.<br />
                <br />
                8. Disclaimer of Warranties<br />
                <br />
                Cycroute makes no warranty regarding the Service, including its availability, reliability, or accuracy. The Service is provided "as is" and "as available." Cycroute disclaims all warranties, express or implied, including but not limited to fitness for a particular purpose.<br />
                <br />
                9. Limitation of Liability<br />
                <br />
                To the maximum extent permitted by applicable law, Cycroute shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to the use of or inability to use the Service.<br />
                <br />
                10. Changes to Terms<br />
                <br />
                Cycroute reserves the right to amend these Terms at any time. Any changes to the Terms will be posted on the Service, and the revised Terms will become effective immediately upon posting. Continued use of the Service constitutes acceptance of the amended Terms.<br />
                <br />
                11. Governing Law<br />
                <br />
                These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of England and Wales.<br />
                <br />
                12. Contact Information<br />
                <br />
                For any questions regarding these Terms, please contact Cycroute via the contact form on our website or through other provided communication channels.<br />
            </div>
        </React.Fragment>
    )
}