import { Box, Button, ButtonProps, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Slide, TextField, ThemeProvider, createTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import { DBenabled, getDataFromDB, removeDataFromDB, saveDataToDB } from '../db/dbio';
import { adjustFontPosition, getLanguageObj } from './Locales';
import { SessionContext, baseUri, sessionIO } from './Session';

const defaultTheme = createTheme();

//基本データの型
export type typeRouteInfo = {
    id:number
    isPublic:boolean
    title:string
    description:string
    counter:number
}

//初期化用データ
export const pointCompBlabk:typePointComp = {
    routeInfo:{
        id:-1,
        isPublic:true,
        title:'',
        description:'',
        counter:0
    },
    points:[],
    historyUndo:[],
    historyRedo:[],
}

//中間ポイントの型
// export type typePathPoint = {
//     lat:number
//     lng:number
//     isTurn:boolean
// }

export type typePathPoint = {
    modifier: string;
    name: string;
}

//ルートポイントの型
export type typeControlPoint = {
    lat:number
    lng:number
    use:string
    mode:string
    title:string
    detail:string
    // fromLast:typePathPoint[]
    // fromLast:(number|boolean|string|typePathPoint|any)[][],
    fromLast:any[],
    counter:number
}

//一元化した型
export type typePointComp = {
    routeInfo:typeRouteInfo;
    points:typeControlPoint[];
    historyUndo:any[];
    historyRedo:any[];
}

//オプション情報の型
export type typeOptionalInfo = {
    distance:number;
    height:number;
    isPublic:boolean;
    uuidPublic:string;
    nanoid:string;
    nickname:string;
    created:string;
    createdIso:string;
    modified:string;
    modifiedIso:string;
}
export const optionalInfoDefault = {
    distance:0,
    height:0,
    isPublic:false,
    uuidPublic:'',
    nanoid:'',
    nickname:'',
    created:'',
    createdIso:'',
    modified:'',
    modifiedIso:''
}

export type typePopuoOpen = {
    open:boolean;
    target:string;
    trigger:boolean;
}

export type typeEditDialog = {
    open:boolean;
    preopen:boolean;
    isNew:boolean;
    lat:number;
    lng:number;
    parentIdx:number;
    childIdx:number;
    idx:number;
    native:{
        lat:number;
        lng:number;
    }
}

export type typeHoverMarker = {
    isMarkerHover:boolean;
    isPolylineHover:boolean;
    visible:boolean,
    lat:number,
    lng:number
}

//コンテキストの型
export type typeContextRoute = {
    pointComp:typePointComp,
    setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>,
    optionalInfo:typeOptionalInfo,
    setOptionalInfo:React.Dispatch<React.SetStateAction<typeOptionalInfo>>,
    polylineArr:any[],
    setPolylineArr:React.Dispatch<React.SetStateAction<any[]>>,
    popuoOpen:typePopuoOpen,
    setPopuoOpen:React.Dispatch<React.SetStateAction<typePopuoOpen>>,
    contextMenu:any,
    setContextMenu:React.Dispatch<React.SetStateAction<any>>,
    cueWidthMode:string,
    setCueWidthMode:React.Dispatch<React.SetStateAction<string>>,
    popupList:string[]
    setPopupList:React.Dispatch<React.SetStateAction<string[]>>,
    popupRef:React.MutableRefObject<string[]>,
    editDialog:typeEditDialog,
    setEditDialog:React.Dispatch<React.SetStateAction<typeEditDialog>>,
    hoverMarker:typeHoverMarker,
    setHoverMarker:React.Dispatch<React.SetStateAction<typeHoverMarker>>,
    escTrigger:boolean,
    setEscTrigger:React.Dispatch<React.SetStateAction<boolean>>,
    markerToggle:boolean,
    setMarkerToggle:React.Dispatch<React.SetStateAction<boolean>>,
}

//共有
export const RouteContext = React.createContext<typeContextRoute>(undefined as any);
export const useRouteContext = () => React.useContext(RouteContext);

//ローカルストレージ保管名
const nameinStorage = 'cycroutedata';

//trueの場合 : indexedDB に保管
//falseの場合 : localStoraage に保管
// const DBenabled = true; // '/src/db/dbio.tsx' に移設
//※ indexedDB の方が圧倒的に容量が大きい
// localStoraage : 2,200 km前後でエラー
// indexedDB : 10,000 kmを超えても問題ない ( 処理はそれなりに重くなる )

//ブラウザのストレージからデータを削除
export function RouteDataStorageDelete(){
    if(DBenabled){
        removeDataFromDB();
    }else{
        window.localStorage.removeItem(nameinStorage);
    }
}

//データをブラウザのストレージに保存
export async function RouteDataStorageSave(pointComp:typePointComp){
    if(DBenabled){
        await saveDataToDB(pointComp);
    }else{
        window.localStorage.setItem(nameinStorage,JSON.stringify(pointComp));
    }
}

//ブラウザのストレージからデータをロード
export function RouteDataStorageLoad(setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>){
    if(DBenabled){
        getDataFromDB(setPointComp);
    }else{
        let INPUT = window.localStorage.getItem(nameinStorage);
        if(INPUT){
            try{
                let data = JSON.parse(INPUT);
                setPointComp(data as typePointComp);
            } catch (err) {
                console.log(err);
            }
        }
    }
}

//データをサーバへ保存
export const ButtonSaveRoute: React.FC<ButtonProps> = (props) => {
    const {t} = useTranslation();
    const {sessionInfo,setBackdrop,setCommonAlert} = React.useContext(SessionContext);
    const {pointComp,setPointComp} = React.useContext(RouteContext);
    const [preSave,setPreSave] = React.useState<boolean>(false);
    const [accept,setAccept] = React.useState<boolean>(false);
    const [confirm,setConfirm] = React.useState<boolean>(false);
    const [sessionAlert,setSessionAlert] = React.useState<boolean>(false);
    const isFirstLoad = React.useRef(false);
    const handleClick = async () => {
        // console.log("pointComp",pointComp);
        await RouteDataStorageSave(pointComp);
        // console.log(pointComp);
        if(pointComp.points.length === 0){
            setCommonAlert({
                open:true,
                title:t('routeEditor.dialog.nothingCreated.title'),
                message:t('routeEditor.dialog.nothingCreated.message'),
                button:t('common.error.button'),
                callback:null
            });
        }else{
            // handleClickMain();
            setAccept(false);
            setPreSave(true);
        }
    }
    const handleClickMain = () => {
        if(sessionInfo.hasSession){
            setBackdrop(true);
            const requestBody = JSON.stringify({...{routeInfo:pointComp.routeInfo,points:pointComp.points}});
            const limitSize = Number(process.env.REACT_APP_BODY_LIMIT_MB??"5");
            if (new Blob([requestBody]).size > (limitSize * 1024 * 1024)) {
                console.error(`Request body size exceeds ${limitSize}MB limit.`);
                setCommonAlert({
                    open:true,
                    title:t('common.payloadError.title'),
                    message:t('common.payloadError.message'),
                    button:t('common.payloadError.button'),
                    callback:null
                });
                setBackdrop(false);
            }else{
                let mySession = new sessionIO();
                let req = baseUri + "saveroute";
                fetch(
                    req,mySession.cookieEnabled?{}:{
                        mode:'cors',
                        method:'POST',
                        headers:{...mySession.getHeader(),...{'Content-Type':'application/json'}},
                        body: JSON.stringify({...{routeInfo:pointComp.routeInfo,points:pointComp.points}})
                    }
                ).then(res => {
                    // console.log('HTTP CODE : ' + res.status);
                    return res.json();
                }).then(content => {
                    // console.log('content : ',content);
                    setBackdrop(false);
                    if(content.has_session){
                        if(content.success){
                            //id更新処理
                            setPointComp((prev)=>({...prev,...{routeInfo:
                                {...prev.routeInfo,...{id:content.id_route}}
                            }}))
                            setConfirm(true);
                        }else{
                            setCommonAlert({
                                open:true,
                                title:t('common.error.title'),
                                message:t('common.error.message'),
                                button:t('common.error.button'),
                                callback:null
                            });
                        }
                    }else{
                        setSessionAlert(true)
                    }
                }).catch(err => {
                    setBackdrop(false);
                    console.log('err : ',err);
                }).finally(() => {
                });
            }
        }
    }
    React.useEffect(()=>{
        if(!isFirstLoad.current){
            isFirstLoad.current = true;
        }else{
            if(accept){
                handleClickMain();
            }
        }
        // eslint-disable-next-line 
    },[accept]);
    return (
        <React.Fragment>
            <Button {...props} onClick={handleClick} />
            <ThemeProvider theme={defaultTheme}>
                <PreSaveDialog preSave={preSave} setPreSave={setPreSave} setAccept={setAccept} setSessionAlert={setSessionAlert} />
                <ConfirmDialog confirm={confirm} setConfirm={setConfirm} />
                <NoSessionDialog sessionAlert={sessionAlert} setSessionAlert={setSessionAlert} />
            </ThemeProvider>
        </React.Fragment>
    );
};

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type typeMaxPrivate = {
    maxPrivate:number;
    currentPrivate:number;
    left:number;
    alreadyPrivate:boolean;
}

//データ登録前の追加情報入力用コンファーム
function PreSaveDialog(
    {preSave,setPreSave,setAccept,setSessionAlert}:
    {
        preSave:boolean,
        setPreSave:React.Dispatch<React.SetStateAction<boolean>>,
        setAccept:React.Dispatch<React.SetStateAction<boolean>>,
        setSessionAlert:React.Dispatch<React.SetStateAction<boolean>>
}){
    const {t} = useTranslation();
    // const navigate = useNavigate();
    const {sessionInfo,setBackdrop} = React.useContext(SessionContext);
    const {pointComp,setPointComp} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const GoogleFontsEn = getLanguageObj('en');
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [maxPrivate,setMaxPrivate] = React.useState<typeMaxPrivate>({maxPrivate:5,currentPrivate:0,left:0,alreadyPrivate:false});
    const isFirstLoad = React.useRef(false);

    const handleAccept = () => {
        setPreSave(false)
        setAccept(true)
    };
    const handleCancel = () => {
        setPreSave(false)
        setAccept(false);
    };
    const handleChangeTitle = (e:any) => {
        setPointComp((prev)=>({...prev,...{routeInfo:{...prev.routeInfo,...{title:e.target.value}}}}));
    }
    const handleChangeDescription = (e:any) => {
        setPointComp((prev)=>({...prev,...{routeInfo:{...prev.routeInfo,...{description:e.target.value}}}}));
    }
    const handleChangeIsPublic = () => {
        if((maxPrivate.left > 0)||(maxPrivate.alreadyPrivate)){
            setPointComp((prev)=>({...prev,...{routeInfo:{...prev.routeInfo,...{isPublic:!prev.routeInfo.isPublic}}}}));
        }
    }
    React.useEffect(()=>{
        if(!isFirstLoad.current){
            isFirstLoad.current = true;
        }else if(preSave){
            let mySession = new sessionIO();
            let req = baseUri + "my_route_capacity?id=" + pointComp.routeInfo.id;
            fetch(
                req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                // console.log('content : ',content);
                setBackdrop(false);
                if(content.has_session){
                    if(content.success){
                        const left = content.max_private - content.current_private;
                        setMaxPrivate({
                            maxPrivate:content.max_private,
                            currentPrivate:content.current_private,
                            left:left,
                            alreadyPrivate:content.already_private
                        });
                        if((left === 0)&&(!maxPrivate.alreadyPrivate)){
                            setPointComp((prev)=>({...prev,...{routeInfo:{...prev.routeInfo,...{isPublic:true}}}}));

                        }
                    }
                }else{
                    setSessionAlert(true)
                }
            }).catch(err => {
                setBackdrop(false);
                console.log('err : ',err);
            }).finally(() => {
            });

        }
        // eslint-disable-next-line
    },[preSave]);
    return (
        <React.Fragment>
            <Dialog
                open={preSave}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCancel}
                fullWidth
            >
                <DialogTitle className={GoogleFonts.className}>{t("routeEditor.btnSave")}</DialogTitle>
                <DialogContent>
                    <Box sx={{marginTop:'8px'}}>
                        <TextField label={t("routeEditor.inputTitle")} variant="outlined" size="small" className={GoogleFonts.className} value={pointComp.routeInfo.title} onChange={handleChangeTitle} fullWidth />
                    </Box>
                    <Box sx={{marginTop:'16px'}}>
                        <TextField label={t("routeEditor.inputDescription")} variant="outlined" size="small" className={GoogleFonts.className} value={pointComp.routeInfo.description} onChange={handleChangeDescription} fullWidth multiline rows={3} />
                    </Box>
                    <Box sx={{marginTop:'16px'}} width="100%">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={pointComp.routeInfo.isPublic} onChange={handleChangeIsPublic} sx={{paddingLeft:'auto',paddingRight:'auto'}} />} label={
                            <span className={GoogleFonts.className}>{t("routeEditor.checkIsPublic")}</span>} sx={{margin:'0px auto'}} />
                        </FormGroup>
                    </Box>
                    {!maxPrivate.alreadyPrivate &&
                        <Box sx={{marginTop:'16px'}}>
                            <span className={GoogleFonts.className}>非公開ルートの登録は最大{maxPrivate.maxPrivate}件までとなっています。</span>
                            {(maxPrivate.currentPrivate < maxPrivate.maxPrivate) &&
                                <span className={GoogleFonts.className}>あと{(maxPrivate.left)}件登録可能です。</span>
                            }
                            {(maxPrivate.currentPrivate >= maxPrivate.maxPrivate) &&
                                <span className={GoogleFonts.className}>非公開ルートは{maxPrivate.maxPrivate}件以上登録済みなので以降は全て公開ルートとなります。</span>
                            }
                        </Box>
                    }
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
                    <Button onClick={handleCancel}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t("common.buttonCancel")}</span>
                    </Button>
                    <Button onClick={handleAccept}>
                        <span className={GoogleFontsEn.className} style={styleBtnText}>{t("common.buttonOK")}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

//データ登録後の遷移先確認コンファーム
function ConfirmDialog(
    {confirm,setConfirm}:
    {
        confirm:boolean,
        setConfirm:React.Dispatch<React.SetStateAction<boolean>>
}){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {sessionInfo} = React.useContext(SessionContext);
    const {setPointComp} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整

    const handleClose = () => {
        setConfirm(false)
    };
    const handleToTop = () => {
        setPointComp(pointCompBlabk);
        RouteDataStorageDelete(); //DB削除なのでマイルート一覧も更新される
        navigate('/');
    }
    const handleMyRoute = () => {
        setPointComp(pointCompBlabk);
        RouteDataStorageDelete(); //DB削除なのでマイルート一覧も更新される
        navigate('/myroutes');
    }
    return (
        <React.Fragment>
            <Dialog
                open={confirm}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
            >
                <DialogTitle className={GoogleFonts.className}>{t('routeEditor.dialog.afterSave.title')}</DialogTitle>
                <DialogContent>
                    <span className={GoogleFonts.className}>{t('routeEditor.dialog.afterSave.message')}</span>
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
                    <Button onClick={handleClose}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.dialog.afterSave.btnContinue')}</span>
                        </Button>
                    <Button onClick={handleToTop}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t('sendPasswordReset.linkToTop')}</span>
                    </Button>
                    <Button onClick={handleMyRoute}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t("top.btnRouteList")}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

//セッションロスト時のアラート&強制ログアウト
function NoSessionDialog(
    {sessionAlert,setSessionAlert}:
    {
        sessionAlert:boolean,
        setSessionAlert:React.Dispatch<React.SetStateAction<boolean>>
}){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {sessionInfo,setSessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整

    const handleClose = (e:any,reason:string) => {
        if (reason !== 'backdropClick') {
            setSessionAlert(false);
            setSessionInfo((prev)=>({...prev,...{hasSession:false}}));
            navigate('/');
        }
    };
    return (
        <React.Fragment>
            <Dialog
                open={sessionAlert}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={GoogleFonts.className}>{t('routeEditor.dialog.lostSession.title')}</DialogTitle>
                <DialogContent>
                    <span className={GoogleFonts.className}>{t('routeEditor.dialog.lostSession.message')}</span>
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
                    <Button onClick={(ev)=>{handleClose(ev,"click")}}>
                        <span className={GoogleFonts.className} style={styleBtnText}>{t('common.error.button')}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}