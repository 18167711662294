import React, { useContext } from 'react';
import { useKey } from 'react-use';
import { CSSProperties } from 'styled-components';
import { RouteContext } from '../../System/RouteData';
import { SessionContext } from '../../System/Session';
import iconHeight from '../../image/height_24dp.svg';
import { outerGridWidth } from './Casts';

export type typeSidesVisible = {
    left:boolean,
    right:boolean,
};

const toggleMargin = 30;

export const sideArrowsTopDefault = 48;

// const buttonWidth = 40;
// const borderRadius = '6px';
export const buttonWidth = 54;
export const borderRadius = '30px';

export const borderColor = '#a8a8a8';

export const baseDivStyle:any = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    overflow:'hidden',
    position:'absolute',
    minWidth:`${buttonWidth}px`,
    height:'64px',
    // lineHeight:'70px',
    zIndex:801,
    padding:0,
    borderStyle:'solid',
    borderColor:borderColor,
    borderTop:`1px solid ${borderColor}`,
    borderBottom:`1px solid ${borderColor}`,
    backgroundColor:'white',
    cursor:'pointer',
    WebkitTapHighlightColor:'rgba(0, 0, 0, 0)',
    tapHighlightColor:'rgba(0, 0, 0, 0)'
}

export function MapButtonLeft(
    {sidesVisible,setSidesVisible,width,top}:
    {
        sidesVisible:typeSidesVisible,
        setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>,
        width:number,
        top?:number
    }
){
    const {sessionInfo} = useContext(SessionContext);
    let tempStyle:any = {...baseDivStyle,...{
        top:(top === undefined)?sideArrowsTopDefault:top,
        }
    }
    if(!sessionInfo.isLefty){
        tempStyle = {
            ...tempStyle,...{
                float:'left',
                left:(sidesVisible.left)?(outerGridWidth.left-1):0,
                borderRadius:`0px ${borderRadius} ${borderRadius} 0px`,borderLeft:'none',
                borderRight:`1px solid ${borderColor}`,
            }
        }
    }else{
        tempStyle = {
            ...tempStyle,...{
                float:'right',
                right:(sidesVisible.left)?(outerGridWidth.left-1):0,
                borderRadius:`${borderRadius} 0px 0px ${borderRadius}`,borderRight:'none',
                borderLeft:`1px solid ${borderColor}`,
            }
        }
    }
    const divStyleLeft:CSSProperties = tempStyle;
    // const handleClick = (e:React.MouseEvent<HTMLDivElement>) => {
    const handleClick = () => {
        // e.nativeEvent.stopImmediatePropagation()
        // e.stopPropagation();
        const newVisible = !sidesVisible.left;
        if(
            (sidesVisible.right && newVisible)&&
            (width < (outerGridWidth.left + outerGridWidth.right + buttonWidth + toggleMargin))){
                setSidesVisible((prev)=>({...prev,...{left:newVisible,right:false}}));
            }else{
                setSidesVisible((prev)=>({...prev,...{left:newVisible}}));
        }
    }

    //Shortut key for CTRL + Left
    const toggleLeftKey = (event:any) => {
        return (
            (!sessionInfo.isLefty &&(event.ctrlKey && (event.keyCode === 37)))||
            (sessionInfo.isLefty &&(event.ctrlKey && (event.keyCode === 39)))
        )
    };
    const toggleLeftHandler = () => {
        handleClick();
    }
    useKey(toggleLeftKey,toggleLeftHandler,{event:'keyup'});

    return (
        <>
            {!sessionInfo.isLefty &&
                <div style={divStyleLeft} onClick={handleClick} title="CTRL + Left">
                    {(sidesVisible.left) && <SvgArrows mode='left' />}
                    {(!sidesVisible.left) && <SvgArrows mode='right' />}
                </div>
            }
            {sessionInfo.isLefty &&
                <div style={divStyleLeft} onClick={handleClick} title="CTRL + Left">
                    {(sidesVisible.left) && <SvgArrows mode='right' />}
                    {(!sidesVisible.left) && <SvgArrows mode='left' />}
                </div>
            }
        </>
    )
}

export function MapButtonRight(
    {sidesVisible,setSidesVisible,width,top}:
    {
        sidesVisible:typeSidesVisible,
        setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>,
        width:number,
        top?:number
    }
){
    const {sessionInfo} = useContext(SessionContext);
    const {cueWidthMode} = useContext(RouteContext);
    const rightColumnWidth = cueWidthMode==='middle'?outerGridWidth.mobile.middle.right:(cueWidthMode==='small'?outerGridWidth.mobile.small.right:outerGridWidth.right);
    let tempStyle:any = {...baseDivStyle,...{
            top:(top === undefined)?sideArrowsTopDefault:top,
        }
    }
    if(!sessionInfo.isLefty){
        tempStyle = {
            ...tempStyle,...{
                float:'right',
                right:(sidesVisible.right)?(rightColumnWidth-1):0,
                borderRadius:`${borderRadius} 0px 0px ${borderRadius}`,borderRight:'none',
                borderLeft:`1px solid ${borderColor}`,
            }
        }
    }else{
        tempStyle = {
            ...tempStyle,...{
                float:'left',
                left:(sidesVisible.right)?(rightColumnWidth-1):0,
                borderRadius:`0px ${borderRadius} ${borderRadius} 0px`,borderLeft:'none',
                borderRight:`1px solid ${borderColor}`,
            }
        }
    }
    const divStyleRight:CSSProperties = tempStyle;
    // const handleClick = (e:React.MouseEvent<HTMLDivElement>) => {
    const handleClick = () => {
        // e.nativeEvent.stopImmediatePropagation()
        // e.stopPropagation();
        setSidesVisible((prev)=>({...prev,...{right:!sidesVisible.right}}));
        const newVisible = !sidesVisible.right;
        if(
            (sidesVisible.left && newVisible)&&
            (width < (outerGridWidth.left + outerGridWidth.right + buttonWidth + toggleMargin))){
                setSidesVisible((prev)=>({...prev,...{right:newVisible,left:false}}));
            }else{
                setSidesVisible((prev)=>({...prev,...{right:newVisible}}));
        }
    }

    //Shortut key for CTRL + Right
    const toggleRightKey = (event:any) => {
        return (
            (!sessionInfo.isLefty &&(event.ctrlKey && (event.keyCode === 39)))||
            (sessionInfo.isLefty &&(event.ctrlKey && (event.keyCode === 37)))
        )
    }
    const toggleRightHandler = () => {
        handleClick()
    }
    useKey(toggleRightKey,toggleRightHandler,{event:'keyup'});

    return (
        <>
            {!sessionInfo.isLefty &&
                <div style={divStyleRight} onClick={handleClick} title="CTRL + Right">
                    {(sidesVisible.right) && <SvgArrows mode='right' />}
                    {(!sidesVisible.right) && <SvgArrows mode='left' />}
                </div>
            }
            {sessionInfo.isLefty &&
                <div style={divStyleRight} onClick={handleClick} title="CTRL + Right">
                    {(sidesVisible.right) && <SvgArrows mode='left' />}
                    {(!sidesVisible.right) && <SvgArrows mode='right' />}
                </div>
            }
        </>
    )
}

export function SvgArrows({mode}:{mode:string}){
    const vector = SvgArrowsVector(mode);
    const box = (mode==='left')?"0 0 40 40":"-15 0 40 40";
    return (
        <svg width="40" height="40" viewBox={box} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={vector} fill="#676767"/>
        </svg>
    )
}

function SvgArrowsVector(mode:string){
    if(mode === 'left'){
        return 'M15,20 L25,10 L25,30 Z'
    }else if(mode === 'right'){
        return 'M10,20 L0,10 L0,30 Z'
    }
}

const baseAdjustMenu = {
    bottom:'88px',
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    overflow:'hidden',
    // position:'absolute',
    position:'fixed',
    width:'40px',
    minWidth:'40px',
    height:'60px',
    lineHeight:'60px',
    zIndex:801,
    padding:0,
    borderStyle:'solid',
    borderColor:borderColor,
    backgroundColor:'white',
    cursor:'pointer',
    borderTop:`1px solid ${borderColor}`,
    borderBottom:`1px solid ${borderColor}`,
    WebkitTapHighlightColor:'rgba(0, 0, 0, 0)',
    tapHighlightColor:'rgba(0, 0, 0, 0)'
}

export function CueSheetAdjustButton({sidesVisible}:
    {
        sidesVisible:typeSidesVisible,
    }){
    const {sessionInfo} = useContext(SessionContext);
    const {cueWidthMode,setCueWidthMode} = useContext(RouteContext);
    const rightColumnWidth = cueWidthMode==='middle'?outerGridWidth.mobile.middle.right:(cueWidthMode==='small'?outerGridWidth.mobile.small.right:outerGridWidth.right);
    let tempStyle:any = baseAdjustMenu;
    if(!sessionInfo.isLefty){
        tempStyle = {
            ...tempStyle,...{
                float:'right',
                right:(sidesVisible.right)?(rightColumnWidth-1):0,
                borderRadius:'6px 0px 0px 6px',borderRight:'none',
                borderLeft:`1px solid ${borderColor}`,
            }
        }
    }else{
        tempStyle = {
            ...tempStyle,...{
                float:'left',
                left:(sidesVisible.right)?(rightColumnWidth-1):0,
                borderRadius:'0px 6px 6px 0px',borderLeft:'none',
                borderRight:`1px solid ${borderColor}`,
            }
        }
    }
    const adjustMenu:CSSProperties = tempStyle;
    const handleClich = () => {
        if(cueWidthMode === ''){
            setCueWidthMode('middle')
        }else if(cueWidthMode === 'middle'){
            setCueWidthMode('small')
        }else if(cueWidthMode === 'small'){
            setCueWidthMode('')
        }
    }
    return (
        <React.Fragment>
            <div style={adjustMenu} onClick={handleClich}>
                <img style={{position:'relative',left:'5px',width:'70%',height:'70%',transform:'rotate(90deg)'}} src={iconHeight} alt="Width Rotation" />
            </div>
        </React.Fragment>
    )
}