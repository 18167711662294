import { Alert, Box, Button, Divider, TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import { adjustFontPosition, getLanguageObj } from '../System/Locales';
import { SessionContext, baseUri, compareUID, sessionIO, syncSession, typeSession } from '../System/Session';
import { setTitle } from '../System/Title';
import TurnstileWidget from '../System/Turnstile';
import { themeBtnAll } from './RouteEditor/RouteEditorColumnRight';

import ButtonAppleOauth from '../System/AppleOauth';
import ButtonGoogleOauth from '../System/GoogleOauth';

import { removeDB } from '../db/dbio';

export const logoStyle = {
    height:'28px',
    width:'28px',
    marginRight:'10px'
} as React.CSSProperties;

const commonShadow = '0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000';
const topLinkStyle = {
    color:'#ffffff',
    fontSize:'20px',
    textShadow:commonShadow,
    textDecoration:'underline',
    textUnderlineOffset:'3px',
    cursor:'pointer'
} as React.CSSProperties;

const messageStyle = {
    color:'#ffffff',
    fontSize:'16px',
    textShadow:commonShadow,
    textDecoration:'none',
};

const mailInputStyle = {
    backgroundColor:'#ffffff',
}

const mailInputStyleDisabled = {
    backgroundColor:'transparent',
}

const dividerStyle = {
    borderTop: '1px solid #ffffff',
    boxShadow:commonShadow
}
const themeDivider = createTheme({
    components: {
        MuiDivider: {
            styleOverrides: {
                root: {
                    '&:before':dividerStyle,
                    '&:after':dividerStyle,
                },
            },
        },
    },
});

export default function Signinup(){

    //背景切り替え
    if(isMobile&&!isTablet){
        document.body.classList.add('cycroute-mobile');
        document.body.classList.remove('cycroute-pc');
    }else{
        document.body.classList.add('cycroute-pc');
        document.body.classList.remove('cycroute-mobile');
    }
    const {t} = useTranslation();
    const location = useLocation();
    if(location.pathname === '/signup'){
        setTitle(t("top.btnSignUp"));
    }else if(location.pathname === '/signin'){
        setTitle(t("top.btnSignIn"));
    }else{
        setTitle();
    }
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const navigate = useNavigate();
    const [success,setSuccess] = React.useState<boolean>(false);

    const handleBack = () => {
        navigate('/')
    }
    return (
        <React.Fragment>
            <Box width="100vw" height="100%" display="flex" flexDirection="column" sx={{backgroundColor:'transparent'}}>

                <Box sx={{width:(isMobile&&!isTablet)?'300px':'420px',margin:'24px auto 0px auto',textAlign:'left'}}>
                    <span onClick={handleBack} className={GoogleFonts.className} style={topLinkStyle}>{t('sendPasswordReset.linkToTop')}</span>
                </Box>

                <Content success={success} />

                <Box sx={{margin:'60px auto 0px auto'}}>
                    <TurnstileWidget setSuccess={setSuccess} />
                </Box>

            </Box>
        </React.Fragment>
    )
}

type typeComm = {
    progress:boolean;
    success:boolean;
    failed:boolean;
    complete:boolean;
    msg:string;
}

type typeTwoFactorDialog = {
    open:boolean;
    uuid:string;
    account:string;
    code:string;
}

type typeSignupDialog = {
    open:boolean;
    hasAccount:boolean;
}

function Content({success}:{success?:boolean}){

    const accept = success??true;
    const location = useLocation();

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {sessionInfo,setBackdrop,setSessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [account,setAccount] = React.useState<string>('');
    const [password,setPassword] = React.useState<string>('');
    const [mailValid,setMailValid] = React.useState<boolean>(false);
    const [comm,setComm] = React.useState<typeComm>({progress:false,success:false,failed:false,complete:false,msg:''});
    const [twoFactorDialog,setTwoFactorDialog] = React.useState<typeTwoFactorDialog>({open:false,uuid:"",account:"",code:""});
    const [signupDialog,setSignupDialog] = React.useState<typeSignupDialog>({open:false,hasAccount:false});
    const [signinFailedDialog,setSigninFailedDialog] = React.useState<boolean>(false);

    const dividerStyle = {...styleBtnText,...{
        color:'#ffffff',
        // fontSize:'18px',
        textShadow:commonShadow,
        // textDecoration:'none',
    }};

    const handleSigninup = () => {
        if(location.pathname === '/signup'){
            if(checkAddressChar(account,setMailValid)){
                setComm((prev)=>({...prev,...{progress:true,success:false,failed:false,complete:false,msg:''}}))
                signUp(account,sessionInfo,setBackdrop,setComm,setSignupDialog)
            }    
        }
        if(location.pathname === '/signin'){
            login(account,password,sessionInfo.language,setSessionInfo,setBackdrop,navigate,setTwoFactorDialog,setSigninFailedDialog);
        }
    }
    React.useEffect(()=>{
        checkAddressChar(account,setMailValid)
    },[account])
    let messageSignup = t('signup.message')
        .replace('<terms_of_service>',process.env.REACT_APP_URL_TERMS_OF_SERVICE??'#')
        .replace('<privacy_policy>',process.env.REACT_APP_URL_PRIVACY_POLICY??'#');
    return(
        <React.Fragment>
            {((location.pathname === '/signup')||(location.pathname === '/signin' && (t('signin.message') !== ''))) &&
                <Box sx={{width:(isMobile&&!isTablet)?'300px':'420px',margin:'30px auto 0px auto'}}>
                    <Box sx={messageStyle}>
                        {location.pathname === '/signup' &&
                        <span style={styleBtnText} dangerouslySetInnerHTML={{__html:messageSignup}} />
                        }
                        {location.pathname === '/signin' &&
                            <span style={styleBtnText} dangerouslySetInnerHTML={{__html:t('signin.message')}} />
                        }
                    </Box>
                </Box>
            }
            <Box sx={{width:'300px',margin:'30px auto 0px auto'}}>
                <TextField sx={accept?mailInputStyle:mailInputStyleDisabled} fullWidth label={t('sendPasswordReset.inputLabelMailAddress')} variant="filled" name="cycroutaccount" onChange={(e)=>{setAccount(e.target.value)}} type="mail" value={account} disabled={!accept} />
                {location.pathname === '/signin' &&
                    <TextField sx={{...accept?mailInputStyle:mailInputStyleDisabled,...{marginTop:'15px'}}} fullWidth label={t('signin.inputLabelPassword')} variant="filled" name="cycroutpassword" onChange={(e)=>{setPassword(e.target.value)}} type="password" value={password} disabled={!accept} />
                }
                <ThemeProvider theme={themeBtnAll}>
                    <Button sx={{marginTop:'15px',marginBottom:'15px'}} fullWidth variant="contained" size="large" className={GoogleFonts.className} onClick={handleSigninup} disabled={!accept}>
                        {location.pathname === '/signup' &&
                            <span style={styleBtnText}>{t('signup.btnWithMail')}</span>
                        }
                        {location.pathname === '/signin' &&
                            <span style={styleBtnText}>{t('signin.btnWithMail')}</span>
                        }
                    </Button>
                </ThemeProvider>

                <ThemeProvider theme={themeDivider}>
                    <Divider>
                        <span className={GoogleFonts.className} style={dividerStyle}>{t('signup.or')}</span>
                    </Divider>
                </ThemeProvider>
                
                <ButtonGoogleOauth accept={accept} />
                {false &&
                    <ButtonAppleOauth accept={accept} />
                }

                {((account!=='')&&!mailValid) &&
                    <Alert sx={{marginTop:'20px'}} severity="error">{t('common.mailValidation.invalid')}</Alert>
                }
                {((account!=='')&&mailValid) &&
                    <Alert sx={{marginTop:'20px'}} severity="success">{t('common.mailValidation.valid')}</Alert>
                }
                {(comm.progress) &&
                    <Alert sx={{marginTop:'20px'}} severity="info">{t('common.mailSending.progress')}</Alert>
                }
                {(comm.success) &&
                    <Alert sx={{marginTop:'20px'}} severity="success">{t('common.mailSending.success')}</Alert>
                }
                {(comm.failed) &&
                    <Alert sx={{marginTop:'20px'}} severity="error">{t('common.mailSending.failed')}</Alert>
                }
            </Box>
            {location.pathname === '/signin' &&
                <React.Fragment>
                    <TwoFactorDialog twoFactorDialog={twoFactorDialog} setTwoFactorDialog={setTwoFactorDialog} />
                    <DialogSininFailed signinFailedDialog={signinFailedDialog} setSigninFailedDialog={setSigninFailedDialog} />
                </React.Fragment>
            }
            {location.pathname === '/signup' &&
                <DialogSinupSent account={account} signupDialog={signupDialog} setSignupDialog={setSignupDialog} />
            }
        </React.Fragment>
    )    
}

function checkAddressChar(mail:string,setMailValid:React.Dispatch<React.SetStateAction<boolean>>){
    const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
    if(pattern.test(mail)){
        setMailValid(true)
        return true
    }else{
        setMailValid(false)
        return false
    }
}

function login(
    account:string,
    password:string,
    language:string,
    setSessionInfo:React.Dispatch<React.SetStateAction<typeSession>>,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    navigate:NavigateFunction,
    setTwoFactorDialog:React.Dispatch<React.SetStateAction<typeTwoFactorDialog>>,
    setSigninFailedDialog:React.Dispatch<React.SetStateAction<boolean>>
){
    setBackdrop(true);
    let mySession = new sessionIO();
    mySession.login({
        account:account,
        password:password,
        language:language,
        onThen:((content)=>{
            // console.log(content);
            if(content.twofactor){
                if(content.success){
                    setTwoFactorDialog((prev)=>({...prev,...{open:true,uuid:content.twofactoruuid,account:content.account,code:""}}));
                    console.log('TWO FACTOR : START')
                }else{
                    setTwoFactorDialog((prev)=>({...prev,...{open:false,uuid:"",account:"",code:""}}));
                    console.log('TWO FACTOR : FAILED TO START')
                }
            }else{
                if(content.success){
                    console.log('LOGIN : SUCCESS')
                    if(!compareUID(content.uid)){ //別ユーザがログインしたら一旦 indexed DB 削除
                        removeDB()
                    }
                    syncSession(content,setSessionInfo);
                    navigate('/')
                }else{
                    console.log('LOGIN : FAILED')
                    setSigninFailedDialog(true)
                }
            }
        }),
        onCatch:((err)=>{
            // console.log('here is "catch"');
        }),
        onFinally:(()=>{
            // console.log('here is "finally"');
            setBackdrop(false);
        }),
    })

}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export function TwoFactorDialog(
    {twoFactorDialog,setTwoFactorDialog}:
    {
        twoFactorDialog:typeTwoFactorDialog,
        setTwoFactorDialog:React.Dispatch<React.SetStateAction<typeTwoFactorDialog>>
}){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {setBackdrop,setSessionInfo} = React.useContext(SessionContext);
    const handleChange = (e:any) => {
        const regex = /^[0-9]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
            setTwoFactorDialog((prev)=>({...prev,...{code:e.target.value}}));
        }
    }
    const handleClose = (ev:any,reason?:string) => {
        if(reason !== 'backdropClick'){
            setTwoFactorDialog((prev)=>({...prev,...{open:false,uuid:"",account:"",code:""}}));
        }
    };
    const handleOK = () => {
        if(twoFactorDialog.code.length === 6){
            // console.log('twoFactorDialog.code = ',twoFactorDialog.code);
            twoFactorAuth(twoFactorDialog.uuid,twoFactorDialog.code,setSessionInfo,setBackdrop,navigate);
        }
    }
    let message = t('top.twoFactorAuthentication.message')
    .replace('<account>',`<strong>${twoFactorDialog.account}</strong>`);
    return (
        <React.Fragment>
            <Dialog
                open={twoFactorDialog.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{t("top.twoFactorAuthentication.title")}</DialogTitle>
                <DialogContent>
                    <span dangerouslySetInnerHTML={{__html:message}} />
                    <TextField inputProps={{inputMode:'numeric'}} sx={{marginTop:'15px'}} fullWidth label={t('top.twoFactorAuthentication.label')} variant="filled" name="cycrouttwofactorcode" onChange={handleChange} type="text" value={twoFactorDialog.code} />
                </DialogContent>
                <DialogActions sx={{justifyContent:'space-between',paddingLeft:'20px',paddingRight:'20px'}}>
                    <Button onClick={handleClose}>{t('top.twoFactorAuthentication.btnCancel')}</Button>
                    <Button onClick={handleOK}>{t('top.twoFactorAuthentication.btnOK')}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function twoFactorAuth(uuid:string,code:string,
    setSessionInfo:React.Dispatch<React.SetStateAction<typeSession>>,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    navigate:NavigateFunction
){
    let mySession = new sessionIO();
    mySession.twoFactor({
        uuid:uuid,
        code:code,
        onThen:((content)=>{
            // console.log(content);
            if(content.success){
                console.log('TWO FACTOR LOGIN : SUCCESS')
                if(!compareUID(content.uid)){ //別ユーザがログインしたら一旦 indexed DB 削除
                    removeDB()
                }
                syncSession(content,setSessionInfo);
                navigate('/')
            }else{
                console.log('TWO FACTOR LOGIN : FAILED')
            }
        }),
        onCatch:((err)=>{
            // console.log('here is "catch"');
        }),
        onFinally:(()=>{
            // console.log('here is "finally"');
            setBackdrop(false);
        }),
    })

}

function signUp(
    mailaddress:string,sessionInfo:typeSession,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    setComm:React.Dispatch<React.SetStateAction<typeComm>>,
    setSignupDialog:React.Dispatch<React.SetStateAction<typeSignupDialog>>
){
    setBackdrop(true);
    let mySession = new sessionIO();
    let req = baseUri
    + "newaccount?mailaddress=" + encodeURIComponent(mailaddress)
    + "&language=" + encodeURIComponent(sessionInfo.language)
    + "&timezone=" + encodeURIComponent(sessionInfo.timezone);
    fetch(
        req,mySession.cookieEnabled?{}:{mode:'cors'}
    ).then(res => {
        // console.log('HTTP CODE : ' + res.status);
        return res.json();
    }).then(content => {
        // console.log('content : ',content);
        setComm((prev)=>({...prev,...{progress:false,success:true,failed:false,complete:false,msg:''}}))
        setBackdrop(false);
        setSignupDialog({open:true,hasAccount:content.has_account});
    }).catch(err => {
        console.log('err : ',err);
        setBackdrop(false);
        setComm((prev)=>({...prev,...{progress:false,success:false,failed:true,complete:false,msg:''}}))
    }).finally(() => {
    });

}

function DialogSinupSent(
    {account,signupDialog,setSignupDialog}:{
        account:string,
        signupDialog:typeSignupDialog,
        setSignupDialog:React.Dispatch<React.SetStateAction<typeSignupDialog>>
}) {
    const {t} = useTranslation();
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const handleClose = () => {
        setSignupDialog({open:false,hasAccount:false});
    };
    return (
        <React.Fragment>
            <Dialog
                open={signupDialog.open}
                TransitionComponent={Transition} keepMounted onClose={handleClose}
            >
                <DialogContent>
                    <span className={GoogleFonts.className} style={{...styleBtnText,...{fontSize:'1.2em'}}}>
                        {!signupDialog.hasAccount &&
                            <React.Fragment>
                                {t("signup.dialog.message").replace('<replacer>',account)}
                            </React.Fragment>
                        }
                        {signupDialog.hasAccount &&
                            <React.Fragment>
                                {t("signup.dialog.hasAccount").replace('<replacer>',account)}
                            </React.Fragment>
                        }
                    </span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("signup.dialog.btnClose")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function DialogSininFailed(
    {signinFailedDialog,setSigninFailedDialog}:{
        signinFailedDialog:boolean,
        setSigninFailedDialog:React.Dispatch<React.SetStateAction<boolean>>
}) {
    const {t} = useTranslation();
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const handleClose = () => {
        setSigninFailedDialog(false);
    };
    return (
        <React.Fragment>
            <Dialog
                open={signinFailedDialog}
                TransitionComponent={Transition} keepMounted onClose={handleClose}
            >
                <DialogContent>
                    <span className={GoogleFonts.className} style={{...styleBtnText,...{fontSize:'1.2em'}}}>
                        {t("signin.dialogFailed.message")}
                    </span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t("signin.dialogFailed.btnClose")}</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

