import { Box } from '@mui/material';
import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import checkAdBlocker from '../System/CheckAdsBlocked';
import { SessionContext } from '../System/Session';
import windowDimensions from '../System/WindowDimensions';

export const adsHeight = {
    small:50,
    large:90
}

// const bgColor = '#aed0ee';
const bgColor = 'transparent';

const theme = createTheme();

export default function AdsArea(){
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <AdsAreaInner />
            </ThemeProvider>
        </React.Fragment>
    )
}

type typeAdBlock = {
    checked:boolean;
    isBlocked:boolean;
}

function AdsAreaInner(){
    const {setAdsHeight} = React.useContext(SessionContext);
    const {width} = windowDimensions();
    const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const isXs = !isSmUp;
    const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isSm = isSmUp && isMdDown;
    const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const isMd = isMdUp && isLgDown;
    const isLgUP = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const [adBlock,setAdBlock] = React.useState<typeAdBlock>({checked:false,isBlocked:false});
    const checkRef = React.useRef(false);
    
    const smallAdsRef = React.useRef<HTMLInputElement>(null);
    const largeAdsRef = React.useRef<HTMLInputElement>(null);

    const calcAdsHeight = () => {
        let calcHeight = 0;
        if(isXs||isSm){
            // console.log("isXs||isSm");
            calcHeight = adsHeight.small;
        }else if(isMd||isLgUP){
            // console.log("isMd||isLgUP");
            calcHeight = adsHeight.large;
        }else{
            // console.log("else");
            calcHeight = 90;
        }
        return calcHeight;
    };
    const newAdsHeight = calcAdsHeight();
    React.useEffect(()=>{
        if(!checkRef.current){
            checkRef.current = true;
            checkAdBlocker().then((isBlocked) => {
                if (isBlocked) {
                    console.log('Ads block detected.');
                    setAdBlock({checked:true,isBlocked:true})
                } else {
                    console.log('Ads block not detected.');
                    setAdBlock({checked:true,isBlocked:false})
                }
            });
        }
    },[]);
    React.useLayoutEffect(() => {
        setAdsHeight(newAdsHeight);
        // eslint-disable-next-line
    },[]);
    React.useEffect(() => {
        const handleAdsResize = () => {
            setAdsHeight(newAdsHeight);
        };
        window.addEventListener('resize',handleAdsResize);
        return () => {
            window.removeEventListener('resize', handleAdsResize);//クリーンアップ ( メモリリーク回避 )
        };
        // eslint-disable-next-line
    },[newAdsHeight]);
    return (
        <>
            {/* 0-899px ( XS:0-599px, SM:600-899px ) */}
            {(isXs||isSm) &&
                <Box width="100vw" height={adsHeight.small} minHeight={adsHeight.small} display="flex" sx={{padding:0,backgroundColor:bgColor}} ref={smallAdsRef}>
                    <SmallAds isBlocked={adBlock.isBlocked} />
                </Box>
            }
            {/* over 900px ( MD:900-1199px, LG:1200-1535px, XL:over 1535px ) */}
            {(isMd||isLgUP) &&
                <Box width="100vw" height={adsHeight.large} minHeight={adsHeight.large} display="flex" sx={{padding:0,backgroundColor:bgColor}} ref={largeAdsRef}>
                    {width <= 1200 && <MiddleAds isBlocked={adBlock.isBlocked} />}
                    {width > 1200 && <LargeAds isBlocked={adBlock.isBlocked} />}
                </Box>
            }
        </>
    )
}

function SmallAds({isBlocked}:{isBlocked:boolean}){
    return (
        <React.Fragment>
            <Box sx={{width:'100%',height:'100%',textAlign:'center',justifyContent:'center',display:'flex',alignItems:'center'}}>
                Ads area small
                {isBlocked && <AdBlockedMessage />}
            </Box>
        </React.Fragment>
    )
}

function MiddleAds({isBlocked}:{isBlocked:boolean}){
    return (
        <React.Fragment>
            <Box sx={{width:'100%',height:'100%',textAlign:'center',justifyContent:'center',display:'flex',alignItems:'center'}}>
                Ads area middle
                {isBlocked && <AdBlockedMessage />}
            </Box>
        </React.Fragment>
    )    
}

function LargeAds({isBlocked}:{isBlocked:boolean}){
    return (
        <React.Fragment>
            <Box sx={{width:'100%',height:'100%',textAlign:'center',justifyContent:'center',display:'flex',alignItems:'center'}}>
                Ads area large
                {isBlocked && <AdBlockedMessage />}
            </Box>
        </React.Fragment>
    )    
}

function AdBlockedMessage(){
    return (
        <React.Fragment>
            &emsp;<span style={{fontWeight:'bold',color:'#ff0000'}}>( Please disable ads blocker. )</span>
        </React.Fragment>
    )
}

export function AdsAreaSpacer(){
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <AdsAreaSpacerInner />
            </ThemeProvider>
        </React.Fragment>
    )
}

function AdsAreaSpacerInner(){
    // const {width} = windowDimensions();
    const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const isXs = !isSmUp;
    const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isSm = isSmUp && isMdDown;
    const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const isMd = isMdUp && isLgDown;
    const isLgUP = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
        return (
        <React.Fragment>
            {/* 0-899px ( XS:0-599px, SM:600-899px ) */}
            {(isXs||isSm) &&
                <Box width="100vw" height={adsHeight.small} minHeight={adsHeight.small} display="flex" sx={{padding:0}}>
                    &nbsp;
                </Box>
            }
            {/* over 900px ( MD:900-1199px, LG:1200-1535px, XL:over 1535px ) */}
            {(isMd||isLgUP) &&
                <Box width="100vw" height={adsHeight.large} minHeight={adsHeight.large} display="flex" sx={{padding:0}}>
                    &nbsp;
                </Box>
            }
        </React.Fragment>
    )
}
