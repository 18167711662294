import { Button } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";
// @ts-ignore
import { XMLParser } from 'fast-xml-parser';

import { typeImportDialogOpen } from '../UI/RouteEditor/RouteEditorColumnLeft';
import { adjustFontPosition, getLanguageObj } from './Locales';
import { typeControlPoint, typePointComp } from './RouteData';
import { SessionContext } from './Session';

import { parseFitFile } from './Import/ImportFileFit';

const inputId = "input-cycroute-importer";

type typeAnalyze = {
  name:string;
  ext:string;
  file:File;
}

export default function RouteUpload(
  {sx,setPointComp,setImportDialogOpen}:
  {sx?:any,setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>,
    setImportDialogOpen:React.Dispatch<React.SetStateAction<typeImportDialogOpen>>}){
    const {t} = useTranslation();
    const {sessionInfo,setBackdrop,setCommonAlert} = useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [analyze,setAnalyze] = useState<typeAnalyze|undefined>();

    const handleFileRead = (e: ProgressEvent<FileReader>) => {
      try{
        const content = e.target?.result;
        // console.log('analyze',analyze);
        if(analyze){
          if(analyze.ext === 'fit'){
            // console.log('fit')
            parseFitFile(content as ArrayBuffer,setPointComp,setImportDialogOpen) // "fit" はバイナリなので事前解析
          }else if(analyze.ext === 'gpx'){
            const xp = new XMLParser({ignoreAttributes:false});
            const output = xp.parse(content as string);
            if(output.gpx){
              let gpxData = parseGpxFile(output.gpx);
              setPointComp(gpxData);
              setImportDialogOpen({open:false,imported:true,confirm:false});
            }
          }else if(analyze.ext === 'tcx'){
            const xp = new XMLParser({ignoreAttributes:false});
            const output = xp.parse(content as string);
            // console.log('tcx',output)
            if(output.TrainingCenterDatabase?.Courses?.Course){
              let tcxData = parseTcxFile(output.TrainingCenterDatabase.Courses.Course);
              setPointComp(tcxData);
              setImportDialogOpen({open:false,imported:true,confirm:false});
            }
          }else if(analyze.ext === 'kml'){
            const xp = new XMLParser({ignoreAttributes:false});
            const output = xp.parse(content as string);
            let kmlData = parseKmlFile(output.kml.Document);
            // console.log('kmlData',kmlData);
            setPointComp(kmlData);
            setImportDialogOpen({open:false,imported:true,confirm:false});
          }else{
            //未対応ファイル形式アラートを実装予定
            setBackdrop(false);
            setCommonAlert({
              open:true,
              title:t('routeEditor.dialog.importError.notSupport.title'),
              message:t('routeEditor.dialog.importError.notSupport.message'),
              button:t('routeEditor.dialog.importError.notSupport.button'),
              callback:null});
          }
        }
      }catch(error){
        console.log(error);
        setCommonAlert({open:true,title:"",message:t('common.error.message'),button:t('common.error.button'),callback:null});
      }
    }

    const handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files && e.target.files[0];
      if (file) {
        setBackdrop(true);
        const ext:string = file.name.split('.').pop() as string;
        setAnalyze((prev)=>({...prev,...{
          name:file.name as string,
          ext:ext,
          file:file
        }}));
        clearInput();
      }

    }
    React.useEffect(()=>{
      if(analyze){
        const fileReader = new FileReader();
        fileReader.onloadend = handleFileRead;
        if(analyze?.ext === 'fit'){
          fileReader.readAsArrayBuffer(analyze.file);  
        }else{
          fileReader.readAsText(analyze.file);  
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[analyze])
  return (
      <Button fullWidth sx={sx} variant="contained" component="label">
        <span className={GoogleFonts.className} style={styleBtnText}>{t('routeEditor.dialog.import.selectFile')}</span>
        <input id={inputId} type="file" hidden onChange={handleChange} accept=".gpx,.fit,.tcx,.kml" />
      </Button>
  )
}

//同じファイルを再選択できるよう一旦クリア
function clearInput(){
  const fileInput = document.getElementById(inputId) as HTMLInputElement;
  fileInput.value = '';
}

function parseGpxFile(gpx:any){
  // console.log('gpx',gpx);
  let pathPoint:any[] = [];
  let controlPoint:typeControlPoint[] = [];
  if(gpx.rte){//詳細なしファイル ( cueのみ )
    for(let i=0;i<gpx.rte.rtept.length;i++){
      if(i===0){
        controlPoint.push({
          lat:gpx.rte.rtept[i]['@_lat'],
          lng:gpx.rte.rtept[i]['@_lon'],
          use:"bicycle",
          mode:"start",
          title:gpx.rte.rtept[i].cmt,
          detail:"",
          fromLast:[],
          counter:controlPoint.length
        });
      }else{
        let isLast = ((gpx.rte.rtept.length - 1) <= (i + 1));
        pathPoint = [[
          gpx.rte.rtept[i]['@_lat'],
          gpx.rte.rtept[i]['@_lon'],
          true,
          {modifier:gpx.rte.rtept[i].name.toLowerCase(),name:gpx.rte.rtept[i].cmt}
        ]];
        controlPoint.push({
          lat:gpx.rte.rtept[i]['@_lat'],
          lng:gpx.rte.rtept[i]['@_lon'],
          use:"bicycle",
          mode:"",
          title:isLast?'GOAL':'',
          detail:"",
          fromLast:isLast?[]:pathPoint,
          counter:controlPoint.length
        });
      }

    }
  }else if(gpx.trk){//詳細ありファイル
    //ファイルによりcues有無あり
    let hasWPT:boolean = (gpx.wpt !== undefined);
    let wptFix:any[] = [];
    if(hasWPT){
      const detector = gpx.trk.trkseg.trkpt.map((one:any)=>{
        return one['@_lat'] + '_' + one['@_lon'] as string
      });
      wptFix = gpx.wpt.filter((one:any)=>{
        const indexOf = detector.indexOf(one['@_lat'] + '_' + one['@_lon'] as string);
        return (indexOf >= 0)
      })
      hasWPT = (wptFix.length > 0);
    }
    let w = 0;
    for(let i=0;i<gpx.trk.trkseg.trkpt.length;i++){
      if(i===0){
        controlPoint.push({
          lat:gpx.trk.trkseg.trkpt[i]['@_lat'],
          lng:gpx.trk.trkseg.trkpt[i]['@_lon'],
          use:"bicycle",
          mode:"start",
          title:'START',
          detail:"",
          fromLast:[],
          counter:controlPoint.length
        });
      }else{
        let isLast = ((gpx.trk.trkseg.trkpt.length - 1) <= (i + 1));
        if(hasWPT){
          pathPoint.push([
            gpx.trk.trkseg.trkpt[i]['@_lat'],
            gpx.trk.trkseg.trkpt[i]['@_lon'],
            false,
            {modifier:"",name:""}
          ])
            let matched:boolean = hasWPT;
            if(matched && ((wptFix.length - 1) >= w)){
              matched = (
                (gpx.trk.trkseg.trkpt[i]['@_lat'] === wptFix[w]['@_lat']) &&
                (gpx.trk.trkseg.trkpt[i]['@_lon'] === wptFix[w]['@_lon']));    
            }else{
              matched = false;
            }
            if((isLast)||(matched)){
            if(matched){
              pathPoint[(pathPoint.length - 1)][2] = true;
              pathPoint[(pathPoint.length - 1)][3].modifier = wptFix[w].name.toLowerCase();
              pathPoint[(pathPoint.length - 1)][3].name = wptFix[w].cmt;
            }
            controlPoint.push({
              lat:gpx.trk.trkseg.trkpt[i]['@_lat'],
              lng:gpx.trk.trkseg.trkpt[i]['@_lon'],
              use:"bicycle",
              mode:"",
              title:isLast?'End':'',
              detail:"",
              fromLast:pathPoint,
              counter:controlPoint.length
            });
            w++;
            pathPoint = [];
          }  
        }else{
          pathPoint.push([
            gpx.trk.trkseg.trkpt[i]['@_lat'],
            gpx.trk.trkseg.trkpt[i]['@_lon'],
            false,
            {modifier:"",name:""}
          ])
            if((isLast)||(pathPoint.length >=250)){
            controlPoint.push({
              lat:gpx.trk.trkseg.trkpt[i]['@_lat'],
              lng:gpx.trk.trkseg.trkpt[i]['@_lon'],
              use:"bicycle",
              mode:"",
              title:isLast?'End':'',
              detail:"",
              fromLast:pathPoint,
              counter:controlPoint.length
            });
            pathPoint = [];
          }  
        }
      }

    }
  }
  return {
    routeInfo:{
        id:-1,
        isPublic:true,
        title:gpx.metadata?.name,
        description:"",
        counter:(controlPoint.length + 1)
    },
    points:controlPoint,
    historyUndo:controlPoint.map((one)=>{
      return {...one,...{type:"point"}}
    }),
    historyRedo:[]
  }
}

function parseTcxFile(tcx:any){
  // console.log(tcx);
  let pathPoint:any[] = [];
  let controlPoint:typeControlPoint[] = [];

  const detector = tcx.Track.Trackpoint.map((one:any)=>{
    return `${one.Position.LatitudeDegrees}_${one.Position.LongitudeDegrees}` as string
  });

  const tcxFix:any[] = tcx.CoursePoint.filter((one:any)=>{
    const indexOf = detector.indexOf(`${one.Position.LatitudeDegrees}_${one.Position.LongitudeDegrees}` as string);
    return (indexOf >= 0)
  })
  let start = 0;
  for(let i=0;i<tcx.Track.Trackpoint.length;i++){
    let isLast = ((tcx.Track.Trackpoint.length - 1) <= i);

    let found:number = -1;
    for(let w=start;w<tcxFix.length;w++){
      if(
        (tcx.Track.Trackpoint[i].Position.LatitudeDegrees===tcxFix[w].Position.LatitudeDegrees)&&
        (tcx.Track.Trackpoint[i].Position.LongitudeDegrees===tcxFix[w].Position.LongitudeDegrees)){
          start = w + 1;
          found = w;
          break;
      }
    }

    //Ride with GPSで出力したTCXファイルを見たところ
    //"Notify before turn" の設定をすると
    //ルートの最後ではないところに最終ポイント設定されるので対策
    //その他のポイントもずれるが出力時に意図したものということでOKとする

    let isTurn = (found >= 0);
    let mode = "";
    if(i === 0){
      mode = "start"
    }else{
      if(found >= 0){
        if(tcxFix[found].Notes.startsWith('End of route')){
          isTurn = false
        }else{
          mode = tcxFix[found].PointType.toLowerCase()
        }
      }
    }

    let title = '';
    if(isLast){
      title = 'End'
    }else if(found >= 0){
      if(!tcxFix[found].Notes.startsWith('End of route')){
        title = tcxFix[found].Notes;
      }
    }

    pathPoint.push([
      tcx.Track.Trackpoint[i].Position.LatitudeDegrees,
      tcx.Track.Trackpoint[i].Position.LongitudeDegrees,
      isTurn,
      {
        modifier:mode,
        name:title
      }
    ])

    if((found >= 0)||isLast){
      controlPoint.push({
        lat:tcx.Track.Trackpoint[i].Position.LatitudeDegrees,
        lng:tcx.Track.Trackpoint[i].Position.LongitudeDegrees,
        use:"bicycle",
        mode:mode,
        title:title,
        detail:"",
        fromLast:(i===0)?[]:(((found >= 0)||isLast)?pathPoint:[]),
        counter:controlPoint.length
      });
  
    }
    if(found >= 0){
      pathPoint = [];
    }
  }
  return {
    routeInfo:{
        id:-1,
        isPublic:true,
        title:'no name',
        description:"",
        counter:(controlPoint.length + 1)
    },
    points:controlPoint,
    historyUndo:controlPoint.map((one)=>{
      return {...one,...{type:"point"}}
    }),
    historyRedo:[]
  }
}

function parseKmlFile(kml:any){
  // console.log('name = ',kml.name);
  const lines: string[] = (kml.Placemark.LineString.coordinates.split('\n')).map((one:any)=>{
    return one.split(',')
  });
  // console.log(lines);
  let pathPoint:any[] = [];
  let controlPoint:typeControlPoint[] = [];
  lines.forEach((line,idx) => {
    if(idx === 0){
      controlPoint.push({
        lat:Number(line[1]),
        lng:Number(line[0]),
        use:"bicycle",
        mode:"start",
        title:"START",
        detail:"",
        fromLast:[],
        counter:controlPoint.length
      });
    }else{
      let isLast = ((lines.length - 1) === idx);
      pathPoint.push([
        Number(line[1]),
        Number(line[0]),
        false,
        {modifier:"",name:""}
      ]);
      if(isLast||(pathPoint.length >=250)){
        controlPoint.push({
          lat:Number(line[1]),
          lng:Number(line[0]),
          use:"bicycle",
          mode:"",
          title:isLast?'GOAL':'',
          detail:"",
          fromLast:isLast?[]:pathPoint,
          counter:controlPoint.length
        });
        pathPoint = [];
      }
    }
  });
  // console.log(controlPoint);
  return {
    routeInfo:{
        id:-1,
        isPublic:true,
        title:kml.name,
        description:"",
        counter:(controlPoint.length + 1)
    },
    points:controlPoint,
    historyUndo:controlPoint.map((one)=>{
      return {...one,...{type:"point"}}
    }),
    historyRedo:[]
  }
}