import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";

import { Box, Button, ClickAwayListener, Grid, Grow, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ListIcon from '@mui/icons-material/List';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnLockIcon from '@mui/icons-material/VpnLock';

import "/node_modules/flag-icons/css/flag-icons.min.css";

import { LanguagePulldown, adjustFontPosition, fontDetect, getLanguageObj } from '../System/Locales';
import { SessionContext, screenSave, sessionIO, syncSession, typeSession } from '../System/Session';
import { isAgreed } from '../UI/Confirmation';

import { setTitle } from '../System/Title';
import { removeDB } from '../db/dbio';
import SettingsHasSession from './Settings/SettingsHasSession';
import SettingsNoSession from './Settings/SettingsNoSession';

import '../top.css';

const footerHeight = 66;

const baseButtonStyle = {
    fontSize:18,
    borderRadius:12,
    paddingTop:16,
    paddingBottom:16,
}

const styleBtnAll = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: baseButtonStyle, 
                contained:{
                    color:'#232323',
                    backgroundColor:'#ffffff',
                    "&:hover":{
                        backgroundColor:'#e2e2e2',
                    }
                }
            }, 
        }, 
    }, 
};

const themeBtnAll = createTheme(styleBtnAll);

const buttonMargin = {
    mx:2,my:3
};

const themeTypo = createTheme({
    typography:{
        h4:{
            textShadow:'0 0 10px #000000,0 0 14px #000000,0 0 14px #000000,4px 5px 7px #000000',
        }
    }
});

export default function Top(){
    //背景切り替え
    if(isMobile&&!isTablet){
        document.body.classList.add('cycroute-mobile');
        document.body.classList.remove('cycroute-pc');
    }else{
        document.body.classList.add('cycroute-pc');
        document.body.classList.remove('cycroute-mobile');
    }
    setTitle()
    return (
        <Box display='flex' height='100%'>
            <Contents />
        </Box>
    )
}

function Contents() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {sessionInfo,setSessionInfo,setConfirmation} = useContext(SessionContext);
    const GoogleFonts = fontDetect(sessionInfo.language);
    const GoogleFontEn = getLanguageObj('en'); //ロゴ用
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const parent = useRef<HTMLInputElement>(null);
    const [outerWidth, setOuterWidth] = useState(0);
    const [sessionReloaded,setSessionReloaded] = useState<boolean>(false);
    const handleClickPublic = async () => {
        const pageTo:string = process.env.REACT_APP_PAGE_PUBLIC_DEFAULT??'';
        screenSave(location.pathname,pageTo);
        navigate(pageTo);
    }
    const handleNew = () => {
        const pageTo = '/editor';
        screenSave(location.pathname,pageTo);
        navigate(pageTo);
    }
    const handleRouteList = async () => {
        navigate('/myroutes')
    }
    const handleSignIn = (event:React.MouseEvent<HTMLButtonElement>) =>{
        navigate('/signin')
    }
    const handleSignUp = (event:React.MouseEvent<HTMLButtonElement>) =>{
        navigate('/signup')
    }
    const handleResetPassword = () => {
        navigate('/resetpassword')
    }
    const sessionReload = () => {
        let mySession = new sessionIO();
        mySession.check({
            update:true,
            onThen:((content)=>{
                if(content.has_session){
                    syncSession(content,setSessionInfo);
                }else{
                    setSessionInfo((prev)=>({...prev,...{hasSession:false,loaded:true}}));
                }
            }),
            onCatch:((err)=>{
                // console.log('here is "catch"');
            }),
            onFinally:(()=>{
                // console.log('here is "finally"');
                setSessionReloaded(true)
            }),
        })
    }
    useEffect(() => {
        sessionReload();
        function handleWindowResize(){
            if(parent != null){
                if(parent.current != null){
                    setOuterWidth(parent.current.offsetWidth);
                }
            }
        }
        handleWindowResize(); //'onload'用
        if(typeof window !== 'undefined'){
            window.addEventListener('resize', handleWindowResize);
            return () => {
                window.removeEventListener('resize', handleWindowResize);
            };
        }
        // eslint-disable-next-line
    },[]);
    useEffect(() => {
        if(sessionInfo.loaded && !sessionInfo.hasSession){
            if(!isAgreed()){
                setConfirmation((prev)=>({...prev,...{open:true}}))
            }
        }
        // eslint-disable-next-line
    },[sessionInfo.loaded])
    return (
        <Box
            width="100vw"
            height="100%" display="flex" flexDirection="column"
            sx={{backgroundColor:'transparent'}} ref={parent}>
            <Grid container spacing={0} columns={{xs:12,sm:12,md:24,lg:24}}>
                <Grid item xs={12} sm={12} md={11} lg={9}>
                    <Box sx={buttonMargin}>
                        <ThemeProvider theme={themeBtnAll}>
                            <Button fullWidth variant="contained" size="large" startIcon={<PublicIcon />} className={GoogleFonts.className} onClick={handleClickPublic}><span style={styleBtnText}>{t("top.btnRoutePublic")}</span></Button>
                        </ThemeProvider>
                    </Box>
                    {(sessionReloaded && sessionInfo.hasSession) &&
                        <React.Fragment>
                            <Box sx={buttonMargin}>
                                <ThemeProvider theme={themeBtnAll}>
                                    <Button fullWidth variant="contained" size="large" startIcon={<AddCircleOutlineIcon />} className={GoogleFonts.className} onClick={handleNew}><span style={styleBtnText}>{t("top.btnRouteNew")}</span></Button>
                                </ThemeProvider>
                            </Box>
                            <Box sx={buttonMargin}>
                                <ThemeProvider theme={themeBtnAll}>
                                    <Button fullWidth variant="contained" size="large" startIcon={<ListIcon />} className={GoogleFonts.className} onClick={handleRouteList}><span style={styleBtnText}>{t("top.btnRouteList")}</span></Button>
                                </ThemeProvider>
                            </Box>
                        </React.Fragment>
                    }
                    {(sessionReloaded && !sessionInfo.hasSession) &&
                        <React.Fragment>
                            <Box sx={buttonMargin}>
                                <Grid container columns={{xs:19}}>
                                    <Grid item xs={9}>
                                        <ThemeProvider theme={themeBtnAll}>
                                            <Button fullWidth variant="contained" size="large" className={GoogleFonts.className} onClick={handleSignIn}><span style={styleBtnText}>{t("top.btnSignIn")}</span></Button>
                                        </ThemeProvider>
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <ThemeProvider theme={themeBtnAll}>
                                            <Button fullWidth variant="contained" size="large" className={GoogleFonts.className} onClick={handleSignUp}><span style={styleBtnText}>{t("top.btnSignUp")}</span></Button>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box sx={{m:0,textAlign:'center'}}>
                                <span onClick={handleResetPassword} className={GoogleFonts.className} style={{color:'#ffffff',fontSize:'20px',textShadow:'0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000',textDecoration:'underline',cursor:'pointer'}}>{t('top.reasetPassword')}</span>
                            </Box>
                        </React.Fragment>
                    }
                </Grid>
                <Grid item xs={0} sm={0} md={2} lg={6} display={{xs:"none",sm:"none",md:"block",lg:"block"}}>
                    {/* spacer */}
                </Grid>
                {sessionReloaded &&
                    <Grid item xs={12} sm={12} md={11} lg={9}>
                        <Box sx={{m:3,mt:6,textAlign:'center'}}>
                            <ThemeProvider theme={themeTypo}>
                                <Typography variant="h4" sx={{color:'#ffffff',fontSize:(outerWidth>=900)?'6.1rem !important':'4.0rem !important'}} className={GoogleFontEn.className}>Cycroute</Typography>
                            </ThemeProvider>
                        </Box>
                        {(!sessionInfo.hasSession) &&
                            <Box sx={{m:3,mt:7}}>
                                <ThemeProvider theme={themeTypo}>
                                    <Typography variant="h4" sx={{color:'#ffffff',fontSize:(outerWidth>=900)?'2.125rem !important':'1.70rem !important'}} className={GoogleFonts.className}>{t("top.policyMessage")}</Typography>
                                </ThemeProvider>
                            </Box>
                        }
                    </Grid>
                }
            </Grid>
            {sessionReloaded && <Footer /> }
        </Box>
    );
}

const commonShadow = '0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000';

const bottomLinkStyle = {
    color:'#dfdfdf',
    fontSize:'13px',
    textShadow:commonShadow,
    textDecoration:'underline',
    textUnderlineOffset:'3px',
    cursor:'pointer'
} as React.CSSProperties;

function Footer(){
    const {t} = useTranslation();
    const {sessionInfo} = useContext(SessionContext);
    const GoogleFonts = fontDetect(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [settingsOpen,setSettingsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleSettings = (event:React.MouseEvent<HTMLButtonElement>) =>{
        setSettingsOpen(true);
    }
    const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
        // setMenuOpen(true);
        setAnchorEl(e.currentTarget);
    }
    
    return (
        <React.Fragment>
            <Box sx={{height:`${footerHeight}px`,lineHeight:`${footerHeight}px`}}>&nbsp;</Box>
            {sessionInfo.hasSession &&
                <React.Fragment>
                    <Box sx={(theme) => ({position:'fixed',bottom:10,left:10,width:'200px',lineHeight:'42px',backgroundColor:'transparent',boxShadow:'none !important',textAlign:'left'})}>
                        <ThemeProvider theme={themeBtnAll}>
                            <Button variant="contained" size="small" startIcon={<MenuIcon />} className={GoogleFonts.className} sx={{height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleMenu}><span style={styleBtnText}>{t("top.btnMenu")}</span></Button>
                        </ThemeProvider>
                        <SettingsHasSession settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
                    </Box>
                    <SettingMenu setSettingsOpen={setSettingsOpen} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
                </React.Fragment>
            }
            {!sessionInfo.hasSession &&
                <React.Fragment>
                    <Box sx={(theme) => ({position:'fixed',bottom:35,right:10,width:'200px',lineHeight:'42px',backgroundColor:'transparent',boxShadow:'none !important',textAlign:'right'})}>
                        <ThemeProvider theme={themeBtnAll}>
                            <Button variant="contained" size="small" startIcon={<SettingsIcon />} className={GoogleFonts.className} sx={{height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleSettings}><span style={styleBtnText}>{t("top.btnSettings")}</span></Button>
                        </ThemeProvider>
                        <SettingsNoSession settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
                    </Box>
                    <Box sx={(theme) => ({position:'fixed',bottom:35,left:10,width:'165px',lineHeight:'42px',color:'#ffffff',backgroundColor:'transparent',textAlign:'left',boxShadow:'none !important'})}>
                        <Box sx={{minWidth:'auto',maxWidth:'auto',display:'inline-block',textAlign:'center'}}>
                            <LanguagePulldown />
                        </Box>
                    </Box>
                </React.Fragment>
            }
            <Box sx={{position:'fixed',bottom:6,right:8,height:'24px',lineHeight:'24px',textAlign:'right'}} >
                <a href={`${process.env.REACT_APP_URL_TERMS_OF_SERVICE??'#'}`} target='_blank' rel="noreferrer" className={GoogleFonts.className} style={{...bottomLinkStyle,...{marginRight:'7px'}}}>{t('termsOfService.title')}</a>
                <a href={`${process.env.REACT_APP_URL_PRIVACY_POLICY??'#'}`} target='_blank' rel="noreferrer" className={GoogleFonts.className} style={bottomLinkStyle}>{t('privacy.title')}</a>
            </Box>
        </React.Fragment>
    )
}

function SettingMenu(
    {setSettingsOpen,anchorEl,setAnchorEl}:{
        setSettingsOpen:React.Dispatch<React.SetStateAction<boolean>>,
        anchorEl:HTMLElement | null,
        setAnchorEl:React.Dispatch<React.SetStateAction<HTMLElement | null>>
}){
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const {sessionInfo,setBackdrop,setSessionInfo} = useContext(SessionContext);
    const GoogleFonts = fontDetect(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const open = Boolean(anchorEl);
    const handleLogout = (e: any) => {
        logout(setSessionInfo,setBackdrop,navigate,location);
        setAnchorEl(null);
    }
    const handleSettings = (e: any) =>{
        setSettingsOpen(true);
        setAnchorEl(null);
    }
    const handlePrivacyArea = (e: any) =>{
        const pageTo = '/privacy_area';
        screenSave(location.pathname,pageTo);
        navigate(pageTo);
        setAnchorEl(null);
    }
    const handleProfile = (e: any) => {
        const pageTo = '/profile_editor';
        screenSave(location.pathname,pageTo);
        navigate(pageTo);
        setAnchorEl(null);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleListKeyDown = () => {

    }
    return (
        <React.Fragment>
            <Popper anchorEl={anchorEl} open={open} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow {...TransitionProps} style={{transformOrigin:placement === 'top-end' ? 'left top' : 'left bottom'}}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                    <MenuItem onClick={handleLogout}><LogoutIcon />&nbsp;<span className={GoogleFonts.className} style={styleBtnText}>{t("top.btnLogout")}</span></MenuItem>
                                    <MenuItem onClick={handleProfile}><PersonIcon />&nbsp;<span className={GoogleFonts.className} style={styleBtnText}>{t("top.btnProfile")}</span></MenuItem>
                                    <MenuItem onClick={handlePrivacyArea}><VpnLockIcon />&nbsp;<span className={GoogleFonts.className} style={styleBtnText}>{t("top.btnPrivacyArea")}</span></MenuItem>
                                    <MenuItem onClick={handleSettings}><SettingsIcon />&nbsp;<span className={GoogleFonts.className} style={styleBtnText}>{t("top.btnSettings")}</span></MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </React.Fragment>
    )
}

//※ 引数は全てReactコンポーネント側でないと呼び出せないもののみ
export function logout(
    setSessionInfo:React.Dispatch<React.SetStateAction<typeSession>>,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    navigate:NavigateFunction,
    location:Location<any>
){
    setBackdrop(true);
    let mySession = new sessionIO();
    mySession.logout({onThen:((content)=>{
        console.log('LOGGED OUT')
        removeDB();
        setSessionInfo((prev)=>({...prev,...{hasSession:false}}));
        if(location.pathname !== '/'){
            navigate('/')
        }
    }),
    onCatch:((err)=>{
        // console.log('here is "catch"');
    }),
    onFinally:(()=>{
        // console.log('here is "finally"');
        setBackdrop(false)
    })});
}
// async function fetchData() {
//     const uri = 'https://api.strapi.cycroute.com/api/crposts?filters\\[cr_title\\][uuid][$eq]=8064ec503876bd749f06de0dc954a55545d0&locale=ja';
//     // const response = await fetch(uri);
//     // const data = await response.json();
//     // return data;
//     await fetch(uri,{method:'GET',mode:'cors',cache:'no-store'}).then(response => response.json()).then(function(data){
//         console.log(data);
//     });
// }
