import _ from 'lodash';
import { typePointComp } from '../../System/RouteData';

export function RouteEditorUndo(
    pointComp:typePointComp,setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>){
    if(pointComp.historyUndo.length > 0){
        // const lastOne = {...pointComp.historyUndo[pointComp.historyUndo.length - 1]};
        const lastOne = _.cloneDeep(pointComp.historyUndo[pointComp.historyUndo.length - 1]);
        if(lastOne.type === 'point'){
            const lastTwo = _.cloneDeep(pointComp.points[pointComp.points.length - 1]);
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                // points:prev.points.filter((one) => (one.counter !== lastOne.counter)),
                points:prev.points.slice(0,-1),
                historyUndo:prev.historyUndo.slice(0,-1),
                historyRedo:[...prev.historyRedo,{...lastTwo,...{type:'point'}}]
            }));
        }else if(lastOne.type === 'clear'){
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:lastOne.points,
                historyUndo:prev.historyUndo.slice(0,-1),
                historyRedo:[...prev.historyRedo,{...lastOne,...{type:'clear'}}]
            }));
        }else if(lastOne.type === 'point_update'){
            const lastTwo = {...pointComp.points[lastOne.indexes.parent].fromLast[lastOne.indexes.child]};
            const redoTwo = {...lastOne,...{data:lastTwo}};
            
            // console.log('lastOne A',lastOne);//更新前のデータ
            // console.log('lastOne B',lastTwo);//現在のデータ
            // console.log('lastOne C',redoTwo);//REDO用のデータ
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:prev.points.map((one,idx1)=>{
                    if(idx1 === lastOne.indexes.parent){
                        return {...one,...{
                            fromLast:one.fromLast.map((two,idx2)=>{
                                if(idx2 === lastOne.indexes.child){
                                    return lastOne.data
                                }else{
                                    return two
                                }
                            })
                        }}
                    }else{
                        return one
                    }
                }),
                historyUndo:prev.historyUndo.slice(0,-1),
                historyRedo:[...prev.historyRedo,{...redoTwo,...{type:'point_update'}}]
                // historyRedo:[...prev.historyRedo,redoTwo]
            }));
        }else if(lastOne.type === 'points_replace'){
            const lastTwo = _.cloneDeep(pointComp.points[lastOne.indexes.parent]);
            const redoTwo = _.cloneDeep({...lastOne,...{data:lastTwo}});
            // console.log("lastTwo",lastTwo);
            // console.log("redoTwo",redoTwo);
            const targetIdx = lastOne.indexes.parent;
            // const targetData = {...lastOne.data};
            const targetData = _.cloneDeep(lastOne.data);
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:prev.points.map((one,idx) => {
                    if(idx === targetIdx){
                        return targetData
                    }else{
                        return one
                    }
                }),
                historyUndo:prev.historyUndo.slice(0,-1),
                historyRedo:[...prev.historyRedo,{...redoTwo,...{type:'points_replace'}}]
            }));
        }
    }
}

export function RouteEditorRedo(
    pointComp:typePointComp,setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>
){
    if(pointComp.historyRedo.length > 0){
        // const lastOne = {...pointComp.historyRedo[pointComp.historyRedo.length - 1]};
        const lastOne = _.cloneDeep(pointComp.historyRedo[pointComp.historyRedo.length - 1]);
        if(lastOne.type === 'point'){
            delete lastOne.type;
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:[...prev.points,lastOne],
                historyUndo:[...prev.historyUndo,{...lastOne,...{type:'point'}}],
                historyRedo:prev.historyRedo.slice(0,-1)
            }));
        }else if(lastOne.type === 'clear'){
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:[],
                historyUndo:[...prev.historyUndo,lastOne],
                historyRedo:prev.historyRedo.slice(0,-1)
            }));
        }else if(lastOne.type === 'point_update'){
            // console.log('lastOne A',lastOne);//REDO用のデータ
            const lastTwo = {...pointComp.points[lastOne.indexes.parent].fromLast[lastOne.indexes.child]};
            const undoTwo = {...lastOne,...{data:lastTwo}};
            // console.log('lastOne B',lastTwo);//現在のデータ
            // console.log('lastOne C',undoTwo);//再UNDO用のデータ
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:prev.points.map((one,idx1)=>{
                    if(idx1 === lastOne.indexes.parent){
                        return {...one,...{
                            fromLast:one.fromLast.map((two,idx2)=>{
                                if(idx2 === lastOne.indexes.child){
                                    return lastOne.data
                                }else{
                                    return two
                                }
                            })
                        }}
                    }else{
                        return one
                    }
                }),
                historyRedo:prev.historyRedo.slice(0,-1),
                historyUndo:[...prev.historyUndo,{...undoTwo,...{type:'point_update'}}]
            }));
        }else if(lastOne.type === 'points_replace'){
            const lastTwo = _.cloneDeep(pointComp.points[lastOne.indexes.parent]);
            const undoTwo = _.cloneDeep({...lastOne,...{data:lastTwo}});
            const targetIdx = lastOne.indexes.parent;
            // const targetData = {...lastOne.data};
            const targetData = _.cloneDeep(lastOne.data);
            console.log("targetData",targetData);
            console.log("undoTwo",undoTwo);
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:prev.points.map((one,idx) => {
                    if(idx === targetIdx){
                        return targetData
                    }else{
                        return one
                    }
                }),
                historyRedo:prev.historyRedo.slice(0,-1),
                historyUndo:[...prev.historyUndo,{...undoTwo,...{type:'points_replace'}}]
            }));
        }
    }
}