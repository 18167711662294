import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import i18n from "i18next";
import { detectLang, detectUnit, getDefaultDatetimeFormat, getDefaultLocation } from './System/Locales';
import { SessionContext, typeCommonAlert, typeConfirmation, typeSession } from './System/Session';
import { fixViewport } from './System/Viewport';
import Loading from "./UI/Loading";
import { polyLineStyle } from './UI/Map/Casts';

import { CallbackGoogle } from './System/GoogleOauth';
import { localVersion, typeVersionObj } from './System/Version';
import Top from "./UI/Top";
// import Onetime from "./UI/Onetime/Onetime";
// import RouteEditor from "./UI/RouteEditor/RouteEditor";
// import MyRoutes from "./UI/MyRoutes/MyRoutes";
// import PrivacyMap from "./UI/PrivacyMap/PrivacyMap";
// import ProfileEditor from "./UI/Profile/ProfileEditor";
import Confirmation from './UI/Confirmation';
import PrivacyPolicy from "./UI/PrivacyPolicy";
import ResetPassword from "./UI/SendResetPassword";
import Signinup from "./UI/Signinup";
import TermsOfService from "./UI/TermsOfService";

//ログイン必須系とワンタイム系はlazy処理
const Onetime = React.lazy(() => import('./UI/Onetime/Onetime'));
const RouteEditor = React.lazy(() => import('./UI/RouteEditor/RouteEditor'));
const Public = React.lazy(() => import('./UI/Public/Public'));
const MyRoutes = React.lazy(() => import('./UI/MyRoutes/MyRoutes'));
const RouteViewer = React.lazy(() => import('./UI/RouteViewer/RouteViewer'));
const PrivacyMap = React.lazy(() => import('./UI/PrivacyMap/PrivacyMap'));
const ProfileEditor = React.lazy(() => import('./UI/Profile/ProfileEditor'));
const ProfileViewer = React.lazy(() => import('./UI/Profile/ProfileViewer'));

export default function App() {
  fixViewport();
  const defaultLocation = getDefaultLocation();
  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const defaultDatetimeFormat = getDefaultDatetimeFormat();
  const locale:string = detectLang();
  const unit:string = detectUnit();
  const [sessionInfo,setSessionInfo] = useState<typeSession>({
    loaded:false,hasSession:false,isAdmin:false,
    language:locale??"en",timezone:defaultTimeZone,device:'pc',datetimeFormat:defaultDatetimeFormat,
    timedOut:false,isLefty:false,skipStraight:false,disableDoubleClick:true,unit:unit,noAds:false,
    routeColor:{
      default:polyLineStyle.default.color,
      hover:polyLineStyle.hover.color
    },
    defaultLocation:defaultLocation,
    map:'default'
  });
  const [backdrop,setBackdrop] = useState<boolean>(false);
  const [commonAlert,setCommonAlert] = useState<typeCommonAlert>({open:false,title:"",message:"",button:""});
  const [version,setVersion] = React.useState<typeVersionObj>({
    local:localVersion,
    remote:localVersion,
    upgradable:false,
    alert:false
  });
  const [adsHeight,setAdsHeight] = useState<number>(0);
  const [confirmation,setConfirmation] = useState<typeConfirmation>({open:false,agree:false,force:false,datetime:null})
  const isFirstLoad = React.useRef(false);
  useEffect(() => {
    // console.log(sessionInfo);
    document.documentElement.lang = sessionInfo.language;
    i18n.changeLanguage(sessionInfo.language);
  },[sessionInfo]);
  useEffect(() => {
    if(!isFirstLoad.current){
        isFirstLoad.current = true;
        // fixViewport();
        // setSessionInfo((prev)=>({...prev,...{loaded:true}}));
    }
    // eslint-disable-next-line
  },[]);
  return (
    <SessionContext.Provider value={{
      sessionInfo,setSessionInfo,
      backdrop,setBackdrop,
      commonAlert,setCommonAlert,
      version,setVersion,
      adsHeight,setAdsHeight,
      confirmation,setConfirmation}}>
      <CssBaseline />
      <Suspense fallback={<Loading />}>
        <URLDetection />
      </Suspense>
      {/* 汎用プログレス */}
      {/* <Backdrop sx={{color:'#fff',zIndex:(theme:any) => theme.zIndex.drawer + 1}} open={backdrop}> */}
      <Backdrop sx={{color:'#fff',zIndex:9999}} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* 汎用アラート */}
      <AnyAlert commonAlert={commonAlert} setCommonAlert={setCommonAlert} />
      <Confirmation />
    </SessionContext.Provider>
  );
}

function URLDetection(){
  const URL_PUBLIC_MAP:string = process.env.REACT_APP_PAGE_PUBLIC_MAP??'';
  const URL_PUBLIC_LIST:string = process.env.REACT_APP_PAGE_PUBLIC_LIST??'';
  /* ログインしないとアクセスできないページは同意済みなのでCookieダイアログ不要 */
  /* ログイン/アカウント登録機能はそれ自体で確認するためCookieダイアログは邪魔なので不要 */
  /* ワンタイムURLは邪魔なのでCookieダイアログ不要 */
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Top /> } />
        <Route path="/editor" element={ <RouteEditor /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/resetpassword" element={ <ResetPassword /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/signup" element={ <Signinup /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/signin" element={ <Signinup /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/terms_of_service" element={ <TermsOfService /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/privacy_policy" element={ <PrivacyPolicy /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/onetime" element={ <Onetime /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/callbackgoogle" element={ <CallbackGoogle /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/privacy_area" element={ <PrivacyMap /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/myroutes" element={ <MyRoutes /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/routeviewer/:routeId" element={ <RouteViewer /> } />
        <Route path="/myrouteviewer" element={ <RouteViewer /> } /> {/* Cookieダイアログ不要 */}
        <Route path={URL_PUBLIC_MAP} element={ <Public /> } />
        <Route path={URL_PUBLIC_LIST} element={ <Public /> } />
        <Route path="/profile_editor" element={ <ProfileEditor /> } /> {/* Cookieダイアログ不要 */}
        <Route path="/profile/:nanoId" element={ <ProfileViewer /> } />
      </Routes>
    </BrowserRouter>
  )
}

//汎用アラート
function AnyAlert({commonAlert,setCommonAlert}:{commonAlert:typeCommonAlert,setCommonAlert:React.Dispatch<React.SetStateAction<typeCommonAlert>>}) {

  const handleClose = () => {
    if(commonAlert.callback !== null && commonAlert.callback !== undefined){
      commonAlert.callback();
    }
    setCommonAlert((prev)=>({...prev,...{open:false,title:"",message:"",button:"",callback:null}}));
  }

  return (
    <React.Fragment>
      <Dialog open={commonAlert.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        {(commonAlert.title !== '') &&
          <DialogTitle id="alert-dialog-title">{commonAlert.title}</DialogTitle>
        }
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{commonAlert.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{commonAlert.button}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
