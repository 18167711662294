import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from "react-i18next";

import { adjustFontPosition, fontDetect } from '../System/Locales';
import { SessionContext, typeSession } from '../System/Session';

const styleOuter = {
    position:'fixed',
    padding:'10px',
    width:'320px',
    backgroundColor:'#FFFAE6',
    bottom:12,left:12,
    border:'1px solid #787878',
    borderRadius:'5px',
    boxShadow:'10px 10px 17px 1px rgba(17, 17, 26, 0.18),20px 20px 17px 1px rgba(17, 17, 26, 0.18),0px 0px 32px 16px rgba(17, 17, 26, 0.18)',
    zIndex:1600
}

export default function Confirmation(){
    const {t} = useTranslation();
    const {sessionInfo,confirmation,setConfirmation} = React.useContext(SessionContext);
    const GoogleFonts = fontDetect(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [open,setOpen] = React.useState<boolean>(false);

    let message = t('confirmation.message')
    .replace('<privacy_policy>',process.env.REACT_APP_URL_PRIVACY_POLICY??'#');
    const handleAgree = () => {
        const datetime = agreementSave(true)
        setConfirmation((prev)=>({...prev,...{open:false,agree:true,datetime:datetime}}))
        setOpen(false)
    }
    const handleDisagree = () => {
        const datetime = agreementSave(false)
        setConfirmation((prev)=>({...prev,...{open:false,agree:false,datetime:datetime}}))
        setOpen(false)
    }
    React.useEffect(()=>{
        if(confirmation.open){
            if(confirmation.force){
                setOpen(true);
            }else{
                const storedData = agreementLoad();
                if(storedData){
                    // console.log(storedData)
                    if(!storedData.agreement){
                        setOpen(true);
                    }
                }else{
                    setOpen(true);
                }
            }
        }
        // eslint-disable-next-line
    },[confirmation.open]);
    return (
        <React.Fragment>
            {open &&
                <Box sx={styleOuter}>
                    <Box>
                        <span className={GoogleFonts.className}  dangerouslySetInnerHTML={{__html:message}} />
                    </Box>
                    <Box sx={{display:'flex',justifyContent:'space-between'}}>
                        <Button onClick={handleAgree}>
                            <span className={GoogleFonts.className} style={{...styleBtnText,...{fontSize:'1.15em'}}}>{t('confirmation.buttonAgree')}</span>
                        </Button>
                        <Button onClick={handleDisagree}>
                            <span className={GoogleFonts.className} style={{...styleBtnText,...{fontSize:'1.15em'}}}>{t('confirmation.buttonDisagree')}</span>
                        </Button>
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
}

const nameAgree = 'agree';

export function isAgreed(sessionInfo?:typeSession|null){
    let result = false;
    let checkLocalStorage = false;
    if(sessionInfo){
        if(sessionInfo.hasSession){
            result = true;
        }else{
            checkLocalStorage = true;
        }
    }else{
        checkLocalStorage = true;
    }
    if(checkLocalStorage){
        const storedData = agreementLoad();
        if(storedData){
            if(storedData.agreement){
                result = storedData.agreement;
            }
        }
    }
    return result;
}
function agreementLoad(){
    const storedDateString = window.localStorage.getItem(nameAgree);
    if(storedDateString){
        const storedData = JSON.parse(storedDateString)
        return {...storedData,...{datetime:new Date(storedData.datetime)}};
    }else{
        return null;
    }
}

export function agreementClear(){
    window.localStorage.removeItem(nameAgree);
}
function agreementSave(agreement:boolean){
    const now = new Date();
    const nowString = now.toISOString();
    const data = {
        datetime:nowString,
        agreement:agreement
    }
    if(agreement){
        window.localStorage.setItem(nameAgree,JSON.stringify(data))
    }else{
        agreementClear()
    }
    return now;
}