import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import * as React from 'react';
import { useContext } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from "react-i18next";

import { adjustFontPosition, fontDetect } from '../../System/Locales';
import { SessionContext } from '../../System/Session';
import { dbname } from '../../db/dbio';

import { themeBtnAll } from '../../UI/RouteEditor/RouteEditorColumnLeft';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type typeSettingsDialog = {
  settingsOpen:boolean;
  setSettingsOpen:React.Dispatch<React.SetStateAction<boolean>>
}

export default function SettingsNoSession({settingsOpen,setSettingsOpen}:typeSettingsDialog) {
  const {t} = useTranslation();
  const {sessionInfo,setConfirmation} = useContext(SessionContext);
  const GoogleFonts = fontDetect(sessionInfo.language);
  const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
  const handleClose = () => {
    setSettingsOpen(false);
    setConfirmation((prev)=>({...prev,...{open:true}}))
  };
  const handleDelete = () => {
    deleteAllData();
    setConfirmation((prev)=>({...prev,...{open:true}}))
  }
  return (
    <React.Fragment>
      <Dialog fullScreen open={settingsOpen} onClose={handleClose} TransitionComponent={Transition} sx={{zIndex:1601}}>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={1} sx={{padding:'10px 16px',overflow:'auto'}}>

          <Box sx={{position:'absolute',bottom:(isMobile && !isTablet)?'0px':undefined}}>
            <Box>
              <ThemeProvider theme={themeBtnAll}>
                <Button onClick={handleDelete} variant="contained" size="small" sx={{marginTop:'6px',lineHeight:'28px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}}>
                  <span className={GoogleFonts.className} style={styleBtnText}>プライバシーポリシー同意の撤回</span>
                </Button>
              </ThemeProvider>
              <Box sx={{padding:'5px 8px 8px 8px'}}>
                <span className={GoogleFonts.className}>あわせて当ドメインに関連する Cookie, LocalStorage, IndexedDB を削除します。</span>
              </Box>
            </Box>
            {false &&
              <Box sx={{marginTop:'10px'}}>
                <ThemeProvider theme={themeBtnAll}>
                  <Button onClick={handleDelete} variant="contained" size="small" sx={{marginTop:'6px',lineHeight:'28px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}}>
                    <span className={GoogleFonts.className} style={styleBtnText}>当ドメインに関連する Cookie, LocalStorage, IndexedDB の削除</span>
                  </Button>
                </ThemeProvider>
              </Box>
            }
            <Box sx={{height:'64px'}}>{/* spacer */}</Box>
          </Box>
        </Box>
        <AppBar sx={{ top: 'auto', bottom:'0px', backgroundColor: '#777777' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ml:2,flex:1}} variant="h6" component="div">
              <span className={GoogleFonts.className}>{t("top.btnSettings")}</span>
            </Typography>
          </Toolbar>
        </AppBar>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

// Cookieの削除
const deleteAllCookies = () => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

// localStorageの削除
const deleteLocalStorage = () => {
  localStorage.clear();
};

// indexedDBの削除
const deleteIndexedDB = () => {
  return new Promise<void>((resolve, reject) => {
      const indexedDBRequest = indexedDB.deleteDatabase(dbname);
      indexedDBRequest.onsuccess = () => {
          resolve();
      };
      indexedDBRequest.onerror = (event) => {
          reject((event.target as IDBRequest).error);
      };
      indexedDBRequest.onblocked = () => {
          console.log("Delete blocked, but will proceed when unblocked");
      };
  });
};

// 全てのデータ削除処理をまとめて実行
const deleteAllData = async () => {
  try {
      deleteAllCookies();
      deleteLocalStorage();
      await deleteIndexedDB();
      console.log("All data deleted successfully.");
  } catch (error) {
      console.error("Error deleting data: ", error);
  }
};

