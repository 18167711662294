
import { isMobile, isTablet } from "react-device-detect";

export function fixViewport(){
    
    if(isMobile && !isTablet){
        if(window.innerWidth < 390){
            const scale = Math.ceil((window.innerWidth/393)*10000)/10000
            const viewportMeta = document.querySelector('meta[name="viewport"]');
            if(viewportMeta){
                viewportMeta.setAttribute('content',`width=device-width,initial-scale=${scale},maximum-scale=${scale},user-scalable=0`);
            }
        }
    }
}