import * as React from 'react';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { getLanguageObj } from '../../System/Locales';
import { RouteContext, RouteDataStorageDelete, pointCompBlabk } from '../../System/RouteData';
import { SessionContext, screenLoad } from '../../System/Session';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RouteEditorExit({dialogOpen,setDialogOpen,t}:{dialogOpen:any,setDialogOpen:React.Dispatch<any>,t:any}) {
    const location = useLocation();
    const {sessionInfo} = useContext(SessionContext);
    const {setPointComp} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const navigate = useNavigate();
    const handleClose = (agree:boolean) => {
        if(agree){
            RouteDataStorageDelete(); //DB削除なのでマイルート一覧も更新されるが良いのか
            setPointComp(pointCompBlabk)

            let pageFrom = screenLoad(location.pathname);
            if(pageFrom===''){
              pageFrom = '/'
            }
            navigate(pageFrom);
        }
        setDialogOpen((prev:any)=>({...prev,...{exit:false}}));
    };

  return (
    <React.Fragment>
      <Dialog
        open={dialogOpen.exit}
        TransitionComponent={Transition}
        keepMounted
        onClose={()=>handleClose(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><span className={GoogleFonts.className}>{t('routeEditor.dialog.exit.title')}</span></DialogTitle>
        <DialogContent>
          <DialogContentText><span className={GoogleFonts.className}>{t('routeEditor.dialog.exit.message')}</span></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose(false)}><span className={GoogleFonts.className}>{t('routeEditor.dialog.exit.disagree')}</span></Button>
          <Button onClick={()=>handleClose(true)}><span className={GoogleFonts.className}>{t('routeEditor.dialog.exit.agree')}</span></Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
