//スマホ専用の "UNDO" & "REDO"
import React, { useContext, useState } from 'react';
import { useTranslation } from "react-i18next";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, ButtonGroup, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import TurnSharpRightRoundedIcon from '@mui/icons-material/TurnSharpRightRounded';

import { adjustFontPosition, getLanguageObj } from '../../System/Locales';
import { RouteContext } from '../../System/RouteData';
import { SessionContext } from '../../System/Session';
import { swipeBorderMove } from '../Map/Casts';
import { typeSidesVisible } from '../Map/SideArrows';
import { typeRouteOpt } from './RouteEditor';
import { AutocueCheck, ModeRadio, themeBtnAll } from './RouteEditorColumnLeft';

import MenuIcon from '@mui/icons-material/Menu';

//const outerHeight = 76;
// const outerHeight = 145;
// const outerHeight = 142;
// const outerHeight = 190;
export const outerHeight = {
    open:190,
    close:48
};

const stylePagerOuter ={
    width:'100%',
    height:`${outerHeight.close}px`,
    backgroundColor:'#9f9f9f',
    borderBottom:'1px solid #9f9f9f'
};
const styleDrawerButton = {
    display:'inline-block',
    padding:'0px 12px',
    width:'auto',height:stylePagerOuter.height,lineHeight:stylePagerOuter.height,
    borderStyle:'solid',borderColor:'#9f9f9f',
    borderWidth:'0px 0px 1px 0px',
    verticalAlign:'top',
    position:'absolute'
};

const stylePagerBase = {
    display:'inline-block',
    padding:'0px 24px',
    width:'auto',
    height:`${(outerHeight.close + 1)}px`,
    lineHeight:`${(outerHeight.close + 1)}px`,
    borderStyle:'solid',borderColor:'#9f9f9f',
    verticalAlign:'top',
    borderRadius:'8px 8px 0px 0px'
};
const stylePagerActive = {...stylePagerBase,...{
    backgroundColor:'#dedede',
    borderWidth:'1px 1px 0px 1px'
}};
const stylePagerInactive = {...stylePagerBase,...{
    backgroundColor:'#bfbfbf',
    borderWidth:'1px 1px 1px 1px'
}};

const styleIconInPager = {
    position:'relative',
    top:'5px',
    fontSize:'1.9em'
};

export default function RouteEditorRowBottom(
    {sidesVisible,routeOpt,setRouteOpt,handleZoomIn,handleZoomOut,setBoundTrigger,setSidesVisible,map,setResizeTrigger}:
    {
        sidesVisible:typeSidesVisible,
        routeOpt:typeRouteOpt,
        setRouteOpt:React.Dispatch<React.SetStateAction<typeRouteOpt>>,
        handleZoomIn:() => void,
        handleZoomOut:() => void,
        setBoundTrigger:React.Dispatch<React.SetStateAction<boolean>>,
        setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>,
        map:L.Map|null,
        setResizeTrigger:React.Dispatch<React.SetStateAction<boolean>>}){
    const {t} = useTranslation();
    const {sessionInfo} = useContext(SessionContext);
    const {optionalInfo} = useContext(RouteContext);
    const {pointComp,setPointComp} = useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [bottomOpen,setBottomOpen] = React.useState<boolean>(true);

    //単語が長い言語用の調整
    let styleBtnTextFix = {...styleBtnText};
    if(sessionInfo.language === 'it'){
        styleBtnTextFix.fontSize = '0.9em';
    }
    
    const [page,setPage] = useState<number>(1)

    //ここからスワイプハンドリング
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const minSwipeDistance = swipeBorderMove
    const onTouchStart = (e:any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e:any) => setTouchEnd(e.targetTouches[0].clientX)
    const onTouchEnd = (e:any) => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if(isLeftSwipe){
            if(!sidesVisible.left && !sidesVisible.right){
                if(sessionInfo.isLefty){
                    setSidesVisible((prev)=>({...prev,...{left:true}}));
                }else{
                    setSidesVisible((prev)=>({...prev,...{right:true}}));
                }
            }
            if(sidesVisible.left && !sidesVisible.right && !sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{left:false}}));
            }
            if(sidesVisible.right && !sidesVisible.left && sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{right:false}}));
            }
        }
        if(isRightSwipe){
            if(!sidesVisible.left && !sidesVisible.right){
                if(sessionInfo.isLefty){
                    setSidesVisible((prev)=>({...prev,...{right:true}}));
                }else{
                    setSidesVisible((prev)=>({...prev,...{left:true}}));
                }
            }
            if(sidesVisible.right && !sidesVisible.left && !sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{right:false}}));
            }
            if(sidesVisible.left && !sidesVisible.right && sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{left:false}}));
            }
        }
    }
    //ここまでスワイプハンドリング

    //わざわざスワイプしないでもタップで済むよう対応
    const handleClick = (e:any) => {
        if(sidesVisible.left && !sidesVisible.right && !sessionInfo.isLefty){
            setSidesVisible((prev)=>({...prev,...{left:false}}));
        }
        if(sidesVisible.left && !sidesVisible.right && sessionInfo.isLefty){
            setSidesVisible((prev)=>({...prev,...{left:false}}));
        }
        if(sidesVisible.right && !sidesVisible.left && !sessionInfo.isLefty){
            setSidesVisible((prev)=>({...prev,...{right:false}}));
        }
        if(sidesVisible.right && !sidesVisible.left && sessionInfo.isLefty){
            setSidesVisible((prev)=>({...prev,...{right:false}}));
        }
    }

    const handleUndo = (event:React.MouseEvent<HTMLButtonElement>) =>{
        if(pointComp.historyUndo.length > 0){
            const lastOne = pointComp.historyUndo[pointComp.historyUndo.length - 1];
            if(lastOne.type === 'point'){
                setPointComp((prev) => ({
                    routeInfo:prev.routeInfo,
                    points:prev.points.filter((one) => (one.counter !== lastOne.counter)),
                    historyUndo:prev.historyUndo.slice(0,-1),
                    historyRedo:[...prev.historyRedo,{...lastOne,...{type:'point'}}]
                }));
            }else if(lastOne.type === 'clear'){
                setPointComp((prev) => ({
                    routeInfo:prev.routeInfo,
                    points:lastOne.points,
                    historyUndo:prev.historyUndo.slice(0,-1),
                    historyRedo:[...prev.historyRedo,{...lastOne,...{type:'clear'}}]
                }));
            }
        }
    }
    const handleRedo = (event:React.MouseEvent<HTMLButtonElement>) =>{
        if(pointComp.historyRedo.length > 0){
            let lastOne = pointComp.historyRedo[pointComp.historyRedo.length - 1];
            if(lastOne.type === 'point'){
                delete lastOne.type;
                setPointComp((prev) => ({
                    routeInfo:prev.routeInfo,
                    points:[...prev.points,lastOne],
                    historyUndo:[...prev.historyUndo,{...lastOne,...{type:'point'}}],
                    historyRedo:prev.historyRedo.slice(0,-1)
                }));
            }else if(lastOne.type === 'clear'){
                setPointComp((prev) => ({
                    routeInfo:prev.routeInfo,
                    points:[],
                    historyUndo:[...prev.historyUndo,lastOne],
                    historyRedo:prev.historyRedo.slice(0,-1)
                }));
            }
        }
    }
    const handleBound = () => {
        setBoundTrigger((prev)=>(!prev));
    }
    const handleDefaultLocation = () => {
        if(map){
            map.setView([
                sessionInfo.defaultLocation.lat,
                sessionInfo.defaultLocation.lng
            ])
        }
    }
    const handleMenuLeft = () => {
        if(sessionInfo.isLefty){
            setSidesVisible((prev)=>({...prev,...{right:true}}));
        }else{
            setSidesVisible((prev)=>({...prev,...{left:true}}));
        }
    }
    const handleMenuRight = () => {
        if(sessionInfo.isLefty){
            setSidesVisible((prev)=>({...prev,...{left:true}}));
        }else{
            setSidesVisible((prev)=>({...prev,...{right:true}}));
        }
    }
    React.useEffect(()=>{
        setResizeTrigger((prev)=>!prev);
        // eslint-disable-next-line
    },[bottomOpen])
    return (
        <React.Fragment>
            <Box display="flex" flexDirection="column" width="100%" height={bottomOpen?outerHeight.open:outerHeight.close} minHeight={bottomOpen?outerHeight.open:outerHeight.close} sx={{position:'relative',padding:0,backgroundColor:'#dedede',borderTop:'1px solid #9f9f9f',zIndex:800}}
            onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} onClick={handleClick}>
                <React.Fragment>
                    <Box sx={stylePagerOuter} display="flex" justifyContent="center" alignItems="center">
                        <Box onClick={handleMenuLeft} sx={{...styleDrawerButton,...{left:'0px'}}}>
                            <MenuIcon sx={{position:'relative',top:'7px',fontSize:'1.9em'}} />
                        </Box>
                        {!bottomOpen &&
                            <React.Fragment>
                                <UpArrow setBottomOpen={setBottomOpen} outerHeight={outerHeight.close} />
                            </React.Fragment>
                        }
                        {bottomOpen &&
                            <React.Fragment>
                                <Box onClick={()=>{setPage(1)}} sx={(page===1)?stylePagerActive:stylePagerInactive}>
                                    <TurnSharpRightRoundedIcon sx={styleIconInPager} />
                                </Box>
                                <Box onClick={()=>{setPage(99)}} sx={(page===99)?stylePagerActive:stylePagerInactive}>
                                    <RemoveRedEyeRoundedIcon sx={styleIconInPager} />
                                </Box>
                            </React.Fragment>
                        }
                        <Box onClick={handleMenuRight} sx={{...styleDrawerButton,...{right:'0px'}}}>
                            <MenuIcon sx={{position:'relative',top:'7px',fontSize:'1.9em'}} />
                        </Box>
                    </Box>
                    {bottomOpen &&
                        <Box sx={{flex:1}}>
                            {(page===1) &&
                                <Grid className="no-select" container spacing={0} columns={{xs:12}} sx={{paddingTop:'12px',height:'100%'}}>
                                    <Grid item xs={12} alignItems="center" textAlign="center" sx={{paddingTop:'3px'}}>
                                        <Box sx={{display:'inline-block',verticalAlign:'top'}}>
                                            <AutocueCheck bottomMode={true} GoogleFonts={GoogleFonts} routeOpt={routeOpt} setRouteOpt={setRouteOpt} t={t} />
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top',width:'28px'}}>
                                            {/* spacer */}
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top'}}>
                                            <ModeRadio GoogleFonts={GoogleFonts} routeOpt={routeOpt} setRouteOpt={setRouteOpt} t={t}/>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" textAlign="center" sx={{paddingBottom:'6px'}}>
                                        <Box sx={{display:'inline-block',verticalAlign:'top'}}>
                                            <ThemeProvider theme={themeBtnAll}>
                                                <ButtonGroup variant="outlined">
                                                    <Button sx={{width:'64px',height:'48px'}} fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleZoomIn}><AddIcon /></Button>
                                                    <Button sx={{width:'64px',height:'48px'}} fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleZoomOut}><RemoveIcon /></Button>
                                                </ButtonGroup>
                                            </ThemeProvider>
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top',width:'12px'}}>
                                            {/* spacer */}
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top'}}>
                                            <ThemeProvider theme={themeBtnAll}>
                                                <ButtonGroup variant="outlined">
                                                    <Button sx={{width:'115px',height:'48px'}} fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleUndo}><span style={styleBtnText}>{t("routeEditor.btnUndo")}</span></Button>
                                                    <Button sx={{width:'115px',height:'48px'}} fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleRedo}><span style={styleBtnText}>{t("routeEditor.btnRedo")}</span></Button>
                                                </ButtonGroup>
                                            </ThemeProvider>
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                            {(page===99) &&
                                <Grid container spacing={0} columns={{xs:12}} sx={{paddingTop:'12px',height:'100%'}}>
                                    <Grid item xs={12} alignItems="center" textAlign="center" sx={{paddingTop:'3px'}}>
                                        <Box sx={{display:'inline-block',verticalAlign:'top'}}>
                                            <ThemeProvider theme={themeBtnAll}>
                                                <Button sx={{width:'230px',height:'48px'}} fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleBound}>
                                                    <span style={styleBtnTextFix}>{t("routeEditor.btnBound")}</span>
                                                </Button>
                                            </ThemeProvider>
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top',width:'12px'}}>
                                            {/* spacer */}
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top',width:'130px'}}>
                                            <div style={{width:'100%',fontSize:'1em',textAlign:'left'}}>
                                                <span className={GoogleFonts.className} style={styleBtnText}>{t("routeEditor.totalDistance")}</span>
                                            </div>
                                            <div style={{width:'100%',fontSize:'1em',textAlign:'right'}}>
                                                {(sessionInfo.unit==='km')&&
                                                    <span className={GoogleFonts.className} style={styleBtnText}>{` ${Math.round((optionalInfo.distance/1000)*10)/10} km`}</span>
                                                }
                                                {(sessionInfo.unit==='mile')&&
                                                    <span className={GoogleFonts.className} style={styleBtnText}>{` ${Math.round(((optionalInfo.distance*0.621371192)/1000)*10)/10} mile`}</span>
                                                }
                                            </div>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} alignItems="center" textAlign="center" sx={{paddingBottom:'6px'}}>
                                    <Box sx={{display:'inline-block',verticalAlign:'top'}}>
                                            <ThemeProvider theme={themeBtnAll}>
                                                <Button sx={{width:'230px',height:'48px'}} fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleDefaultLocation}>
                                                    <span style={styleBtnTextFix}>{t("routeEditor.btnDefaultLocation")}</span>
                                                </Button>
                                            </ThemeProvider>
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top',width:'12px'}}>
                                            {/* spacer */}
                                        </Box>
                                        <Box sx={{display:'inline-block',verticalAlign:'top',width:'130px'}}>
                                            {/* spacer */}
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </Box>
                    }
                    {bottomOpen && <DownArrow setBottomOpen={setBottomOpen} /> }
                </React.Fragment>
            </Box>
            {(sidesVisible.left || sidesVisible.right) && <EventRejector sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} height={bottomOpen?outerHeight.open:outerHeight.close} />}
        </React.Fragment>
    )
}

const styleDownArrow = {
    width:'60px',
    height:'40px',
    position:'absolute',
    left:'8px',
    bottom:`${outerHeight.open}px`,
    backgroundColor:'#dedede',
    borderRadius:'8px 8px 0px 0px',
    borderStyle:'solid',borderColor:'#9f9f9f',
    borderWidth:'1px 1px 0px 1px'
};

function DownArrow({setBottomOpen}:{setBottomOpen:React.Dispatch<React.SetStateAction<boolean>>}){
    const handleClick = () => {
        setBottomOpen(false)
    }
    return (
        <Box sx={styleDownArrow} onClick={handleClick}>
            <span style={{position:'relative',top:'6px',left:'5px'}}>
                <UpDownArrows mode="down" />
            </span>
        </Box>
    )
}

function UpArrow({setBottomOpen,outerHeight}:{
    setBottomOpen:React.Dispatch<React.SetStateAction<boolean>>,
    outerHeight:number
}){
    const handleClick = () => {
        setBottomOpen(true)
    }
    return (
        <Button sx={{position:'relative',top:'-1px',backgroundColor:'#dedede',borderColor:'#9f9f9f',width:'120px',height:`${(outerHeight - 8)}px`,padding:'0px','&:hover':{backgroundColor:'#dedede'}}} variant="outlined" onClick={handleClick}>
            <span style={{position:'relative',top:'-1px',left:'-4px'}}>
                <UpDownArrows mode="up" />
            </span>
        </Button>
    )
}

export function UpDownArrows({mode}:{mode:string}){
    return (
        <svg width="40" height="40" viewBox="-15 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10,20 L0,10 L0,30 Z" fill="#676767" transform={(mode==='down')?"rotate(90, 10, 20)":"rotate(-90, 10, 20)"}/>
        </svg>
    )
}

export function EventRejector({sidesVisible,setSidesVisible,height}:{
    sidesVisible:typeSidesVisible,
    setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>,
    height:number
}){
    //ここからスワイプハンドリング
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)
    const minSwipeDistance = swipeBorderMove
    const onTouchStart = (e:any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e:any) => setTouchEnd(e.targetTouches[0].clientX)
    const onTouchEnd = (e:any) => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if(isLeftSwipe){
            if(!sidesVisible.left && !sidesVisible.right){
                setSidesVisible((prev)=>({...prev,...{right:true}}));
            }
            if(sidesVisible.left && !sidesVisible.right){
                setSidesVisible((prev)=>({...prev,...{left:false}}));
            }
        }
        if(isRightSwipe){
            if(!sidesVisible.left && !sidesVisible.right){
                setSidesVisible((prev)=>({...prev,...{left:true}}));
            }
            if(sidesVisible.right && !sidesVisible.left){
                setSidesVisible((prev)=>({...prev,...{right:false}}));
            }
        }
    }
    //ここまでスワイプハンドリング

    //わざわざスワイプしないでもタップで済むよう対応
    const handleClick = (e:any) => {
        e.stopPropagation();
        if(sidesVisible.left && !sidesVisible.right){
            setSidesVisible((prev)=>({...prev,...{left:false}}));
        }
        if(sidesVisible.right && !sidesVisible.left){
            setSidesVisible((prev)=>({...prev,...{right:false}}));
        }
    }

    return (
        <Box sx={{backgroundColor:'transparent',zIndex:801,width:'100%',height:`${height}px`,position:'fixed',bottom:'0px'}}
        onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} onClick={handleClick}>
            {/* event rejector */}
        </Box>
    )
}