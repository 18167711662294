//< OSRM Server settings >

//Server information
type typeOSRM = {
    protocol:string
    host:string
    service:string
    version:string
    profile:string
}
const OSRM:typeOSRM = {
    protocol:process.env.REACT_APP_OSRM_PROTOCOL as string,
    host:process.env.REACT_APP_OSRM_HOST as string,
    service:process.env.REACT_APP_OSRM_SERVICE as string,
    version:process.env.REACT_APP_OSRM_VERSION as string,
    profile:process.env.REACT_APP_OSRM_PROFILE as string,
}

type typeLogin = {
    lon1?:number|string,
    lat1?:number|string,
    lon2?:number|string,
    lat2?:number|string,
    onThen?:(content?:any) => void,
    onCatch?:(err?:any) => void,
    onFinally?:() => void,
};

//OSRM class
export default class osrmIO {

    public osrm:typeOSRM;

    constructor(){
        this.osrm = OSRM;
    }

    //Get API URL /{service}/{version}/{profile}/{lon1},{lat1};{lon2},{lat2}
    getUrl(lon1:number|string,lat1:number|string,lon2:number|string,lat2:number|string):string{
        return `${OSRM.protocol}://${this.osrm.host}/${this.osrm.service}/${this.osrm.version}/${this.osrm.profile}/${lon1},${lat1};${lon2},${lat2}?geometries=geojson&alternatives=true&steps=true&generate_hints=false`
    }

    //Request
    request({lon1='',lat1='',lon2='',lat2='',onThen=()=>{},onCatch=()=>{},onFinally=()=>{}}:typeLogin){
        let req = this.getUrl(lon1,lat1,lon2,lat2);
        fetch(
            req,{mode:'cors'}
        ).then(res => {
            // console.log('HTTP CODE : ' + res.status);
            return res.json();
        }).then(content => {
            onThen(content)
        }).catch(err => {
            onCatch(err)
        }).finally(() => {
            onFinally()
        });    
    }
    
}
