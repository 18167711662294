import { Box, Button, SwipeableDrawer } from '@mui/material';
import React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { useKey } from 'react-use';

import { Theme, ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import L, { LatLng, Map as LeafletMap } from "leaflet";
import { MapContainer, Marker, MarkerProps, Polyline, Popup, ZoomControl, useMap, useMapEvents } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import '../../System/LeafletMarkers.css';
import '../../System/LeafletPopup.css';
import { SessionContext, baseUri, sessionIO, syncSession } from '../../System/Session';
import CycrouteTiles, { CycrouteTilePulldown } from '../Map/Tiles';
import './RouteEditor.css';

import iconCircle16 from '../Map/MapIcon/circle_16x16.png';
// import iconCircle20 from '../Map/MapIcon/circle_20x20.png';
// import iconCircle24 from '../Map/MapIcon/circle_24x24.png';

import { RouteContext, RouteDataStorageLoad, RouteDataStorageSave, optionalInfoDefault, pointCompBlabk, typeEditDialog, typeHoverMarker, typeOptionalInfo, typePointComp, typePopuoOpen } from '../../System/RouteData';
import { VersionAlert } from '../../System/Version';
import windowDimensions from '../../System/WindowDimensions';
import AdsArea, { adsHeight } from '../Ads';
import { outerGridWidth } from '../Map/Casts';
import { CueSheetAdjustButton, MapButtonLeft, MapButtonRight, sideArrowsTopDefault, typeSidesVisible } from '../Map/SideArrows';
import RouteEditorColumnLeft from './RouteEditorColumnLeft';
import RouteEditorColumnRight from './RouteEditorColumnRight';
import RouteEditorContextMenu, { MobileContextMenuVisibleDetection, RouteEditorContextMenuMobile } from './RouteEditorContextmenu';
import RouteEditorEditDialog from './RouteEditorEditDialog';
import RouteEditorRowBottom from './RouteEditorRowBottom';
import { RouteEditorRedo, RouteEditorUndo } from './RouteEditorUndoRedo';

import { getLanguageObj } from '../../System/Locales';
import osrmIO from '../../System/OSRM';
import { setTitle } from '../../System/Title';
import { polyLineStyle } from '../Map/Casts';
import { customMarkerIcon, detectIsTurn, modifierList } from '../Map/CustomMarkers';

import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import '../Map/fontawesome.css';

export type typeRouteOpt = {
    use:string,
    autocue:boolean,
}

export default function RouteEditor(){
    document.body.classList.remove('cycroute-mobile');
    document.body.classList.remove('cycroute-pc');
    //背景切り替え
    // if(isMobile || isTablet){
    //     document.body.classList.add('cycroute-mobile');
    //     document.body.classList.remove('cycroute-pc');
    // }else{
    //     document.body.classList.add('cycroute-pc');
    //     document.body.classList.remove('cycroute-mobile');
    // }
    const {t} = useTranslation();
    setTitle(t("top.btnRouteNew"));
    const navigate = useNavigate();
    const {sessionInfo,setSessionInfo} = React.useContext(SessionContext);
    const GoogleFontsEn = getLanguageObj('en');
    const [sidesVisible,setSidesVisible] = React.useState<typeSidesVisible>({left:false,right:false});
    const [pointComp,setPointComp] = React.useState<typePointComp>(pointCompBlabk);
    const [polylineArr,setPolylineArr] = React.useState<any[]>([]);
    const [optionalInfo,setOptionalInfo] = React.useState<typeOptionalInfo>(optionalInfoDefault);
    const [popuoOpen,setPopuOpen] = React.useState<typePopuoOpen>({open:false,target:'',trigger:false});
    const [popupList,setPopupList] = React.useState<string[]>([]);
    const popupRef = React.useRef<string[]>([])
    const [hoverMarker,setHoverMarker] = React.useState<typeHoverMarker>({
        isMarkerHover:false,isPolylineHover:false,visible:false,lat:0,lng:0
    });

    const [cueWidthMode,setCueWidthMode] = React.useState<string>("");
    const [contextMenu,setContextMenu] = React.useState<any>({
        open:false,hover:false,mouseX:0,mouseY:0,data:null,native:null});
    const [editDialog,setEditDialog] = React.useState<typeEditDialog>({
        open:false,preopen:false,isNew:false,lat:0,lng:0,parentIdx:-1,childIdx:-1,idx:-1,native:{lat:0,lng:0}
    });
    const [markerToggle,setMarkerToggle] = React.useState<boolean>(true);
    // const [version,setVersion] = React.useState<typeVersionObj>({
    //     local:localVersion,
    //     remote:localVersion,
    //     upgradable:false,
    //     alert:false
    // });
    const [escTrigger,setEscTrigger] = React.useState<boolean>(false);
    const isFirstLoad = React.useRef(false);
    const isFirstLoad2nd = React.useRef(false);

    //UP key is 38, DOWN key is 40
    const upKey = (event:any) => ((event.keyCode === 38)||(event.keyCode === 40));
    const upKeyUpHandler = (e:any) => {
        if(e.keyCode === 38){
            // console.log('UP')
            if(popuoOpen.open){
                const idx = popupRef.current.indexOf(popuoOpen.target)
                const newPos = idx - 1;
                const max = popupRef.current.length - 1;
                if((newPos >= 0) && (idx <= max)){
                    const newTarget = popupRef.current[newPos]
                    setPopuOpen((prev)=>({...prev,...{target:newTarget,trigger:true}}))
                }
            }
        }
        if(e.keyCode === 40){
            // console.log('DOWN')
            if(popuoOpen.open){
                const idx = popupRef.current.indexOf(popuoOpen.target)
                const newPos = idx + 1;
                const max = popupRef.current.length - 1;
                if((newPos >= 0) && (idx <= max)){
                    const newTarget = popupRef.current[newPos]
                    setPopuOpen((prev)=>({...prev,...{target:newTarget,trigger:true}}))
                }
            }
        }
        // console.log('key event',e)
    }
    useKey(upKey,upKeyUpHandler,{event:'keyup'});

    //Undo shortut key for CTRL + Z
    const undoKey = (event:any) => ((event.ctrlKey && event.key === 'z')||(event.ctrlKey && event.key === 'Z'));
    const undoKeyUpHandler = () => {
        RouteEditorUndo(pointComp,setPointComp);
    }
    useKey(undoKey,undoKeyUpHandler,{event:'keyup'});

    //Redo shortut key for CTRL + Y
    const redoKey = (event:any) => ((event.ctrlKey && event.key === 'y')||(event.ctrlKey && event.key === 'Y'));
    const redoKeyUpHandler = () => {
        RouteEditorRedo(pointComp,setPointComp);
    }
    useKey(redoKey,redoKeyUpHandler,{event:'keyup'});

    //Exit shortut key for ESC
    const escKey = (event:any) => (event.key === 'Escape');
    const escKeyUpHandler = () => {
        setEscTrigger((prev)=>(!prev))
    }
    useKey(escKey,escKeyUpHandler,{event:'keyup'});

    const findInPoint = (IN_LAT:number,IN_LNG:number) => {
        let index = {
            parent:-1,
            child:-1,
            input:{
                lat:IN_LAT,
                lng:IN_LNG
            }
        }
        pointComp.points.forEach((one,parentIdx)=>{
            if(parentIdx > 0){
                let childIdx = one.fromLast.findIndex((two)=>{
                    return (two[0] === IN_LAT && two[1] === IN_LNG)
                });
                if(childIdx >= 0){
                    index.parent = parentIdx;
                    index.child = childIdx;
                }
            }
        });
        // console.log('index',index);
        return {parent:index.parent,child:index.child}
    }

    React.useEffect(() => {
        if(!editDialog.open && editDialog.preopen){
            //ここで pointComp 内の要素を特定
            const detect = findInPoint(editDialog.lat,editDialog.lng);
            if((detect.parent >= 0)&&(detect.child >= 0)){
                setEditDialog((prev)=>({...prev,...{open:true,preopen:false,parentIdx:detect.parent,childIdx:detect.child}}));

            }
        }else if(editDialog.open && !editDialog.preopen){
            // console.log('editDialog',editDialog);
        }
        // eslint-disable-next-line
    },[editDialog.open,editDialog.preopen]);

    const sessionUpdate = () => {
        let mySession = new sessionIO();
        mySession.check({
            update:true,
            onThen:((content)=>{
                // console.log("sessionUpdate",content);
                if(content.has_session){
                    syncSession(content,setSessionInfo);
                }else{
                    setSessionInfo((prev)=>({...prev,...{hasSession:false,loaded:true}}));
                    navigate('/')
                }
            }),
            onCatch:((err)=>{
                // console.log('here is "catch"');
            }),
            onFinally:(()=>{
                // console.log('here is "finally"');
            }),
        })
    }
    React.useEffect(() => {
        if(!isFirstLoad.current){
            isFirstLoad.current = true;
            RouteDataStorageLoad(setPointComp);
            sessionUpdate();
        }
        // eslint-disable-next-line 
    },[]);
    React.useEffect(() => {
        if(!isFirstLoad2nd.current){
            isFirstLoad2nd.current = true;
        }else{
            RouteDataStorageSave(pointComp);
        }
    },[pointComp]);
    return (
        <RouteContext.Provider value={{
            pointComp,setPointComp,
            optionalInfo,setOptionalInfo,
            polylineArr,setPolylineArr,
            popuoOpen,setPopuoOpen: setPopuOpen,
            contextMenu,setContextMenu,
            cueWidthMode,setCueWidthMode,
            popupList,setPopupList,popupRef,
            editDialog,setEditDialog,
            hoverMarker,setHoverMarker,
            escTrigger,setEscTrigger,
            markerToggle,setMarkerToggle
        }}>
            <Box height='100%' display='flex' flexDirection="column" alignItems='stretch'>
                {!sessionInfo.noAds && <AdsArea /> }
                <Box width="100vw" display="flex" flexGrow={1} flexDirection="row" alignItems='stretch' sx={{position:'relative'}}>
                    {(process.env.NODE_ENV === 'development') && <MapArea sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} />}
                    {(process.env.NODE_ENV === 'production') && <MapArea sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} />}
                    {/* {(process.env.NODE_ENV === 'production') && "Route Editor Area"} */}
                </Box>
                <VersionAlert className={GoogleFontsEn.className} />
            </Box>
        </RouteContext.Provider>
    )
}

export function MapComponent(
    {boundTrigger,isEdited}:
    {boundTrigger:boolean,isEdited:boolean}){
    const {polylineArr} = React.useContext(RouteContext);
    const map = useMap();
    const isFirstLoad = React.useRef(false);
    const lastTriggerValue = React.useRef(false);

    React.useEffect(() => {
        //初回用
        if(!isFirstLoad.current && !isEdited){
            if(!map || !polylineArr.length){
                return
            }else{
                const group = L.featureGroup();
                group.addLayer(L.polyline(polylineArr.map((one) => [one[0],one[1]])));
                map.fitBounds(group.getBounds());
                isFirstLoad.current = true;
                return
            }
        }
        //手動実行用
        if(boundTrigger !== lastTriggerValue.current){
            if(!map || !polylineArr.length){
                lastTriggerValue.current = boundTrigger;
                return
            }else{
                const group = L.featureGroup();
                group.addLayer(L.polyline(polylineArr.map((one) => [one[0],one[1]])));
                map.fitBounds(group.getBounds());
                lastTriggerValue.current = boundTrigger;
                return
            }
        }
    },[map,polylineArr,isEdited,boundTrigger]);
    return null;
};

function MapRefGetter({setMap}:{setMap:React.Dispatch<React.SetStateAction<L.Map|null>>}){
    const map = useMap();
    React.useEffect(() => {
        setMap(map);
    }, [map,setMap]);
    return null;
}

//仮想Marker用の0pxの透明アイコン ( Marker無しのPopupを作ると他のPopupが正常に動作しないため )
const transparentIcon = new L.Icon({
    iconUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    iconRetinaUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
    iconAnchor: undefined,
    shadowUrl: undefined,
    shadowSize: undefined,
    shadowAnchor: undefined,
    iconSize: new L.Point(0, 0), // アイコンサイズを0pxに設定
    className: 'leaflet-div-icon'
});

export type typeMobileContext = {
    trigger:boolean;
    open:boolean;
    lat:number;
    lng:number;
    distance:number;
    zoom:number;
    idx:number;
}

const theme = createTheme();

function MapArea(
    {sidesVisible,setSidesVisible}:
    {
        sidesVisible:typeSidesVisible,
        setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>
    }
){
    const {sessionInfo} = React.useContext(SessionContext);
    const {setPopuoOpen,polylineArr} = React.useContext(RouteContext);
    const [mapRef,setMapRef] = React.useState<LeafletMap|null>(null);
    const parentRef = React.useRef<HTMLInputElement>(null);
    // const {width,height,modeBorder} = windowDimensions();
    const {width,modeBorder} = windowDimensions();
    const position = new LatLng(sessionInfo.defaultLocation.lat,sessionInfo.defaultLocation.lng);
    const [sideArrowsTop,setSideArrowsTop] = React.useState<number>(sideArrowsTopDefault);
    const [routeOpt,setRouteOpt] = React.useState<typeRouteOpt>({use:"bicycle",autocue:true});
    const [boundTrigger,setBoundTrigger] = React.useState<boolean>(false);
    const [isEdited,setIsEdited] = React.useState<boolean>(false); //少しでも座標追加したら true
    const [resizeTrigger,setResizeTrigger] = React.useState<boolean>(false);
    React.useLayoutEffect(() => {
        if(isMobile || isTablet){
            if(parentRef.current?.offsetHeight){
                let top = parentRef.current?.offsetHeight - 104;
                setSideArrowsTop(top);
            }
        }
    },[]);
    const handleZoomIn = () => {
        if (mapRef) mapRef.zoomIn();
    };
    const handleZoomOut = () => {
        if (mapRef) mapRef.zoomOut();
    };
    const handlePopupClose = (e: L.LeafletEvent) => {
        setPopuoOpen({open:false,target:"",trigger:false})
    }

    const [mobileContext,setMobileContext] = React.useState<typeMobileContext>({
        trigger:false,open:false,lat:0,lng:0,distance:0,zoom:0,idx:-1
    });
    const mobileContextRef = React.useRef<L.Marker|null>(null);
    React.useEffect(()=>{
        if (mobileContextRef.current && mapRef) {
            if(mobileContext.open){
                // mapRef.setView([mobileContext.lat,mobileContext.lng]);
                mobileContextRef.current.openPopup();
            }else{
                if(mobileContextRef.current.isPopupOpen()){
                    mobileContextRef.current.closePopup();
                }
            }
        }
    },[mobileContext,mapRef])

    React.useEffect(()=>{
        if(mapRef && (polylineArr !== null) && (polylineArr !== undefined)){
            if(polylineArr.length === 0){
                // console.log('center');
                mapRef.setView([
                    sessionInfo.defaultLocation.lat,
                    sessionInfo.defaultLocation.lng
                ])
            }
        }
        // eslint-disable-next-line
    },[sessionInfo.defaultLocation]);
    React.useEffect(()=>{
        if(mapRef){
            mapRef.invalidateSize();
        }
        // eslint-disable-next-line
    },[resizeTrigger,sidesVisible,sessionInfo.isLefty])
    return (
        <React.Fragment>
            {!(isMobile || isTablet) && 
                <React.Fragment>
                    {(!sessionInfo.isLefty && (sidesVisible.left || (width>modeBorder))) && <RouteEditorColumnLeft setSidesVisible={setSidesVisible} routeOpt={routeOpt} setRouteOpt={setRouteOpt} isLefty={sessionInfo.isLefty} setBoundTrigger={setBoundTrigger} />}
                    {(sessionInfo.isLefty && (sidesVisible.right || (width>modeBorder))) && <RouteEditorColumnRight sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} isLefty={sessionInfo.isLefty} setBoundTrigger={setBoundTrigger} map={mapRef} />}
                </React.Fragment>
            }
            <Box height='100%' display='flex' flexGrow={1} flexDirection="column" alignItems='stretch'>
                <div ref={parentRef} style={{height: '100%', display: 'flex', flexGrow: 1, alignItems: 'stretch'}}>
                {(width<=modeBorder) &&
                    <React.Fragment>
                        {!(isMobile || isTablet) &&
                            <React.Fragment>
                                <MapButtonLeft sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} width={width} top={sideArrowsTop} />
                                <MapButtonRight sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} width={width} top={sideArrowsTop} />
                            </React.Fragment>
                        }
                        {(sidesVisible.right && (isMobile || isTablet) && false) &&
                            <CueSheetAdjustButton sidesVisible={sidesVisible} />
                        }
                    </React.Fragment>
                }
                <MapContainer center={position} zoom={13} zoomControl={false} scrollWheelZoom={true} style={{zIndex:500,cursor:'pointer',width:'100%',height:'100%'}} attributionControl={false}>
                    <CycrouteTilePulldown />
                    <CycrouteTiles map={mapRef} />
                    <ThemeProvider theme={theme}>
                        <MarkersAndPolyline routeOpt={routeOpt} setIsEdited={setIsEdited} mapRef={mapRef} mobileContext={mobileContext} setMobileContext={setMobileContext} mobileContextRef={mobileContextRef} />
                    </ThemeProvider>
                    {!(isMobile || isTablet) &&
                        <ZoomControl position={(sessionInfo.isLefty?"bottomright":"bottomleft")} />
                    }
                    <MapComponent boundTrigger={boundTrigger} isEdited={isEdited} />
                    <MapRefGetter setMap={setMapRef} />
                    <RouteEditorContextMenu />
                    {mapRef && <MapEvents map={mapRef} onPopupClose={handlePopupClose} />}
                    <Marker ref={mobileContextRef} position={[mobileContext.lat,mobileContext.lng]} icon={transparentIcon}>
                        <RouteEditorContextMenuMobile mobileContext={mobileContext} setMobileContext={setMobileContext} map={mapRef} />
                    </Marker>
                </MapContainer>
                </div>
                {/* </Box> */}
                {(isMobile || isTablet) && 
                    <RouteEditorRowBottom sidesVisible={sidesVisible} routeOpt={routeOpt} setRouteOpt={setRouteOpt} handleZoomIn={handleZoomIn} handleZoomOut={handleZoomOut} setBoundTrigger={setBoundTrigger} setSidesVisible={setSidesVisible} map={mapRef} setResizeTrigger={setResizeTrigger} />
                }
                <RouteEditorEditDialog />
            </Box>
            {!(isMobile || isTablet) && 
                <React.Fragment>
                    {(!sessionInfo.isLefty && (sidesVisible.right || (width>modeBorder))) && <RouteEditorColumnRight sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} isLefty={sessionInfo.isLefty} setBoundTrigger={setBoundTrigger} map={mapRef} />}
                    {(sessionInfo.isLefty && (sidesVisible.left || (width>modeBorder))) && <RouteEditorColumnLeft setSidesVisible={setSidesVisible} routeOpt={routeOpt} setRouteOpt={setRouteOpt} isLefty={sessionInfo.isLefty} setBoundTrigger={setBoundTrigger} />}
                </React.Fragment>
            }
            {(isMobile || isTablet) && 
                <React.Fragment>
                    <SwipeableDrawer anchor={sessionInfo.isLefty?"right":"left"} open={sidesVisible.left} onClose={()=>{
                        setSidesVisible((prev)=>({...prev,...{left:false}}))
                    }} onOpen={()=>{
                        setSidesVisible((prev)=>({...prev,...{left:true}}))
                    }}
                    ModalProps={{BackdropProps:{style:{backgroundColor:'transparent',pointerEvents:'none'}}}}
                    PaperProps={{sx:{pointerEvents:'auto'}}} sx={{pointerEvents:'none'}}>
                        {/* <Box sx={{width:outerGridWidth.left}}> */}
                            <RouteEditorColumnLeft setSidesVisible={setSidesVisible} routeOpt={routeOpt} setRouteOpt={setRouteOpt} isLefty={sessionInfo.isLefty} setBoundTrigger={setBoundTrigger} />
                        {/* </Box> */}
                    </SwipeableDrawer>
                    <SwipeableDrawer anchor={sessionInfo.isLefty?"left":"right"} open={sidesVisible.right} onClose={()=>{
                        setSidesVisible((prev)=>({...prev,...{right:false}}))
                    }} onOpen={()=>{
                        setSidesVisible((prev)=>({...prev,...{right:true}}))
                    }}
                    ModalProps={{BackdropProps:{style:{backgroundColor:'transparent',pointerEvents:'none'}}}}
                    PaperProps={{sx:{pointerEvents:'auto'}}} sx={{pointerEvents:'none'}}>
                        {/* <Box sx={{width:outerGridWidth.right}}> */}
                            <RouteEditorColumnRight sidesVisible={sidesVisible} setSidesVisible={setSidesVisible} isLefty={sessionInfo.isLefty} setBoundTrigger={setBoundTrigger} map={mapRef} />
                        {/* </Box> */}
                    </SwipeableDrawer>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const MapEvents: React.FC<{ map: L.Map; onPopupClose: (e: L.LeafletEvent) => void; }> = ({ map, onPopupClose }) => {
    React.useEffect(() => {
        map.on('popupclose', onPopupClose);
        return () => {
            map.off('popupclose', onPopupClose);
        };
    }, [map, onPopupClose]);
    return null;
};

export const hoverMkr = L.icon({
    iconUrl: iconCircle16,
    iconSize:[16,16],
    iconAnchor:[8,8],
    shadowUrl:undefined,
    className:undefined
});

function MarkersAndPolyline(
    {routeOpt,setIsEdited,mapRef,mobileContext,setMobileContext,mobileContextRef}:
    {
        routeOpt:typeRouteOpt,
        setIsEdited:React.Dispatch<React.SetStateAction<boolean>>,
        mapRef:L.Map|null,
        mobileContext:typeMobileContext,
        setMobileContext:React.Dispatch<React.SetStateAction<typeMobileContext>>,
        mobileContextRef:React.MutableRefObject<L.Marker<any> | null>
}){
    const {sessionInfo,setBackdrop} = React.useContext(SessionContext);
    const {pointComp,setPointComp,setOptionalInfo,polylineArr,setPolylineArr,setContextMenu} = React.useContext(RouteContext);

    //ダブルクリック有効化 ( 設定画面で変更可能 )
    const enableDoubleClick = !sessionInfo.disableDoubleClick;

    const delay = 200; //delay for detecting double click
    let clicks = 0;
    let timer: NodeJS.Timeout | null = null;

    // const map = useMapEvents({
    useMapEvents({
        contextmenu:(e:L.LeafletMouseEvent)=>{
            // console.log('contextmenu',e);
            if(isMobile || isTablet){
                //別ロジックで判別 ( ロングタップ )
                const res = {
                    target:findNearestPoint(e.latlng.lat,e.latlng.lng,polylineArr),
                    zoom:mapRef?.getZoom()
                }
                if (mobileContextRef.current && mapRef && (res.zoom !== undefined)) {
                    if(!mobileContextRef.current.isPopupOpen()){
                        // console.log('LONG TAP ('+res.target.distance+')',res);
                        const mobileContextMenuVisible = MobileContextMenuVisibleDetection(res.target.distance,res.zoom);
                        if(mobileContextMenuVisible){
                            //ルート上 ( 直線対策未実装なので注意 )
                            setMobileContext((prev)=>({...prev,...{
                                trigger:!prev.trigger,
                                open:true,
                                lat:res.target.lat,lng:res.target.lng,
                                distance:res.target.distance,
                                zoom:(res.zoom===undefined?0:res.zoom),
                                idx:res.target.idx
                            }}));
                        }else{
                            //ルート外
                            setMobileContext((prev)=>({...prev,...{
                                trigger:!prev.trigger,
                                open:true,
                                lat:e.latlng.lat,lng:e.latlng.lng,
                                distance:-1,
                                zoom:(res.zoom===undefined?0:res.zoom),
                                idx:-1
                            }}));
                        }
                    }
                }
        
            }else{
                const mouseY = e.originalEvent.pageY
                const mouseX = e.originalEvent.pageX
                setContextMenu({
                    open:true,
                    out:true,in:false,
                    mouseX:mouseX,
                    mouseY:mouseY,
                    data:{
                        lat:e.latlng.lat,
                        lng:e.latlng.lng
                    },
                    native:{
                        lat:e.latlng.lat,
                        lng:e.latlng.lng
                    }
                });
            }
        },
        click:(e:L.LeafletMouseEvent)=>{
            // console.log(`lat=${e.latlng.lat}, lng=${e.latlng.lng}`)
            // if (!longPressHappened.current) {
                // console.log('is onClick')
                // if (pressTimer.current !== null) window.clearTimeout(pressTimer.current);
                if(enableDoubleClick){
                    clicks++;
                    if (clicks === 1) {
                        timer = setTimeout(() => {
                            addNewPoint({
                                lat:e.latlng.lat,
                                lng:e.latlng.lng,
                            });
                            // console.log('Single Click');
                            clicks = 0;
                        },delay);
                    }
                }else{
                    if(isMobile || isTablet){
                        addNewPoint({
                            lat:e.latlng.lat,
                            lng:e.latlng.lng,
                        });
                        setIsEdited(true)
                    }else{
                        addNewPoint({
                            lat:e.latlng.lat,
                            lng:e.latlng.lng,
                        });
                        setIsEdited(true)
                    }
                }
            // }else{
            //     longPressHappened.current = false
            // }
        },
        dblclick:(e:L.LeafletMouseEvent)=>{
            if(enableDoubleClick){
                if(timer){
                    clearTimeout(timer);
                }
                // console.log('Double Click');
                clicks = 0;    
            }
        },
        mousedown:(e:L.LeafletMouseEvent)=>{
            // console.log('mousedown',e);
        },
        mouseup:(e:L.LeafletMouseEvent)=>{
            // console.log('mouseup',e);
        },
        mouseout:(e:L.LeafletEvent)=>{
            // console.log('mouseout',e);
        }
    });

    const addNewPoint = (INPUT:{lat:number,lng:number}) => {
        // console.log('new point');
        const mode = (pointComp.points.length === 0)?'start':'normal';
        const newCounter = pointComp.routeInfo.counter + 1;
        const newPoint = {
            lat:INPUT.lat,
            lng:INPUT.lng,
            use:routeOpt.use, // 'bicycle' or 'line'
            mode:mode, // 'start' or 'normal' 先頭の要素のみ 'start'
            title:(mode==='start')?'START':'',
            detail:'',
            fromLast:[], //ここに中間ポイントを格納
            counter:newCounter
        };
        if(mode==='start'){ //自転車/直接 共通の開始ポイント
            setPointComp((prev) => ({
                routeInfo:{
                    id:prev.routeInfo.id,
                    isPublic:prev.routeInfo.isPublic,
                    title:prev.routeInfo.title,
                    description:prev.routeInfo.description,
                    counter:newCounter
                },
                points:[...prev.points,newPoint],
                historyUndo:[...prev.historyUndo,{...newPoint,...{type:'point'}}],
                historyRedo:[]
            }));
        }else if(routeOpt.use === 'line'){ //直線
            const bicyclePoint = {
                lat:INPUT.lat,
                lng:INPUT.lng,
                use:routeOpt.use, // 'bicycle' or 'line'
                mode:mode, // 'start' or 'normal' 先頭の要素のみ 'start'
                title:'',
                detail:'',
                fromLast:[[INPUT.lat,INPUT.lng,false,{modifier:"",name:""}]], //直線の場合は自点を中間ポイントにも格納
                counter:newCounter
            };
            setPointComp((prev) => ({
                routeInfo:{
                    id:prev.routeInfo.id,
                    isPublic:prev.routeInfo.isPublic,
                    title:prev.routeInfo.title,
                    description:prev.routeInfo.description,
                    counter:newCounter
                },
                points:[...prev.points,bicyclePoint],
                historyUndo:[...prev.historyUndo,{...bicyclePoint,...{type:'point'}}],
                historyRedo:[]
            }));
        }else if(routeOpt.use === 'bicycle'){ //自転車
            const lastPos = pointComp.points.length - 1;
            const lastPoint = pointComp.points[lastPos];
            let myOsrm = new osrmIO();
            setBackdrop(true)
            myOsrm.request({
                lon1:lastPoint.lng,
                lat1:lastPoint.lat,
                lon2:newPoint.lng,
                lat2:newPoint.lat,
                onThen:function(data){
                    // console.log(data);
                    if(data.code === 'Ok'){
                        if(data.routes.length > 0){
                            if(data.routes[0].legs.length > 0){
                                // const newParentIdx = pointComp.points.length;
                                let fromLast:any[] = [];
                                data.routes[0].legs.forEach((leg:any,ix:number)=>{
                                    let isLastLeg:boolean = (ix === (data.routes[0].legs.length - 1));
                                    leg.steps.forEach((step:any,kx:number) => {
                                        // console.log('step',step)
                                        let isLastStep:boolean = false;
                                        if(isLastLeg){
                                            isLastStep = (kx === (leg.steps.length - 1));
                                        }
                                        let isTurn = false
                                        let turnInfo = {modifier:'',name:''}
                                        if(step.maneuver.modifier && !isLastStep){
                                            if(step.maneuver.modifier.includes('straight')){
                                                if(!sessionInfo.skipStraight){
                                                    isTurn = routeOpt.autocue;
                                                    turnInfo.modifier = step.maneuver.modifier;
                                                }
                                            }else{
                                                const {newIsTurn} = detectIsTurn(step.maneuver.modifier);
                                                if(newIsTurn){
                                                    isTurn = routeOpt.autocue;
                                                }else{
                                                    isTurn = false;
                                                }
                                                turnInfo.modifier = step.maneuver.modifier;
                                            }
                                        }
                                        if(step.name){
                                            // turnInfo.name = step.name;
                                            //↓表示の整合性を考慮して modifier がブランクのものは name をクリアする
                                            if(turnInfo.modifier !==''){
                                                turnInfo.name = step.name;
                                            }
                                        }
                                        if(lastPos===0 && ix===0 && kx===0){
                                            //初回ルーティングなら最初の要素は補正
                                            isTurn = false;
                                            turnInfo.name = "";
                                            turnInfo.modifier = "";
                                        }
                                        // console.log(`routeOpt.autocue=${routeOpt.autocue}`);
                                        // fromLast = [...fromLast,...step.geometry.coordinates.map((one:any)=>[one[1],one[0]])];
                                        // fromLast = [...fromLast,...step.geometry.coordinates.map((one:any,idx:number)=>[one[1],one[0],(idx===0)?isTurn:false,(idx===0)?turnInfo:{modifier:'',name:''}])];
                                        fromLast = [...fromLast,...step.geometry.coordinates.map((one:any,idx:number)=>[one[1],one[0],(idx===0)?(routeOpt.autocue?isTurn:false):false,(idx===0)?(routeOpt.autocue?turnInfo:{modifier:'',name:''}):{modifier:'',name:''}])];
                                    });
                                });
                                let newPointFixed = {...newPoint,...{fromLast:fromLast}};
                                const pos = fromLast.length - 1;
                                newPointFixed.lat = fromLast[pos][0];
                                newPointFixed.lng = fromLast[pos][1];
                                setPointComp((prev) => ({
                                    routeInfo:{
                                        id:prev.routeInfo.id,
                                        isPublic:prev.routeInfo.isPublic,
                                        title:prev.routeInfo.title,
                                        description:prev.routeInfo.description,
                                        counter:newCounter
                                    },
                                    // points:[...prev.points,newPointFixed],
                                    points:[...prev.points.map((one,idx1)=>{
                                        if(lastPos === 0){
                                            //Startポイントの補正
                                            return {...one,...{
                                                lat:fromLast[0][0],
                                                lng:fromLast[0][1]
                                            }}
                                        }else{
                                            return one
                                        }
                                    }),newPointFixed],
                                    historyUndo:[...prev.historyUndo.map((two,idx2)=>{
                                        if(lastPos === 0){
                                            const lastHistoryPos = prev.historyUndo.length - 1;
                                            if(idx2 === lastHistoryPos){
                                                //Startポイントの補正
                                                return {...two,...{
                                                    lat:fromLast[0][0],
                                                    lng:fromLast[0][1]
                                                }}
                                            }else{
                                                return two
                                            }
                                        }else{
                                            return two
                                        }
                                    }),{...newPointFixed,...{type:'point'}}],
                                    historyRedo:[]
                                }));
                            }
                        }
                    }
                },
                onCatch:function(data){
                    console.log(data)
                },
                onFinally:function(){
                    mapRef?.setView([newPoint.lat,newPoint.lng]);
                    setBackdrop(false)
                },
            })
        }
    }
    React.useEffect(() => {
        let distanceArr:any[] = [];
        let last:number[] = [];
        let dist:number = 0;
        if(pointComp.points.length > 0){
            pointComp.points.forEach((one,parentIdx) =>{
                // console.log('one.fromLast',one.fromLast);
                if(one.fromLast.length > 0){
                    distanceArr = [...distanceArr,...one.fromLast.map((two,childIdx)=>{
                        // console.log('two',two);
                        dist += getDistance(last[0],last[1],two[0],two[1]);
                        last = two;
                        // return [...two,dist];
                        //↓parent及びchildのindexを追加
                        // return [...two,dist,parentIdx,childIdx];
                        //↓書き方修正 ( 型判定でなぜかエラーになるケースがあるので再度配列として生成 )
                        const res = [];
                        res.push(two[0])
                        res.push(two[1])
                        res.push(two[2])
                        res.push(two[3])
                        res.push(dist);
                        res.push(parentIdx);
                        res.push(childIdx);
                        res.push(one.use);
                    return res;
                    })];
                }else{
                    // distanceArr.push([one.lat,one.lng,false,{modifier:'',name:''},0])
                    //↓parent及びchildのindexを追加 ( childはないので -1 )
                    // distanceArr.push([one.lat,one.lng,false,{modifier:'',name:''},0,parentIdx,-1])
                    distanceArr.push([one.lat,one.lng,false,{modifier:'',name:''},0,parentIdx,-1,one.use])
                    last = [one.lat,one.lng];
                }
            })
        }
        setOptionalInfo((prev)=>({...prev,...{distance:dist}}));

        // console.log('distanceArr',distanceArr);
        setPolylineArr(distanceArr);
        // calcDistanceAsync(distanceArr).then((distance) => {
        //     // console.log('OK',distance);
        //     setOptionalInfo((prev)=>({...prev,...{distance:distance}}));
        // }).catch(() => {
        //     // console.log('error');
        // })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pointComp]);
    return (
        <ThemeProvider theme={theme}>
            <MarkersAndPolylineSub mapRef={mapRef} />
        </ThemeProvider>
    )
}



export function MarkersAndPolylineSub({mapRef}:{mapRef:L.Map|null}){
    const {t} = useTranslation();
    const location = useLocation();
    const {sessionInfo} = React.useContext(SessionContext);
    const {pointComp,polylineArr,contextMenu,setContextMenu,popupRef,hoverMarker,setHoverMarker,markerToggle} = React.useContext(RouteContext);
    const [polylineStyle, setPolylineStyle] = React.useState(polyLineStyle.default);

    const isSmUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const isXs = !isSmUp;
    const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
    const isSm = isSmUp && isMdDown;
    const isMdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const isLgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const isMd = isMdUp && isLgDown;
    const isLgUP = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const handleContextmenu = (e:any) => {
        // console.log(e);
        // console.log('polylineArr',polylineArr);
        let mousePosAddY = 0;
        if(!sessionInfo.noAds){
            if(isXs||isSm){
                mousePosAddY = adsHeight.small
            }else if(isMd||isLgUP){
                mousePosAddY = adsHeight.large
            }
        }
        const res = {
            target:findNearestPoint(e.latlng.lat,e.latlng.lng,polylineArr),
            zoom:mapRef?.getZoom()
        }
        // console.log(res);
        setContextMenu({
            open:true,
            out:false,in:true,
            mouseX:e.containerPoint.x + outerGridWidth.left,
            mouseY:e.containerPoint.y + mousePosAddY,
            data:{
                // lat:e.latlng.lat,
                // lng:e.latlng.lng
                lat:res.target.lat,
                lng:res.target.lng
            },
            native:{
                lat:e.latlng.lat,
                lng:e.latlng.lng
            }
        })
    }
    // React.useEffect(() => {
    //     if(hoverMarker.isMarkerHover){
    //         setPolylineStyle(polyLineStyle.hover);
    //     }else{
    //         setPolylineStyle(polyLineStyle.default);
    //     }
    // },[hoverMarker.isMarkerHover])
    React.useEffect(() => {
        if(hoverMarker.isMarkerHover || hoverMarker.isPolylineHover|| contextMenu.open){
            setPolylineStyle(polyLineStyle.hover);
            setHoverMarker((prev)=>({...prev,...{visible:true}}));
        }else{
            setPolylineStyle(polyLineStyle.default);
        }
        if(!hoverMarker.isMarkerHover && !hoverMarker.isPolylineHover && !contextMenu.open){
            setHoverMarker((prev)=>({...prev,...{visible:false}}));
        }
        // eslint-disable-next-line
    },[hoverMarker.isMarkerHover,hoverMarker.isPolylineHover,contextMenu.open]);
    popupRef.current = [];
    return (
        <>
            {(pointComp.points.length > 0) &&
            <>
                <Polyline pathOptions={polylineStyle} positions={polylineArr.map((one) => [one[0],one[1]])}
                    eventHandlers={{
                        mouseover:(e:L.LeafletMouseEvent)=>{
                            if(!(isMobile || isTablet)){ //非モバイル時のみ
                                // console.log(e);
                                L.DomEvent.stopPropagation(e);
                                setHoverMarker((prev:any)=>({...prev,...{isPolylineHover:true}}));
                            }
                        },
                        mousemove:(e:L.LeafletMouseEvent)=>{
                            if(!(isMobile || isTablet)){ //非モバイル時のみ
                                // console.log(e);
                                L.DomEvent.stopPropagation(e);
                                // if(!contextMenu.open){
                                setHoverMarker((prev)=>({...prev,...{lat:e.latlng.lat,lng:e.latlng.lng}}));
                                // }
                            }
                        },
                        mouseout:(e:L.LeafletMouseEvent)=>{
                            if(!(isMobile || isTablet)){ //非モバイル時のみ
                                L.DomEvent.stopPropagation(e);
                                setHoverMarker((prev:any)=>({...prev,...{isPolylineHover:false}}));
                            }
                        }
                    }}
                />
                {/* ↓Polylineホバー用のMarker */}
                {(hoverMarker.visible && location.pathname==='/editor') &&
                    <Marker position={[hoverMarker.lat,hoverMarker.lng]} icon={hoverMkr} eventHandlers={{
                        mouseover:(e:L.LeafletMouseEvent)=>{
                            if(!(isMobile || isTablet)){ //非モバイル時のみ
                                setHoverMarker((prev:any)=>({...prev,...{isMarkerHover:true}}));
                            }
                        },
                        mouseout:(e:L.LeafletMouseEvent)=>{
                            if(!(isMobile || isTablet)){ //非モバイル時のみ
                                setHoverMarker((prev:any)=>({...prev,...{isMarkerHover:false}}));
                            }
                        },
                        contextmenu:handleContextmenu
                    }}></Marker>
                }
                {/* {(polylineArr.length === 0) &&
                    <Marker key='one' position={[pointComp.points[0].lat,pointComp.points[0].lng]} icon={colorMarker("green")}>
                        <Popup autoPan={false}>START</Popup>
                    </Marker>
                } */}
                {(polylineArr.length > 0) &&
                    <>
                        <CustomMarker target="key_first" position={[polylineArr[0][0],polylineArr[0][1]]} icon={customMarkerIcon("start")} mapRef={mapRef}>
                            <CustomPopup target="key_first" indexes={{parent:-1,child:-1}} message="START" />
                        </CustomMarker>
                        {/* {polylineArr.filter((one)=>(one[2]||one[3].name!=='')).map((one,key)=>{ */}
                        {polylineArr.filter((one)=>(one[2]||modifierList.includes(one[3].modifier))).map((one,key)=>{

                            // console.log('modifier=' + one[3].modifier)

                            //以下2要素を使用してターンマーカーを表示する
                            // one[3].modifier : 方向 ( 後に方向以外も追加 )
                            // one[3].name : 交差点名または通りの名前 ( ブランクあり )
                            // console.log(`modifier=${one[3].modifier}, name=${one[3].name}`)
                            const markerKey = `key_${key}`;
                            const indexes:typeIndexes = {
                                parent:one[5],
                                child:one[6]
                            }
                            const {newIsTurn,newModifier} = detectIsTurn(one[3].modifier);
                            let message = '';
                            if(newIsTurn){
                                let popupModifier = t('map.popup.' + newModifier);
                                if(popupModifier === ('map.popup.' + newModifier)){
                                    popupModifier = one[3].modifier;
                                }
                                message = popupModifier + (one[3].name?` (${one[3].name})`:'');
                                return (
                                    <React.Fragment key={key}>
                                        {markerToggle &&
                                            <CustomMarker target={markerKey} key={key} position={[one[0],one[1]]} icon={customMarkerIcon(newModifier)} mapRef={mapRef}>
                                                <CustomPopup target={markerKey} indexes={indexes} message={message} />
                                            </CustomMarker>
                                        }
                                    </React.Fragment>
                                )
                            }else{
                                const modifierPlain = one[3].modifier;
                                let message = '';
                                if(one[3].modifier === ''){
                                    if(one[3].name !==''){
                                        message = one[3].name
                                    }
                                }else{
                                    let modifier = '';
                                    if(modifierList.includes(one[3].modifier)){
                                        modifier = t(`routeEditor.dialog.edit.modifier.${one[3].modifier}`);
                                        if(modifier === `routeEditor.dialog.edit.modifier.${one[3].modifier}`){
                                            //翻訳データが無い場合
                                            modifier = one[3].modifier
                                        }
                                    }else{
                                        modifier = one[3].modifier
                                    }
                                    message = modifier;
                                    if(one[3].name !== ''){ message += `(${one[3].name})` }
                                }
                                return (
                                    <React.Fragment key={key}>
                                        {markerToggle &&
                                            <CustomMarker target={markerKey} key={key} position={[one[0],one[1]]} icon={customMarkerIcon(modifierPlain)} mapRef={mapRef}>
                                                <CustomPopup target={markerKey} indexes={indexes} message={message} />
                                            </CustomMarker>
                                        }
                                    </React.Fragment>
                                )
                            }
                        })}
                        {(polylineArr.length > 1)&&
                            <CustomMarker target="key_last" position={[polylineArr[polylineArr.length - 1][0],polylineArr[polylineArr.length - 1][1]]} icon={customMarkerIcon("end")} mapRef={mapRef}>
                                <CustomPopup target="key_last" indexes={{parent:-1,child:-1}} message='END' />
                            </CustomMarker>
                        }
                    </>
                }
            </>
            }
        </>
    )

}

// MyMarkerコンポーネントのプロパティの型定義
interface CustomMarkerProps extends MarkerProps {
    target?:string;
    mapRef?:L.Map|null|undefined;
}

//Markerコンポーネントのカスタム版
const CustomMarker: React.FC<CustomMarkerProps> = ({ children, ...props }) => {
    const {popuoOpen,setPopuoOpen,popupRef} = React.useContext(RouteContext);
    const markerRef = React.useRef<L.Marker>(null);
    if(props.target !== null && props.target !== undefined){
        popupRef.current = [...popupRef.current,props.target as string]
    }    
    React.useEffect(() => {
        // L.LatLng
        // L.LatLngLiteral
        // L.LatLngTuple
        try{
            // console.log('CustomMarker props.position',props.position)
            // console.log('CustomMarker props',props)
            // openThisPopup(markerRef,props);
            if(
                markerRef.current !== null &&
                props.mapRef !== null &&
                props.mapRef !== undefined){
                if(popuoOpen.open){
                    if(popuoOpen.target === props.target){
                        // console.log(popuoOpen)
                        markerRef.current.openPopup();
                        const latlng = props.position as number[];
                        props.mapRef.setView([latlng[0],latlng[1]]);
                    }else{
                        if(markerRef.current.isPopupOpen()){
                            markerRef.current.closePopup();
                        }
                    }
                }else{
                    if(markerRef.current.isPopupOpen()){
                        markerRef.current.closePopup();
                    }
                }
            }
        }catch(error){
            console.log('error',error);
        }
        // eslint-disable-next-line
    },[popuoOpen,props.mapRef])
    const handleOnClick = () => {
        if(
            markerRef.current !== null &&
            props.mapRef !== null &&
            props.mapRef !== undefined){
            if(popuoOpen.open){
                if(popuoOpen.target === props.target){
                    setPopuoOpen((prev)=>({...prev,...{open:false,target:""}}))
                }else{
                    // setPopuoOpen({open:true,target:props.target as string,trigger:true})
                    setPopuoOpen((prev)=>({...prev,...{open:true,target:props.target as string,trigger:true}}))
                }
            }else{
                // setPopuoOpen({open:true,target:props.target as string,trigger:true})
                setPopuoOpen((prev)=>({...prev,...{open:true,target:props.target as string,trigger:true}}))
            }
        }
    }
    return (
        <Marker ref={markerRef} {...props} eventHandlers={{click:handleOnClick}}>{children}</Marker>
    )
};

type typeIndexes = {
    parent:number;
    child:number;
}

function CustomPopup({target,message,indexes}:{target:string,message:string,indexes:typeIndexes}){
    const {sessionInfo} = React.useContext(SessionContext);
    const {setPopuoOpen,setEditDialog} = React.useContext(RouteContext);
    const {t} = useTranslation();
    const location = useLocation();
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const handleClose = () => {
        setPopuoOpen((prev)=>({...prev,...{open:false,target:""}}))
    }
    const handleEdit = () => {
        setEditDialog((prev)=>({...prev,...{open:true,preopen:false,isNew:false,lat:-1,lng:-1,parentIdx:indexes.parent,childIdx:indexes.child}}));
        setPopuoOpen((prev)=>({...prev,...{open:false,target:""}}))
    }
    return (
        <Popup autoPan={false}>
            <span className={GoogleFonts.className} style={{wordBreak:'break-all'}}>{message}</span>
            <Box sx={{width:'100%',textAlign:'center',marginTop:'12px'}}>
                {(indexes.parent>=0 && indexes.child>=0 && location.pathname==='/editor') &&
                    <React.Fragment>
                        <Button sx={{display:'inline-block'}} variant="contained" onClick={handleEdit}>
                            <span className={GoogleFonts.className}>{t('map.popup.btnEdit')}</span>
                        </Button>
                        &emsp;
                    </React.Fragment>
                }
                <Button sx={{display:'inline-block'}} variant="contained" onClick={handleClose}>
                    <span className={GoogleFonts.className}>{t('map.popup.btnClose')}</span>
                </Button>
            </Box>
        </Popup>
    )
}

export function getDistance(lat1:number,lng1:number,lat2:number,lng2:number) {
    const R = 6371e3; // 地球の半径（メートル）
    const φ1 = lat1 * Math.PI/180; // 緯度をラジアンに変換
    const φ2 = lat2 * Math.PI/180; // 緯度をラジアンに変換
    const Δφ = (lat2-lat1) * Math.PI/180; // 緯度の差をラジアンに変換
    const Δλ = (lng2-lng1) * Math.PI/180; // 経度の差をラジアンに変換

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

    const distance = R * c; // 2点間の距離（メートル）

    // console.log(`lat1=${lat1},lng1=${lng1},lat2=${lat2},lng2=${lng2},distance=${distance}`);
    return distance;
}

function findNearestPoint(IN_LAT:number,IN_LNG:number,polylineArr:any[]){
    // console.log("polylineArr",polylineArr);
    let result = {distance:-1,lat:0,lng:0,idx:-1,use:""};
    let second = {distance:-1,lat:0,lng:0,idx:-1,use:""};
    if(polylineArr.length > 0){
        polylineArr.forEach((one,idx)=>{
            // const tempDist  = getDistance(IN_LAT,IN_LNG,one[0],one[1]);
            // console.log('tempDist',tempDist);
            if(idx === 0){
                result.distance = getDistance(IN_LAT,IN_LNG,one[0],one[1]);
                result.lat = one[0];
                result.lng = one[1];
                result.idx = idx;
                result.use = one[7];
            }else{
                let distance = getDistance(IN_LAT,IN_LNG,one[0],one[1]);
                if(distance < result.distance){
                    second = {...result};
                    result.distance = distance
                    result.lat = one[0];
                    result.lng = one[1];
                    result.idx = idx;
                    result.use = one[7];
                }else{
                    if(idx === 1){
                        second.distance = distance
                        second.lat = one[0];
                        second.lng = one[1];
                        second.idx = idx;
                        second.use = one[7];
                    }
                }
            }
        });
        //直線補正
        if(result.idx >= 0 && second.idx >= 0){
            if((result.idx + 1) === second.idx){ //近い方がインデックスが1つ前だった場合
                if(second.use ==='line'){ //2番目が直線指定だった場合
                    result = {...second};
                }
            }
        }
    }else{
        result.lat = IN_LAT;
        result.lng = IN_LNG;
    }
    return result;

}

//デフォルトロケーション更新汎用
export function saveDefaultLocation(IN_LAT:number,IN_LNG:number,setBackdrop?:React.Dispatch<React.SetStateAction<boolean>>){
    if(setBackdrop){ setBackdrop(true) }
    let mySession = new sessionIO();
    let req = baseUri
    + "setdefaultlocation?lat=" + encodeURIComponent(IN_LAT)
    + "&lng=" + encodeURIComponent(IN_LNG);
    fetch(
        req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
    ).then(res => {
        // console.log('HTTP CODE : ' + res.status);
        return res.json();
    }).then(content => {
        // console.log('content : ',content);
    }).catch(err => {
        console.log('err : ',err);
    }).finally(() => {
        if(setBackdrop){ setBackdrop(false) }
    });

}

//非同期関数
// function calcDistanceAsync(arr:any[]) {
//     return new Promise<number>((resolve,reject) => {
//         setTimeout(() => {
//             let last:number[] = [];
//             let distance:number = 0;
//             arr.forEach((one,i)=>{
//                 if(i === 0){
//                     last = one;
//                 }else{
//                     distance += getDistance(last[0],last[1],one[0],one[1]);
//                     last = one;
//                 }
//             })
//             resolve(distance);
//         },10);
//     })
// }
