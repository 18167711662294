import { Alert, Box, Button, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import { adjustFontPosition, getLanguageObj } from '../System/Locales';
import { SessionContext, baseUri, sessionIO } from '../System/Session';
import { setTitle } from '../System/Title';
import TurnstileWidget from '../System/Turnstile';
import { themeBtnAll } from './RouteEditor/RouteEditorColumnRight';

const commonShadow = '0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000';
const topLinkStyle = {
    color:'#ffffff',
    fontSize:'20px',
    textShadow:commonShadow,
    textDecoration:'underline',
    cursor:'pointer'
} as React.CSSProperties;

const messageStyle = {
    color:'#ffffff',
    fontSize:'18px',
    textShadow:commonShadow,
    textDecoration:'none',
};

const mailInputStyle = {
    backgroundColor:'#ffffff',
}

const mailInputStyleDisabled = {
    backgroundColor:'transparent',
}

export default function ResetPassword(){

    //背景切り替え
    if(isMobile&&!isTablet){
        document.body.classList.add('cycroute-mobile');
        document.body.classList.remove('cycroute-pc');
    }else{
        document.body.classList.add('cycroute-pc');
        document.body.classList.remove('cycroute-mobile');
    }
    const {t} = useTranslation();
    setTitle(t('top.reasetPassword'));
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const navigate = useNavigate();

    const [success,setSuccess] = React.useState<boolean>(false);

    const handleBack = () => {
        navigate('/')
    }
    return (
        <React.Fragment>
            <Box width="100vw" height="100%" display="flex" flexDirection="column" sx={{backgroundColor:'transparent'}}>

                <Box sx={{width:(isMobile&&!isTablet)?'300px':'420px',margin:'24px auto 0px auto',textAlign:'left'}}>
                    <span onClick={handleBack} className={GoogleFonts.className} style={topLinkStyle}>{t('sendPasswordReset.linkToTop')}</span>
                </Box>

                <Content success={success} />

                <Box sx={{margin:'90px auto 0px auto'}}>
                    <TurnstileWidget setSuccess={setSuccess} />
                </Box>

            </Box>
        </React.Fragment>
    )
}

type typeComm = {
    progress:boolean;
    success:boolean;
    failed:boolean;
    complete:boolean;
    msg:string;
}

function Content({success}:{success?:boolean}){

    const accept = success??true;

    const {t} = useTranslation();
    const {sessionInfo,setBackdrop} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [mail,setMail] = React.useState<string>('');
    const [mailAddressValid,setMailAddressValid] = React.useState<boolean>(false);
    const [comm,setComm] = React.useState<typeComm>({progress:false,success:false,failed:false,complete:false,msg:''});
    const handlePasswordReset = () => {
        if(checkAddressChar(mail,setMailAddressValid)){
            sendPasswordReset(mail,sessionInfo.language,setBackdrop,setComm);
        }
    }
    React.useEffect(()=>{
        checkAddressChar(mail,setMailAddressValid)
    },[mail])
    return(
        <React.Fragment>
            <Box sx={{width:(isMobile&&!isTablet)?'300px':'420px',margin:'50px auto 0px auto'}}>
                <Box sx={messageStyle}>
                    <span style={styleBtnText}>{t('sendPasswordReset.message')}</span>
                </Box>
            </Box>
            <Box sx={{width:'300px',margin:'40px auto 0px auto'}}>
                <TextField sx={accept?mailInputStyle:mailInputStyleDisabled} fullWidth label={t('sendPasswordReset.inputLabelMailAddress')} variant="filled" name="cycroutemail" onChange={(e)=>{setMail(e.target.value)}} type="mail" value={mail} disabled={!accept} />
                <ThemeProvider theme={themeBtnAll}>
                    <Button sx={{marginTop:'20px'}} fullWidth variant="contained" size="large" className={GoogleFonts.className} onClick={handlePasswordReset} disabled={!accept}><span style={styleBtnText}>{t('sendPasswordReset.btnSubmit')}</span></Button>
                </ThemeProvider>
                {((mail!=='')&&!mailAddressValid) &&
                    <Alert sx={{marginTop:'20px'}} severity="error">{t('common.mailValidation.invalid')}</Alert>
                }
                {((mail!=='')&&mailAddressValid) &&
                    <Alert sx={{marginTop:'20px'}} severity="success">{t('common.mailValidation.valid')}</Alert>
                }
                {(comm.progress) &&
                    <Alert sx={{marginTop:'20px'}} severity="info">{t('common.mailSending.progress')}</Alert>
                }
                {(comm.success) &&
                    <Alert sx={{marginTop:'20px'}} severity="success">{t('common.mailSending.success')}</Alert>
                }
                {(comm.failed) &&
                    <Alert sx={{marginTop:'20px'}} severity="error">{t('common.mailSending.failed')}</Alert>
                }
            </Box>
        </React.Fragment>
    )
}

function checkAddressChar(mail:string,setMailAddressValid:React.Dispatch<React.SetStateAction<boolean>>){
    const pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+\.[A-Za-z0-9]+$/;
    if(pattern.test(mail)){
        setMailAddressValid(true)
        return true
    }else{
        setMailAddressValid(false)
        return false
    }
}

function sendPasswordReset(
    mailAddress:string,
    language:string,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    setComm:React.Dispatch<React.SetStateAction<typeComm>>){
    setComm((prev)=>({...prev,...{progress:true,success:false,failed:false,complete:false,msg:''}}))
    setBackdrop(true);
    let mySession = new sessionIO();
    let req = baseUri
        + "sendpasswordreset?mailaddress=" + encodeURIComponent(mailAddress)
        + "&language=" + encodeURIComponent(language)
    fetch(
        req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
    ).then(res => {
        // console.log('HTTP CODE : ' + res.status);
        return res.json();
    }).then(content => {
        // console.log('content : ',content);
        if(content.success){
            setComm((prev)=>({...prev,...{progress:false,success:true,failed:false,complete:false,msg:''}}))
        }else{
            setComm((prev)=>({...prev,...{progress:false,success:false,failed:true,complete:false,msg:''}}))
        }
    }).catch(err => {
        console.log('err : ',err);
        setComm((prev)=>({...prev,...{progress:false,success:false,failed:true,complete:false,msg:''}}))
    }).finally(() => {
        setBackdrop(false);
    });
}