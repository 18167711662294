
const SITE_NAME = process.env.REACT_APP_SITE_NAME as string;

export function setTitle(INPUT:string=''){
    if(INPUT !==''){
        INPUT += ' - ';
    }
    const titleElement = document.querySelector('title');
    if(titleElement){
        titleElement.innerText = `${INPUT}${SITE_NAME}`;
    }
}