import { Box, Button, Grid, Paper } from '@mui/material';
import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { isMobile, isTablet } from "react-device-detect";

import { adjustFontPosition, getLanguageObj } from '../../System/Locales';
import { RouteContext } from '../../System/RouteData';
import { SessionContext } from '../../System/Session';
import { outerGridWidth, swipeBorderMove } from '../Map/Casts';
import { CueIcon, detectIsTurn, modifierList, modifierListTurn } from '../Map/CustomMarkers';
import { CueSheetAdjustButton, typeSidesVisible } from '../Map/SideArrows';
import { styleBtnAll } from './RouteEditorColumnLeft';
import { styleInnerLeft, styleInnerRight, styleOuter } from './RouteEditorColumnStyle';

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

export const themeBtnAll = createTheme(styleBtnAll);

export default function RouteEditorColumnRight(
    {sidesVisible,setSidesVisible,isLefty,setBoundTrigger,map}:
    {
        sidesVisible:typeSidesVisible,
        setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>,
        isLefty:boolean,
        setBoundTrigger:React.Dispatch<React.SetStateAction<boolean>>,
        map:L.Map|null
    }){
    const {t} = useTranslation();
    const location = useLocation();
    const {sessionInfo} = React.useContext(SessionContext);
    const {optionalInfo,cueWidthMode,popuoOpen,setPopuoOpen} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const parentRef = React.useRef<HTMLInputElement>(null);
    const [cueHeight,setCueHeight] = React.useState<number>(200);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整

    const distanceRef = React.useRef<HTMLInputElement>(null);
    const [distanceHeight,setDistanceHeight] = React.useState<number>(0)

    //単語が長い言語用の調整
    let styleBtnTextFix = {...styleBtnText};
    if(sessionInfo.language === 'it'){
        styleBtnTextFix.fontSize = '0.9em';
    }

    //ここからスワイプハンドリング
    const [touchStart, setTouchStart] = React.useState(null)
    const [touchEnd, setTouchEnd] = React.useState(null)
    const minSwipeDistance = swipeBorderMove 
    const onTouchStart = (e:any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e:any) => setTouchEnd(e.targetTouches[0].clientX)
    const onTouchEnd = (e:any) => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if(isRightSwipe){
            if(!sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{right:false}}));
            }
        }
        if(isLeftSwipe){
            if(sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{right:false}}));
            }
        }
    }
    //ここまでスワイプハンドリング

    const handleBound = () => {
        setBoundTrigger((prev)=>(!prev)) //バウンドトリガ
    }

    const handleDefaultLocation = () => {
        if(map){
            map.setView([
                sessionInfo.defaultLocation.lat,
                sessionInfo.defaultLocation.lng
            ])
        }
    }

    const handleGPS = () => {
        navigator.geolocation.getCurrentPosition(funcGPS);
    }
    const funcGPS = (GPS:GeolocationPosition) => {
        // console.log(GPS.coords.latitude,GPS.coords.longitude)
        if(map){
            map.flyTo([GPS.coords.latitude,GPS.coords.longitude]);
        }
    }

    const styleInner = isLefty?styleInnerLeft:styleInnerRight;

    // const cueHeightDiff = (isMobile || isTablet)?79:165;
    // const cueHeightDiff = (isMobile || isTablet)?11:165;
    // const cueHeightDiff = (isMobile || isTablet)?11:168;
    // const cueHeightDiff = (isMobile || isTablet)?11:(
    //     (location.pathname==='/editor')?168:112
    // );
    
    const calcCueHeightDiff = () => {
        if(location.pathname==='/editor'){
            if(isMobile || isTablet){
                return 11;
            }else{
                return 168;
            }
        }else{
            if(isMobile || isTablet){
                // return 68;
                // distanceHeight はデフォルト25pxだったので 68 -25 = 43
                return (43 + distanceHeight);
            }else{
                // return 112;
                return 170;
            }
        }
    };
    const cueHeightDiff = calcCueHeightDiff();
    useLayoutEffect(() => {
        if(parentRef.current?.offsetHeight){
            const newHeight = parentRef.current.offsetHeight - cueHeightDiff;
            setCueHeight(newHeight)
        }
    },[cueHeightDiff]);

    useEffect(() => {
        const handleResize = () => {
            if(parentRef.current?.offsetHeight){
                const newHeight = parentRef.current.offsetHeight - cueHeightDiff;
                setCueHeight(newHeight)
            }
        };
        window.addEventListener('resize',handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);//クリーンアップ ( メモリリーク回避 )
        };
    },[cueHeightDiff]);
    useEffect(()=>{
        // console.log('distanceRef.current.offsetHeight',distanceRef.current?.offsetHeight)
        if(distanceRef.current?.offsetHeight){
            setDistanceHeight(distanceRef.current.offsetHeight)
        }
    },[cueWidthMode]);

    useEffect(()=>{
        if(popuoOpen.target!=='' && popuoOpen.trigger){
            const selectedRow = document.querySelector(`div[data-row-id="${popuoOpen.target}"]`);
            if(selectedRow){
                // 要素のビューポートに対する座標を取得
                const targetRect = selectedRow.getBoundingClientRect();

                // 親要素のビューポートに対する座標を取得
                const parentElement = selectedRow.parentElement;
                if(parentElement){
                    const gParentElement = parentElement.parentElement;
                    if(gParentElement){
                        const ggParentElement = gParentElement.parentElement;
                        if(ggParentElement){
                            const parentRect = parentElement.getBoundingClientRect();

                            // 親要素内でのターゲット要素の座標を計算
                            const relativeTop = targetRect.top - parentRect.top;
                            // const relativeLeft = targetRect.left - parentRect.left;
                            // console.log(`position top:${relativeTop},left:${relativeLeft}`)
                            ggParentElement.scrollTop = relativeTop -100;
        
                        }
                    }
                }
            }
            setPopuoOpen((prev)=>({...prev,...{trigger:false}}))
        }
        // eslint-disable-next-line
    },[popuoOpen]);

    const rightColumnWidth = cueWidthMode==='middle'?outerGridWidth.mobile.middle.right:(cueWidthMode==='small'?outerGridWidth.mobile.small.right:outerGridWidth.right);
    return (
        <Box width={rightColumnWidth} height="100%" display="flex" sx={styleOuter} ref={parentRef}
        onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <Box width={rightColumnWidth} sx={styleInner}>
                <ThemeProvider theme={themeBtnAll}>
                    {((isMobile || isTablet) && location.pathname!=='/editor') &&
                        <React.Fragment>
                            <Grid container spacing={0} columns={{xs:30}} sx={{marginTop:'4px'}} ref={distanceRef}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={28}>
                                    <span className={GoogleFonts.className} style={styleBtnText}>{t("routeEditor.totalDistance")}</span>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                            <Grid container spacing={0} columns={{xs:30}} sx={{marginTop:'0px'}}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={28} sx={{textAlign:'right'}}>
                                    <span className={GoogleFonts.className} style={styleBtnText}>
                                        {(sessionInfo.unit==='km')&&
                                            <>
                                                {`${Math.round((optionalInfo.distance/1000)*10)/10} km`}
                                            </>
                                        }
                                        {(sessionInfo.unit==='mile')&&
                                            <>
                                                {`${Math.round(((optionalInfo.distance*0.621371192)/1000)*10)/10} mile`}
                                            </>
                                        }
                                    </span>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    {!(isMobile || isTablet) &&
                        <React.Fragment>
                            <Grid container spacing={0} columns={{xs:30}} sx={{marginTop:'4px'}}>
                                <Grid item xs={1}></Grid>
                                <Grid item xs={16}>
                                    <span className={GoogleFonts.className} style={styleBtnText}>{t("routeEditor.totalDistance")}</span>
                                </Grid>
                                <Grid item xs={12} sx={{textAlign:'right'}}>
                                    <span className={GoogleFonts.className} style={styleBtnText}>
                                        {(sessionInfo.unit==='km')&&
                                            <>
                                                {`${Math.round((optionalInfo.distance/1000)*10)/10} km`}
                                            </>
                                        }
                                        {(sessionInfo.unit==='mile')&&
                                            <>
                                                {`${Math.round(((optionalInfo.distance*0.621371192)/1000)*10)/10} mile`}
                                            </>
                                        }
                                    </span>
                                </Grid>
                                <Grid item xs={1}></Grid>
                            </Grid>
                            {location.pathname==='/editor' &&
                                <Box>
                                    <Button sx={{marginTop:'12px'}} fullWidth variant="contained" size="small" onClick={handleDefaultLocation}>
                                        <span className={GoogleFonts.className} style={styleBtnTextFix}>{t("routeEditor.btnDefaultLocation")}</span>
                                    </Button>
                                </Box>
                            }
                            <Box>
                                <Button sx={{marginTop:'12px'}} fullWidth variant="contained" size="small" onClick={handleBound}>
                                    <span className={GoogleFonts.className} style={styleBtnTextFix}>{t("routeEditor.btnBound")}</span>
                                </Button>
                            </Box>
                            {location.pathname!=='/editor' &&
                                <Box>
                                    <Button sx={{marginTop:'12px'}} fullWidth variant="contained" size="small" onClick={handleGPS}>
                                        <span className={GoogleFonts.className} style={styleBtnTextFix}>{t("common.currentLocation")}</span>
                                    </Button>
                                </Box>
                            }
                        </React.Fragment>
                    }
                    <SimpleBar style={{marginTop:(isMobile || isTablet)?'5px':'14px',height:`${cueHeight}px`,width:'100%',overflowY:'auto',backgroundColor:'transparent'}}>
                        <CheSheet />
                    </SimpleBar>
                </ThemeProvider>
            </Box>
            {(isMobile || isTablet) &&
                <CueSheetAdjustButton sidesVisible={sidesVisible} />
            }
        </Box>
    )
}

function CustomIcon({type=''}:{type?:string}){
    return (
        <React.Fragment>
            <span style={{position:'relative',top:'3px'}}>
                <CueIcon type={type} />
            </span>
        </React.Fragment>
    )
}

//CheSheetのカスタムフック化に伴う追加関数
function useSessionContextForCueSheet() {
    const {sessionInfo} = React.useContext(SessionContext);
    return {sessionInfo};
}

//CheSheetのカスタムフック化に伴う追加関数
function useRouteContextForCueSheet() {
    const {polylineArr,pointComp,popuoOpen,setPopuoOpen,cueWidthMode} = React.useContext(RouteContext);
    return {polylineArr,pointComp,popuoOpen,setPopuoOpen,cueWidthMode};
}

// function CheSheet(){
//↓親要素の高さ変更で再描画されないようカスタムフック化
const CheSheet = React.memo(function CheSheet() {
    const {t} = useTranslation();
    // const {sessionInfo} = React.useContext(SessionContext);
    // const {polylineArr,pointComp,popuoOpen,setPopuoOpen,cueWidthMode} = React.useContext(RouteContext);
    //↓親要素の高さ変更で再描画されないようカスタムフック化
    const {sessionInfo} = useSessionContextForCueSheet();
    const {polylineArr,pointComp,popuoOpen,setPopuoOpen,cueWidthMode} = useRouteContextForCueSheet();
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const fontSize = (cueWidthMode==='middle'?outerGridWidth.mobile.middle.fontSize:(cueWidthMode==='small'?outerGridWidth.mobile.small.fontSize:undefined));
    // const cellClassName = `right-cuesheet-cell${(cueWidthMode!=='')??`-${cueWidthMode}`}`;
    const cellClassName = "right-cuesheet-cell" + (cueWidthMode!==''?'-'+cueWidthMode:'');
    
    const handleClickCue = (ev:React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const target = ev.currentTarget.getAttribute('data-id');
        if(target !== null){
            // console.log('target',target);
            if(popuoOpen.open){
                if(popuoOpen.target === target){
                    setPopuoOpen((prev)=>({...prev,...{open:false,target:""}}))
                }else{
                    setPopuoOpen((prev)=>({...prev,...{open:true,target:target}}))
                }
            }else{
                setPopuoOpen((prev)=>({...prev,...{open:true,target:target}}))
            }
        }
    }
    return (
        <React.Fragment>
        {(pointComp.points.length === 0) &&
            <div style={{padding:'2px 5px'}}>
                <Paper elevation={10} sx={{padding:'8px 10px',backgroundColor:'#d8f0f5'}}>
                    <span className={GoogleFonts.className}>{t("routeEditor.noRouteMessage")}</span>
                </Paper>
            </div>
        }
        {(pointComp.points.length > 0) &&
            <div style={{display:'flex',flexDirection:'column'}}>
                <React.Fragment>
                    {(polylineArr.length === 0)&&
                        <div className='right-cuesheet-row'>
                            <div className={`${cellClassName} right-cuesheet-cell-start`}>

                                <table style={{width:'100%'}}>
                                    <tbody>
                                        <tr>
                                            <td width="50%" style={{fontSize:fontSize}}>&nbsp;</td>
                                            <td width="50%" style={{textAlign:'right',fontSize:fontSize}}>{`${addUnitToDistance(0,sessionInfo.unit)}`}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <GetCueText modifierType="" name={`${(pointComp.points[0].title??'START')}`} t={t} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    }
                    {(polylineArr.length > 0) &&
                        <React.Fragment>
                            <div className='right-cuesheet-row' data-row-id="key_first">
                                <div className={`${cellClassName} right-cuesheet-cell-start`} data-id="key_first" data-lat={polylineArr[0][0]} data-lng={polylineArr[0][1]} onClick={handleClickCue}>
                                    <table style={{width:'100%'}}>
                                        <tbody>
                                            <tr>
                                                <td width="50%" style={{fontSize:fontSize}}>&nbsp;</td>
                                                <td width="50%" style={{textAlign:'right',fontSize:fontSize}}>{`${addUnitToDistance(0,sessionInfo.unit)}`}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <GetCueText modifierType="" name={`${(pointComp.points[0].title??'START')}`} t={t} />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* {polylineArr.filter((one)=>(one[2]||one[3].name!=='')).map((one,key)=>{ */}
                            {polylineArr.filter((one)=>(one[2]||modifierList.includes(one[3].modifier))).map((one,key)=>{
                                //以下2要素を使用してターンマーカーを表示する
                                // one[3].modifier : 方向
                                // one[3].name : 交差点名または通りの名前 ( ブランクあり )
                                // console.log(`modifier=${one[3].modifier}, name=${one[3].name}`)
                                // const modifierType =
                                //     one[3].modifier.includes('left')?'left':
                                //     (one[3].modifier.includes('right')?'right':
                                //     (one[3].modifier.includes('straight')?'straight':one[3].modifier));
                                const {newIsTurn,newModifier} = detectIsTurn(one[3].modifier);
                                const modifierType = newIsTurn?newModifier:one[3].modifier;
                                const markerKey = `key_${key}`;
                                const selected = (popuoOpen.open && (popuoOpen.target === markerKey));
                                return (
                                    <div key={key} className='right-cuesheet-row' data-row-id={markerKey}>
                                        <div className={`${cellClassName} ${selected?'right-cuesheet-cell-turn-selected':'right-cuesheet-cell-turn'}`} data-id={markerKey} data-lat={one[0]} data-lng={one[1]} onClick={handleClickCue}>

                                            <table style={{width:'100%'}}>
                                                <tbody>
                                                    <tr>
                                                        <td width="50%" style={{fontSize:fontSize}}><CustomIcon type={modifierType} /></td>
                                                        <td width="50%" style={{textAlign:'right',fontSize:fontSize}}>{`${addUnitToDistance(one[4],sessionInfo.unit)}`}</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} style={{wordBreak:'break-all'}}>
                                                            {/* {`${getCueText(modifierType,one[3].name,t)}`} */}
                                                            <GetCueText modifierType={modifierType} name={one[3].name} t={t} />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            })}
                            {(polylineArr.length > 1) &&
                                <div className='right-cuesheet-row' style={{marginBottom:'0px'}}>
                                    <div className={`${cellClassName} right-cuesheet-cell-end`} data-id="key_last" data-lat={polylineArr[polylineArr.length - 1][0]} data-lng={polylineArr[polylineArr.length - 1][1]} onClick={handleClickCue}>

                                        <table style={{width:'100%'}}>
                                            <tbody>
                                                <tr>
                                                    <td width="50%" style={{fontSize:fontSize}}>&nbsp;</td>
                                                    <td width="50%" style={{textAlign:'right',fontSize:fontSize}}>{`${addUnitToDistance(polylineArr[polylineArr.length - 1][4],sessionInfo.unit)}`}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}>
                                                        {/* {`${getCueText(modifierType,one[3].name,t)}`} */}
                                                        <GetCueText modifierType="" name={`${(polylineArr[polylineArr.length - 1][3].name===''?'END':polylineArr[polylineArr.length - 1][3].name)}`} t={t} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    }
                </React.Fragment>
            </div>
        }
        </React.Fragment>
    )

})

function addUnitToDistance(distance:number,unit:string){
    if(unit==='km'){
        let RND1 = Math.round((distance/1000)*10)/10;
        return `${RND1} km`
        // let RND1 = Math.round((distance/1000)*10)/10;
        // let RND2 = Math.round((distance/1000)*100)/100;
        // if(RND1 > 0){
        //     return `${RND1} km`
        // }else if(RND2 > 0){
        //     return `${RND2} km`
        // }else{
        //     return `${RND1} km`
        // }
    }else if(unit ==='mile'){
        let RND1 = Math.round(((distance*0.621371192)/1000)*10)/10;
        return `${RND1} mile`
        // let RND1 = Math.round(((distance*0.621371192)/1000)*10)/10;
        // let RND2 = Math.round(((distance*0.621371192)/1000)*100)/100;
        // if(RND1 > 0){
        //     return `${RND1} mile`
        // }else if(RND2 > 0){
        //     return `${RND2} mile`
        // }else{
        //     return `${RND1} mile`
        // }
    }else{
        return ''
    }
}

function GetCueText({modifierType,name,t}:{modifierType:string,name:string,t:any}){
    const {sessionInfo} = React.useContext(SessionContext);
    const {cueWidthMode} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const fontSize = (cueWidthMode==='middle'?outerGridWidth.mobile.middle.fontSize:(cueWidthMode==='small'?outerGridWidth.mobile.small.fontSize:undefined));
    if(modifierListTurn.includes(modifierType)){
        let popupModifier = t('map.popup.' + modifierType);
        if(popupModifier === ('map.popup.' + modifierType)){
            popupModifier = modifierType;
        }
        return (
            <React.Fragment>
                <span className={GoogleFonts.className} style={{fontSize:fontSize,fontWeight:'bold'}}>{popupModifier}</span>
                <span className={GoogleFonts.className} style={{fontSize:fontSize}}>{`${(name?` (${name})`:'')}`}</span>
            </React.Fragment>
        )
    }else{
        let modifier = '';
        if(modifierList.includes(modifierType)){
            modifier = t(`routeEditor.dialog.edit.modifier.${modifierType}`);
        }else{
            modifier = modifierType;
        }
        let message = '';
        if(modifier === ''){
            message = name??'';
        }else{
            message = modifier + (name?('('+name+')'):'');
        }
        return (
            <React.Fragment>
                <span className={GoogleFonts.className} style={{fontSize:fontSize}}>{`${message}`}</span>
            </React.Fragment>
        )
    }
}
