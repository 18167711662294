import FitParser from 'fit-file-parser';

import { typeImportDialogOpen } from '../../UI/RouteEditor/RouteEditorColumnLeft';
import { typeControlPoint, typePointComp } from '../RouteData';

export function parseFitFile(
    buffer:ArrayBuffer,
    setPointComp:React.Dispatch<React.SetStateAction<typePointComp>>,
    setImportDialogOpen:React.Dispatch<React.SetStateAction<typeImportDialogOpen>>
){
    const fitParser = new FitParser({
        force: true,
        speedUnit: 'km/h',
        lengthUnit: 'km',
        temperatureUnit: 'celcius',
        elapsedRecordField: true,
        mode: 'list',
    });

    fitParser.parse(buffer,function(error: any, data: any){
        if (error) {
            console.log(error);
            setImportDialogOpen({open:false,imported:false,confirm:false});
        } else {
            console.log(data);
            // setFileData(data);
            const cycroute = convertFitToCommon(data);
            // console.log(cycroute);
            setPointComp({
                routeInfo:{
                    id:-1,
                    isPublic:true,
                    title:"no title",
                    description:"",
                    counter:(cycroute.length + 1)
                },
                points:cycroute,
                historyUndo:cycroute.map((one)=>{
                    return {...one,...{type:"point"}}
                }),
                historyRedo:[]
            });
            setImportDialogOpen({open:false,imported:true,confirm:false});
            // console.log('complete');
        }
    });
}

export function convertFitToCommon(fit:any){
    let pathPoint:any[] = [];
    let controlPoint:typeControlPoint[] = [];
    let cnt = 0;
    let start = 0;

    if(fit.course_points.length > 0){
        //コントロールポイントがある場合
        for(let record of fit.records){
            let pos = start;
            let found = -1;
            let loop = ((fit.course_points.length - 1) >= pos);
            while(loop){
                if(
                    (record.position_lat === fit.course_points[pos].position_lat) &&
                    (record.position_long === fit.course_points[pos].position_long)
                ){
                    found = pos;
                    loop = false;
                    start = pos + 1;
                }
                if(loop){
                    loop = ((fit.course_points.length - 1) >= (pos + 1));
                    if(loop){
                        pos++;
                    }
                }
            }
            if(found >= 0){
                pathPoint.push([
                    record.position_lat,
                    record.position_long,
                    (cnt > 0),
                    {modifier:fitTypeConvrter(fit.course_points[pos].type),
                    name:fit.course_points[pos].name}
                ]);
                controlPoint.push({
                    lat:record.position_lat,
                    lng:record.position_long,
                    use:"bicycle",
                    mode:(cnt === 0)?"start":"",
                    // title:fit.course_points[pos].name,
                    title:(cnt === 0)?"START":fit.course_points[pos].name,
                    detail:"",
                    fromLast:(cnt === 0)?[]:pathPoint,
                    counter:controlPoint.length
                });
                pathPoint = [];
            }else{
                pathPoint.push([
                    record.position_lat,
                    record.position_long,
                    false,
                    {modifier:"",name:""}
                ])
            }
            cnt++;
        }
    }else{
        //コントロールポイントがない場合
        let setSize = 30; //分割単位
        let cnt = 1;
        fit.records.forEach((one:any,idx:number,arr:any[])=>{
            let recPos = idx + 1;
            let isLast = (recPos === arr.length);
            if(idx === 0){
                controlPoint.push({
                    lat:one.position_lat,
                    lng:one.position_long,
                    use:"bicycle",
                    mode:"start",
                    title:"START",
                    detail:"",
                    fromLast:[],
                    counter:cnt
                });
                cnt++;
            }
            pathPoint.push([
                one.position_lat,
                one.position_long,
                false,
                {modifier:"",name:""}
            ]);
            if(isLast || ((recPos % setSize) === 0)){
                controlPoint.push({
                    lat:one.position_lat,
                    lng:one.position_long,
                    use:"bicycle",
                    mode:"",
                    title:"",
                    detail:"",
                    fromLast:pathPoint,
                    counter:cnt
                });
                pathPoint = [];
            }

        })
    }
    return controlPoint;
}

function fitTypeConvrter(INPUT:string){
    if(INPUT === 'u_turn'){
        return 'uturn';
    }else{
        return INPUT;
    }
}