import {
    Box, Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { useTranslation } from "react-i18next";

import { adjustFontPosition, getLanguageObj } from '../../System/Locales';
import { RouteContext, typeControlPoint } from '../../System/RouteData';
import { SessionContext } from '../../System/Session';
import { detectIsTurn, modifierList } from '../Map/CustomMarkers';
import { getDistance } from './RouteEditor';

type typeTurnInfo = {
    modifier:string;
    name:string;
}
type typeData = {
    parentIdx:number;
    childIdx:number;
    lat:number;
    lng:number;
    isTurn:boolean;
    turnInfo:typeTurnInfo
}

// export const modifierListTurn:string[] = [
//     "left","right","straight","uturn"
// ];

// export const modifierListExt:string[] = [
//     "climb","food","information","rest","danger","other"
// ];

// export const modifierList:string[] = [
//     ...modifierListTurn,...modifierListExt
// ];

export default function RouteEditorEditDialog() {
    const {t} = useTranslation();
    const {sessionInfo} = React.useContext(SessionContext);
    const {editDialog,setEditDialog,pointComp,setPointComp} = React.useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [data,setData] = React.useState<typeData>({
        parentIdx:0,childIdx:0,lat:0,lng:0,isTurn:false,turnInfo:{modifier:"",name:""}
    });
    // const Counter = React.useRef(0);

    React.useEffect(()=>{
        // Counter.current++;
        // console.log(`CNT ${Counter.current} : A`)
        if((editDialog.parentIdx >= 0)&&(editDialog.childIdx >= 0)){
            // console.log(`CNT ${Counter.current} : B`)
            if(pointComp.points.length > editDialog.parentIdx){
                // console.log(`CNT ${Counter.current} : C`)
                if(pointComp.points[editDialog.parentIdx].fromLast.length > editDialog.childIdx){
                    // console.log(`CNT ${Counter.current} : D`)
                    // console.log("dialog: " + editDialog.lat + ", " + editDialog.lng);
                    // console.log("dialog native: " + editDialog.native.lat + ", " + editDialog.native.lng);
                    let temp = pointComp.points[editDialog.parentIdx].fromLast[editDialog.childIdx];
                    let found = false;
                    modifierList.forEach((one)=>{
                        if(temp[3].modifier.includes(one)){
                            temp[3].modifier = one;
                            found = true;
                        }
                    });
                    if(!found){
                        // temp[3].modifier = "other"; //マーカーが削除できなくなるのでmodifier不明のものを "other" にするのは廃止
                    }
                    setData((prev)=>({...prev,...{
                        parentIdx:editDialog.parentIdx,
                        childIdx:editDialog.childIdx,
                        lat:temp[0],
                        lng:temp[1],
                        isTurn:temp[2],
                        turnInfo:temp[3]
                    }}));
                }
            }
        }
    },[editDialog,pointComp]);

    // React.useEffect(()=>{
    //     console.log('data is',data);
    // },[data]);

    const handleClose = () => {
        setEditDialog((prev)=>({...prev,...{open:false}}));
    };
    const handleChangeModifier = (e:any) => {
        const {newIsTurn} = detectIsTurn(e.target.value);
        setData((prev)=>({...prev,...{
            isTurn:newIsTurn,
            turnInfo:{
                modifier:e.target.value,
                name:prev.turnInfo.name
            }
        }}));
    }
    const handleChangeName = (e:any) => {
        setData((prev)=>({...prev,...{
            turnInfo:{
                modifier:prev.turnInfo.modifier,
                name:e.target.value
            }
        }}));
    }
    const handleSave = (e:any) => {
        if(pointComp.points.length >= (data.parentIdx - 1)){
            const use = pointComp.points[data.parentIdx].use;
            if(use === 'line' && editDialog.isNew){
                // console.log('new point on line');
                // const pre = {...pointComp.points[data.parentIdx]};
                const pre = _.cloneDeep(pointComp.points[data.parentIdx]);
                console.log("pre",pre);
                setPointComp((prev)=>({
                    routeInfo:prev.routeInfo,
                    points:prev.points.map((one,idx1)=>{
                        if(idx1 === data.parentIdx){
                            const insertPoint = [
                                // editDialog.lat,
                                // editDialog.lng,
                                editDialog.native.lat,
                                editDialog.native.lng,
                                data.isTurn,
                                data.turnInfo
                            ];
                            // console.log("insertPoint",insertPoint);
                            const targetIndex = findIndex(one,insertPoint);
                            return {...one,...{fromLast:insertAt(one.fromLast,targetIndex,insertPoint)}}
                        }else{
                            return one
                        }
                    }),
                    historyUndo:[...prev.historyUndo,{
                        type:"points_replace",
                        indexes:{
                            parent:data.parentIdx
                        },
                        data:pre
                    }],
                    historyRedo:[]
                }));
            }else{
                const pre = [...pointComp.points[data.parentIdx].fromLast[data.childIdx]];
                setPointComp((prev)=>({
                    routeInfo:prev.routeInfo,
                    points:prev.points.map((one,idx1)=>{
                        if(idx1 === data.parentIdx){
                            return {...one,...{fromLast:one.fromLast.map((two,idx2)=>{
                                if(idx2 === data.childIdx){
                                    two[2] = data.isTurn;
                                    two[3] = data.turnInfo;
                                }
                                return two
                            })}}
                        }else{
                            return one
                        }
                    }),
                    historyUndo:[...prev.historyUndo,{
                        type:"point_update",
                        indexes:{
                            parent:data.parentIdx,
                            child:data.childIdx
                        },
                        data:pre
                    }],
                    historyRedo:[]
                }));
            }
        }
        handleClose();
    }
    const handleDelete = (e:any) => {
        if(pointComp.points.length >= (data.parentIdx - 1)){
            const pre = [...pointComp.points[data.parentIdx].fromLast[data.childIdx]];
            setPointComp((prev)=>({
                routeInfo:prev.routeInfo,
                points:prev.points.map((one,idx1)=>{
                    if(idx1 === data.parentIdx){
                        return {...one,...{fromLast:one.fromLast.map((two,idx2)=>{
                            if(idx2 === data.childIdx){
                                console.log('two pre=',two);
                                two[2] = false;
                                two[3] = {modifier:"",name:""};
                                console.log('two aft=',two);
                            }
                            return two
                        })}}
                    }else{
                        return one
                    }
                }),
                historyUndo:[...prev.historyUndo,{
                    type:"point_update",
                    indexes:{
                        parent:data.parentIdx,
                        child:data.childIdx
                    },
                    data:pre
                }],
                historyRedo:[]
            }));
        }
        handleClose();
    }
    return (
        <React.Fragment>
            <Dialog open={editDialog.open} fullWidth maxWidth="sm" onClose={(e,reason)=>{
                if(reason!=='backdropClick'){
                    handleClose()
                }
            }}>
                <DialogTitle className={GoogleFonts.className}>
                    <span style={styleBtnText}>{t('routeEditor.dialog.edit.title')}</span>
                </DialogTitle>
                <DialogContent sx={{paddingBottom:'0px'}}>
                    <input type="hidden" name="parent_idx" value={data.parentIdx} />
                    <input type="hidden" name="child_idx" value={data.childIdx} />
                    <FormControl sx={{marginTop:'6px'}}>
                        <InputLabel className={GoogleFonts.className}>{t('routeEditor.dialog.edit.labelType')}</InputLabel>
                        <Select value={data.turnInfo.modifier} onChange={handleChangeModifier} sx={{minWidth:'120px'}}
                        input={<OutlinedInput className={GoogleFonts.className} label={t('routeEditor.dialog.edit.labelType')} />} className={GoogleFonts.className}>
                            {modifierList.map((one,idx)=>{
                                return <MenuItem key={idx} className={GoogleFonts.className} value={one}>{t(`routeEditor.dialog.edit.modifier.${one}`)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <Box>
                        <TextField sx={{marginTop:'24px',wordBreak:'break-all'}} className={GoogleFonts.className} onChange={handleChangeName} variant="outlined" margin="dense" id="text" name="text" label={t('routeEditor.dialog.edit.labelText')} type="Text" fullWidth multiline rows={3} value={data.turnInfo.name} />
                    </Box>
                </DialogContent>
                <DialogActions sx={{paddingTop:'14px',paddingBottom:'14px'}}>
                    {!editDialog.isNew &&
                        <Box marginRight="auto">
                            <Button className={GoogleFonts.className} onClick={handleDelete} variant='contained' color="error">
                                <span style={styleBtnText}>{t('routeEditor.dialog.edit.btnDelete')}</span>
                            </Button>
                        </Box>
                    }
                    <Button className={GoogleFonts.className} onClick={handleClose} variant='outlined'>
                        <span style={styleBtnText}>{t('routeEditor.dialog.edit.btnCancel')}</span>
                    </Button>
                    <Button className={GoogleFonts.className} onClick={handleSave} variant='outlined'>
                        <span style={styleBtnText}>{t('routeEditor.dialog.edit.btnSave')}</span>
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

function findIndex(plot:typeControlPoint,insertPoint:any[]){
    let targetIndex = 0;
    const targetDistance = getDistance(plot.lat,plot.lng,insertPoint[0],insertPoint[1]);
    let arr:any[] = [];
    plot.fromLast.forEach((one,i)=>{
        let distance = getDistance(plot.lat,plot.lng,one[0],one[1]);
        if(distance > targetDistance){
            targetIndex = i + 1;
        }
        arr.push({index:i,distance:distance})
    });
    // console.log("targetDistance="+targetDistance+",targetIndex="+targetIndex+",arr",arr);
    return targetIndex;
}

function insertAt<T>(array: T[], index: number, element: T): T[] {
    array.splice(index, 0, element);
    return array;
}
