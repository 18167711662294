import { Box, Button, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

import { GoogleSeriousFontClassName, LanguagePulldown, adjustFontPosition, getLanguageObj } from '../System/Locales';
import { SessionContext } from '../System/Session';
import { setTitle } from '../System/Title';
import { themeBtnAll } from './RouteEditor/RouteEditorColumnRight';

const commonShadow = '0 0 4px #000000,0 0 4px #000000,0 0 5px #000000,0 0 7px #000000,0 0 9px #000000,2px 2px 7px #000000';

const topLinkStyle = {
    color:'#ffffff',
    fontSize:'20px',
    textShadow:commonShadow,
    textDecoration:'underline',
    textUnderlineOffset:'3px',
    cursor:'pointer'
} as React.CSSProperties;

export default function PrivacyPolicy(){
    //背景切り替え
    if(isMobile&&!isTablet){
        document.body.classList.add('cycroute-mobile');
        document.body.classList.remove('cycroute-pc');
    }else{
        document.body.classList.add('cycroute-pc');
        document.body.classList.remove('cycroute-mobile');
    }
    const {t} = useTranslation();
    setTitle(t('privacy.title'));
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const GoogleFontEn = getLanguageObj('en'); //ミニロゴ用
    const navigate = useNavigate();
    const handleBack = () => {
        navigate('/')
    }
    const handleClose = () => {
        window.close();
    }
    const seriousFontClassName = (sessionInfo.language==='ja')?GoogleFonts.className:GoogleSeriousFontClassName;
    return (
        <React.Fragment>
            <Box width="100vw" display="flex" flexDirection="column" sx={{backgroundColor:'transparent'}}>

            <Box sx={{position:'absolute',top:'0px',left:'0px',width:'100%',height:'100%',backgroundColor:'rgba(0, 0, 0, 0.6)',zIndex:-1}}>&nbsp;</Box>

                <Box sx={{width:(isMobile&&!isTablet)?'300px':'420px',margin:'24px auto 0px auto',textAlign:'left'}} className="no-print">
                    <span onClick={handleBack} className={GoogleFontEn.className} style={topLinkStyle}>Cycroute</span>
                </Box>

                <Box sx={{width:(isMobile&&!isTablet)?'300px':'420px',margin:'60px auto 00px auto',position:'relative'}}>
                    <div className={`cycroute-serious-text ${seriousFontClassName}`}>
                        <div style={{fontSize:'1.5em',fontWeight:'bold',marginBottom:'30px'}}>{t('privacy.title')}</div>
                        <span style={{fontSize:'1.2em',lineHeight:'1.8em'}}>{t('privacy.message')}</span>
                    </div>
                    <Grid container spacing={0.7} columns={{xs:12}} sx={{marginTop:'60px'}}>
                        <Grid item xs={6}>
                            <LanguagePulldown />
                        </Grid>
                        <Grid item xs={6}>
                            <ThemeProvider theme={themeBtnAll}>
                                <Button className='no-print' sx={{position:'absolute',right:'0px'}} size="large" variant="contained" onClick={handleClose}>
                                    <span className={GoogleFonts.className} style={styleBtnText}>{t('privacy.btnClose')}</span>
                                </Button>
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{width:'10px',height:'40px'}}></Box>

            </Box>
        </React.Fragment>
    )
}