// import {isMobile,isTablet} from "react-device-detect";

//スワイプと判定するための左右移動
// export const swipeBorderMove = 60;
export const swipeBorderMove = 40;
// export const swipeBorderMove = 30;

//ルートエディタの左右カラムの幅
export const outerGridWidth = {
    left:260,
    right:260,
    mobile:{
        middle:{
            right:180,
            fontSize:'0.88em'
        },
        small:{
            right:120,
            fontSize:'0.8em'
        }
    }
}

//LeafletのPolyLineの色
export const polyLineStyle = {
    default:{
        color:'#E92B0D',
        // opacity:.9,
        weight: 4
    },
    hover:{
        // color:'#0EB700',
        //↓利便性は無いのでとりあえずhoverでは変えないように修正
        color:'#E92B0D',
        opacity: 0.7,
        weight: 6
    }
}
