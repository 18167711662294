import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from "react-i18next";

import CloseIcon from '@mui/icons-material/Close';
import { Alert, AppBar, Box, Button, Checkbox, Dialog, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, Radio, RadioGroup, Slide, Toolbar, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import SimpleBar from 'simplebar-react';

import { isMobile, isTablet } from 'react-device-detect';
import { DatetimeFormatPulldown, LanguagePulldown, TimezonePulldown, adjustFontPosition, fontDetect } from '../../System/Locales';
import { SessionContext, baseUri, sessionIO } from '../../System/Session';
import { saveDefaultLocation } from '../RouteEditor/RouteEditor';
import { themeBtnAll } from '../RouteEditor/RouteEditorColumnLeft';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type typeSettingsDialog = {
  settingsOpen:boolean;
  setSettingsOpen:React.Dispatch<React.SetStateAction<boolean>>
}

export default function SettingsHasSession({settingsOpen,setSettingsOpen}:typeSettingsDialog) {
  const {t} = useTranslation();
  const {sessionInfo,setSessionInfo} = useContext(SessionContext);
  const [isLefty,setIsLefty] = React.useState<boolean>(sessionInfo.isLefty);
  const GoogleFonts = fontDetect(sessionInfo.language);
  const isFirstLoad = React.useRef(false);
  React.useEffect(() => {
    if(!isFirstLoad.current){
      isFirstLoad.current = true;
    }else{
      if(!settingsOpen){
        setSessionInfo((prev)=>({...prev,...{isLefty:isLefty}}));
      }else{
        setIsLefty(sessionInfo.isLefty);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[settingsOpen]);
  const handleClose = () => {
    setSettingsOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={settingsOpen} onClose={handleClose} TransitionComponent={Transition}>
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex={1} sx={{overflow:'auto'}}>
            <DialogInner isLefty={isLefty} setIsLefty={setIsLefty} />
          </Box>
          <AppBar sx={{position:'relative',backgroundColor:'#777777'}}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography sx={{ml:2,flex:1}} variant="h6" component="div">
                <span className={GoogleFonts.className}>{t("top.btnSettings")}</span>
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

function DialogInner({
  isLefty,setIsLefty
}:{
  isLefty:boolean,
  setIsLefty:React.Dispatch<React.SetStateAction<boolean>>
}){
  const {t} = useTranslation();
  const {sessionInfo,setSessionInfo,setBackdrop} = useContext(SessionContext);
  const GoogleFonts = fontDetect(sessionInfo.language);
  const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
  const handleChangeSwapmenu = (event:React.ChangeEvent<HTMLInputElement>) => {
    // console.log('event.target.checked',event.target.checked);
    let isLeftyChecked = event.target.checked;
    setIsLefty(isLeftyChecked);
    if(isLeftyChecked){
      updateRouteSetting("islefty","on");
    }else{
      updateRouteSetting("islefty","off");
    }
  }
  const handleChangeStraight = () => {
    let skipStraight = !sessionInfo.skipStraight;
    setSessionInfo((prev)=>({...prev,...{skipStraight:skipStraight}}));
    if(skipStraight){
      updateRouteSetting("skipstraight","on");
    }else{
      updateRouteSetting("skipstraight","off");
    }
  }
  const handleChangeDbouleClick = () => {
    let disableDoubleClick = !sessionInfo.disableDoubleClick;
    setSessionInfo((prev)=>({...prev,...{disableDoubleClick:disableDoubleClick}}));
    if(disableDoubleClick){
      updateRouteSetting("disabledoubleclick","on");
    }else{
      updateRouteSetting("disabledoubleclick","off");
    }    
  }
  const handleChangeUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUnit = (event.target as HTMLInputElement).value;
    setSessionInfo((prev)=>({...prev,...{unit:newUnit}}));
    updateRouteSetting("unit",newUnit);
  }
  const handleGPS = () => {
    navigator.geolocation.getCurrentPosition(funcGPS);
  }
  const funcGPS = (GPS:GeolocationPosition) => {
    // console.log(GPS.coords.latitude,GPS.coords.longitude)
    setSessionInfo((prev)=>({...prev,...{defaultLocation:{
      lat:GPS.coords.latitude,
      lng:GPS.coords.longitude,
    }}}));
    saveDefaultLocation(GPS.coords.latitude,GPS.coords.longitude,setBackdrop);
  }

  return (
    <SimpleBar style={{height:'100%',width:'100%',overflowY:'auto'}}
    onTouchStart={(e)=>e.stopPropagation()} onTouchMove={(e)=>e.stopPropagation()} onTouchEnd={(e)=>e.stopPropagation()}>

      <Box sx={{width:'180px',margin:'16px',marginTop:'30px'}} >
        <LanguagePulldown />
      </Box>

      <Box sx={{marginLeft:'24px',marginTop:'30px',marginBottom:'0px'}} >
        <FormControl style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <FormLabel sx={{marginRight:'32px'}}><span className={GoogleFonts.className}>{t("map.settings.unit")}</span></FormLabel>
          <RadioGroup row value={sessionInfo.unit} onChange={handleChangeUnit}>
            <FormControlLabel value="km" control={<Radio />} label={
              <span className={GoogleFonts.className}>km&nbsp;</span>
            } />
            <FormControlLabel value="mile" control={<Radio />} label={
              <span className={GoogleFonts.className}>mile</span>
            } />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box sx={{margin:'16px 16px 42px 16px'}}>
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={isLefty} onChange={handleChangeSwapmenu} />} label={
              <span className={GoogleFonts.className}>{t("map.settings.swapSideMenu")}</span>
            } sx={{margin:'0px'}} />
          </FormGroup>
          <Alert severity="info" sx={{marginTop:'5px'}}><span className={GoogleFonts.className}>{t("map.settings.swapSideMenuAlert")}</span></Alert>
      </Box>

      <Box sx={{position:'relative',margin:'16px',padding:'20px',borderRadius:'6px',border:'1px solid #bcbcbc'}}>

        <Box sx={{position:'absolute',top:'-11px',left:'50%',transform:'translateX(-50%)',backgroundColor:'#ffffff',padding:'0 10px'}} ><span className={GoogleFonts.className}>{t("routeEditor.title")}</span></Box>

        <Box sx={{marginBottom:'20px'}} >
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={sessionInfo.skipStraight} onChange={handleChangeStraight} />} label={
              <span className={GoogleFonts.className}>{t("map.settings.skipStraight")}</span>
            } sx={{margin:'0px'}} />
          </FormGroup>
          <Alert severity="info" sx={{marginTop:'5px'}}><span className={GoogleFonts.className}>{t("map.settings.skipStraightAlert")}</span></Alert>
        </Box>
        <Box sx={{marginBottom:'20px'}} >
          <FormGroup>
            <FormControlLabel control={<Checkbox checked={sessionInfo.disableDoubleClick} onChange={handleChangeDbouleClick} />} label={
              <span className={GoogleFonts.className}>{t("map.settings.disableDoubleclick")}</span>
            } sx={{margin:'0px'}} />
          </FormGroup>
          <Alert severity="info" sx={{marginTop:'5px'}}><span className={GoogleFonts.className}>{t("map.settings.disableDoubleclickAlert")}</span></Alert>
        </Box>
        <Box sx={{marginBottom:'0px'}} >
          <ThemeProvider theme={themeBtnAll}>
            <Button variant="contained" size="small" className={GoogleFonts.className} onClick={handleGPS}>
              {sessionInfo.language==='ja' &&
                <React.Fragment>
                  <span style={{...styleBtnText,...{lineHeight:(isMobile && !isTablet)?'1.5em':undefined}}}>
                    {t("map.settings.setDefaultLocation")}
                    {(isMobile && !isTablet) && <br /> }
                    {t("map.settings.setDefaultLocation2nd")}
                  </span>
                </React.Fragment>
              }
              {sessionInfo.language!=='ja' &&
                <span style={{...styleBtnText,...{lineHeight:(isMobile && !isTablet)?'1.5em':undefined}}}>
                  {t("map.settings.setDefaultLocation")}{t("map.settings.setDefaultLocation2nd")}
                </span>
              }
            </Button>
          </ThemeProvider>
        </Box>

      </Box>
      
      <Box sx={{margin:'16px',marginTop:'30px'}}>
        <Box>
          <span className={GoogleFonts.className} style={{...styleBtnText,...{fontSize:'0.9em'}}}>{t('common.timezone')}</span>
        </Box>
        <Box sx={{width:'240px',margin:'0px'}} >
          <TimezonePulldown />
        </Box>
      </Box>
      
      <Box sx={{margin:'16px',marginBottom:'40px'}}>
        <Box>
          <span className={GoogleFonts.className} style={{...styleBtnText,...{fontSize:'0.9em'}}}>{t('common.datetimeFormat')}</span>
        </Box>
        <Box sx={{width:'350px',margin:'0px'}} >
          <DatetimeFormatPulldown />
        </Box>
      </Box>

    </SimpleBar>
  )
}

function updateRouteSetting(target:string,newValue:string){
  //target: "islefty", newValue: "on" or "off"
  //target: "skipstraight", newValue: "on" or "off"
  //target: "disabledoubleclick", newValue: "on" or "off"
  let mySession = new sessionIO();
  let req = baseUri
    + "saveroutesettings?target=" + encodeURIComponent(target)
    + "&newvalue=" + encodeURIComponent(newValue);
  fetch(
      req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
  ).then(res => {
      // console.log('HTTP CODE : ' + res.status);
      return res.json();
  }).then(content => {
      // console.log('content : ',content);
  }).catch(err => {
      console.log('err : ',err);
  }).finally(() => {
  });

}