//ウインドウサイズの動的取得用コンポーネント

import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const {innerWidth:width,innerHeight:height} = window;
  const modeBorder = 1400;
  return{
    width,
    height,
    modeBorder
  };
}

export default function useWindowDimensions(){
    
  const [windowDimensions,setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize(){
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize',handleResize);
    return () => window.removeEventListener('resize',handleResize);
  },[]);

  return windowDimensions;

}