import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';

import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { isMobile, isTablet } from "react-device-detect";

import { adjustFontPosition, getLanguageObj, typeEachLnag } from '../../System/Locales';
import { ButtonSaveRoute, RouteContext } from '../../System/RouteData';
import { SessionContext, screenLoad } from '../../System/Session';
import Version from '../../System/Version';
import { outerGridWidth, swipeBorderMove } from '../Map/Casts';
import { typeSidesVisible } from '../Map/SideArrows';
import { styleInnerLeft, styleInnerRight, styleOuter } from './RouteEditorColumnStyle';

import { typeRouteOpt } from './RouteEditor';
import RouteEditorExit from './RouteEditorExit';
import RouteEditorImport from './RouteEditorImport';
import { RouteEditorRedo, RouteEditorUndo } from './RouteEditorUndoRedo';

import SettingsHasSession from '../Settings/SettingsHasSession';

import DoneIcon from '@mui/icons-material/Done';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const leftColumnWidth = outerGridWidth.left;


// const controlAreaMinHeight = (isMobile || isTablet)?555:485;
//↓チェックボックスを移動したので42px調整
// const controlAreaMinHeight = (isMobile || isTablet)?597:485;
const controlAreaMinHeight = (isMobile || isTablet)?528:690;
// const controlHeightBorder = (isMobile || isTablet)?[
//     // 493,563,633 //↓チェックボックスを移動したので42px調整
//     451,521,591
// ]:[
//     563,633,703 
// ];
//ページャ廃止に伴い定数も廃止

// const pagerDetectHeight = 773;
//↓チェックボックスを移動したので調整
// const pagerDetectHeight = 731;
//↓ページャが無くなるので一気に余裕が出るのでさらに修正
// const pagerDetectHeight = 680;
//ページャ廃止に伴い定数も廃止

const spacerHeight = 24;

export const baseButtonStyle = {
    fontSize:15,
    borderRadius:10,
    paddingTop:6,
    paddingBottom:6,
    border:'2px solid #acacac',
}

export const styleBtnAll = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: baseButtonStyle, 
                contained:{
                    color:'#232323',
                    backgroundColor:'#ffffff',
                    "&:hover":{
                        backgroundColor:'#e2e2e2',
                    }
                }
            }, 
        }, 
    }, 
};

export const themeBtnAll = createTheme(styleBtnAll);

export type typeImportDialogOpen = {
    open:boolean
    imported:boolean
    confirm:boolean
}

export default function RouteEditorColumnLeft(
    {setSidesVisible,routeOpt,setRouteOpt,isLefty,setBoundTrigger}:
    {
        setSidesVisible:React.Dispatch<React.SetStateAction<typeSidesVisible>>,
        routeOpt:typeRouteOpt,
        setRouteOpt:React.Dispatch<React.SetStateAction<typeRouteOpt>>,
        isLefty:boolean,
        setBoundTrigger:React.Dispatch<React.SetStateAction<boolean>>
    }){
    const navigate = useNavigate();
    const location = useLocation();
    const parentRef = useRef<HTMLInputElement>(null);
    const {t} = useTranslation();
    const {sessionInfo,setBackdrop} = useContext(SessionContext);
    const {pointComp,setPointComp,escTrigger} = useContext(RouteContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const GoogleFontsEn = getLanguageObj('en');
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const [exitDialogOpen,setExitDialogOpen] = useState<any>({exit:false});
    const [importDialogOpen,setImportDialogOpen] = useState<typeImportDialogOpen>({
        open:false,
        imported:false,
        confirm:false
    });

    const [settingsOpen,setSettingsOpen] = useState(false);
    const isFirstLoad = useRef(false);

    //ここからスワイプハンドリング
    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)
    const minSwipeDistance = swipeBorderMove;
    const onTouchStart = (e:any) => {
        setTouchEnd(null)
        setTouchStart(e.targetTouches[0].clientX)
    }
    const onTouchMove = (e:any) => setTouchEnd(e.targetTouches[0].clientX)
    const onTouchEnd = (e:any) => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if(isLeftSwipe){
            if(!sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{left:false}}));
            }
        }
        if(isRightSwipe){
            if(sessionInfo.isLefty){
                setSidesVisible((prev)=>({...prev,...{left:false}}));
            }
        }
    }
    //ここまでスワイプハンドリング

    const handleUndo = (event:React.MouseEvent<HTMLButtonElement>) =>{
        RouteEditorUndo(pointComp,setPointComp);
    }
    const handleRedo = (event:React.MouseEvent<HTMLButtonElement>) =>{
        RouteEditorRedo(pointComp,setPointComp);
    }
    const handleTitle = (e:any) =>{
        setPointComp((prev)=>({...prev,...{routeInfo:{...prev.routeInfo,...{title:e.target.value}}}}));
    }
    const handleClear = (event:React.MouseEvent<HTMLButtonElement>) =>{
        if(pointComp.points.length > 0){
            setPointComp((prev) => ({
                routeInfo:prev.routeInfo,
                points:[],
                historyUndo:[...prev.historyUndo,{
                    type:'clear',
                    points:pointComp.points
                }],
                historyRedo:[]
            }));
        }
    }
    const handleExit = () =>{
        if(
            (pointComp.points.length === 0) &&
            (pointComp.historyUndo.length === 0) &&
            (pointComp.historyRedo.length === 0)
        ){
            let pageFrom = screenLoad(location.pathname);
            if(pageFrom===''){
                pageFrom = '/'
            }
            navigate(pageFrom);
        }else{
            setExitDialogOpen((prev:any)=>({...prev,...{exit:true}}));
        }
    }
    const handleImport = (event:React.MouseEvent<HTMLButtonElement>) =>{
        let confirm = false;
        if(
            (pointComp.points.length === 0) &&
            (pointComp.historyUndo.length === 0) &&
            (pointComp.historyRedo.length === 0)
        ){
            //no confirm
        }else{
            confirm = true;
        }
        setImportDialogOpen({
            open:true,
            imported:false,
            confirm:confirm
        })
    }
    const handleHowto = (event:React.MouseEvent<HTMLButtonElement>) =>{
    }
    const handleSettings = (event:React.MouseEvent<HTMLButtonElement>) =>{
        setSettingsOpen(true);
    }
    useEffect(() => {
        if(!importDialogOpen.open && !importDialogOpen.confirm && importDialogOpen.imported){
            setImportDialogOpen((prev)=>({...prev,...{imported:false}}));
            setBoundTrigger((prev)=>(!prev)); //バウンドトリガ
            setBackdrop(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[importDialogOpen]);
    useEffect(() => {
        if(!isFirstLoad.current){
            isFirstLoad.current = true;
        }else{
            handleExit();
        }
        // eslint-disable-next-line 
    },[escTrigger]);
    const styleInner = isLefty?styleInnerRight:styleInnerLeft;
    return (
        <Box width={leftColumnWidth} height="100%" display="flex" sx={styleOuter} ref={parentRef}
        onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
            <ThemeProvider theme={themeBtnAll}>
                <Box width={leftColumnWidth} sx={styleInner}>
                    <Box height="100%" width="100%" display="flex" sx={{position:'relative',minHeight:`${controlAreaMinHeight}px`,margin:0,padding:0}}>
                        <Box sx={{height:"100%",width:"100%"}}>
                            <Box sx={{width:"100%",position:(isMobile && !isTablet)?'absolute':'relative',bottom:(isMobile && !isTablet)?'44px':null}}>
                            {!(isMobile || isTablet) &&
                                <React.Fragment>
                                    <Grid container spacing={0.7} columns={{xs:12}} sx={{marginTop:'8px'}}>
                                        <Grid item xs={6}>
                                            <Button title="CTRL + Z" fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleUndo}><span style={styleBtnText}>{t("routeEditor.btnUndo")}</span></Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button title="CTRL + Y" fullWidth variant="contained" size="small" className={GoogleFonts.className} onClick={handleRedo}><span style={styleBtnText}>{t("routeEditor.btnRedo")}</span></Button>
                                        </Grid>
                                    </Grid>
                                    <Box height={spacerHeight/2}>{/* spacer */}</Box>
                                </React.Fragment>
                            }
                            <Box height={spacerHeight/2}>{/* spacer */}</Box>
                            <TextField sx={{}} label={t("routeEditor.inputTitle")} id="outlined-size-small" size="small" fullWidth value={pointComp.routeInfo.title} onChange={handleTitle} />
                            {!(isMobile || isTablet) &&
                                <React.Fragment>
                                    <Box height={spacerHeight-10}>{/* spacer */}</Box>
                                    <Box width="100%" sx={{textAlign:'center'}}>
                                        <ModeRadio GoogleFonts={GoogleFonts} routeOpt={routeOpt} setRouteOpt={setRouteOpt} t={t}/>
                                    </Box>
                                </React.Fragment>
                            }
                            {!(isMobile || isTablet) &&
                                <Box width="100%">
                                    <AutocueCheck bottomMode={false} GoogleFonts={GoogleFonts} routeOpt={routeOpt} setRouteOpt={setRouteOpt} t={t} />
                                </Box>
                            }
                            <Box height={spacerHeight-10}>{/* spacer */}</Box>
                            <ButtonSaveRoute  fullWidth variant="contained" size="small" startIcon={<DoneIcon />} className={GoogleFonts.className} sx={{marginTop:'6px',height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}}>
                                <span style={styleBtnText}>{t("routeEditor.btnSave")}</span>
                            </ButtonSaveRoute>
                            <Box height={spacerHeight}>{/* spacer */}</Box>
                            <Button fullWidth variant="contained" size="small" className={GoogleFonts.className} sx={{marginTop:'6px',height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleClear}><span style={styleBtnText}>{t("routeEditor.btnClear")}</span></Button>
                            <Box height={spacerHeight}>{/* spacer */}</Box>
                            <Button fullWidth variant="contained" size="small" startIcon={<FileUploadIcon />} className={GoogleFonts.className} sx={{marginTop:'6px',height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleImport}><span style={styleBtnText}>{t("routeEditor.btnImport")}</span></Button>
                            <Box height={spacerHeight}>{/* spacer */}</Box>
                            <Button fullWidth variant="contained" size="small" startIcon={<QuestionMarkIcon />} className={GoogleFonts.className} sx={{marginTop:'6px',height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleHowto}><span style={styleBtnText}>{t("routeEditor.btnHowto")}</span></Button>
                            <Box height={spacerHeight}>{/* spacer */}</Box>
                            <Button fullWidth variant="contained" size="small" startIcon={<SettingsIcon />} className={GoogleFonts.className} sx={{marginTop:'6px',height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleSettings}><span style={styleBtnText}>{t("top.btnSettings")}</span></Button>
                            <Box height={spacerHeight}>{/* spacer */}</Box>
                            <Button fullWidth variant="contained" size="small" className={GoogleFonts.className} sx={{marginTop:'6px',height:'42px',fontSize:'16px !important',paddingLeft:'14px',paddingRight:'14px'}} onClick={handleExit}><span style={styleBtnText}>{t("routeEditor.btnExit")}</span></Button>
                            </Box>

                            <Box sx={{position:'absolute',left:72,bottom:5}}>
                                {/* <span className={GoogleFontsEn.className} style={{fontSize:'18px'}}>&copy;2024 Cycroute</span> */}
                                <Version className={GoogleFontsEn.className} />
                            </Box>

                        </Box>

                    </Box>
                </Box>
            </ThemeProvider>
            <RouteEditorExit dialogOpen={exitDialogOpen} setDialogOpen={setExitDialogOpen} t={t} />
            <RouteEditorImport dialogOpen={importDialogOpen} setImportDialogOpen={setImportDialogOpen} t={t} />
            <SettingsHasSession settingsOpen={settingsOpen} setSettingsOpen={setSettingsOpen} />
        </Box>
    )
}

export function ModeRadio({GoogleFonts,routeOpt,setRouteOpt,t}:{GoogleFonts:typeEachLnag,routeOpt:typeRouteOpt,setRouteOpt:React.Dispatch<React.SetStateAction<typeRouteOpt>>,t:any}) {
    const handleChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newMode = (event.target as HTMLInputElement).value;
        setRouteOpt((prev)=>({...prev,...{use:newMode}}));
    }
    return (
        <FormControl>
            <RadioGroup row value={routeOpt.use} onChange={handleChangeMode}>
                <FormControlLabel value="bicycle" control={<Radio />} label={
                    <span className={GoogleFonts.className}>{t("routeEditor.radioBicycle")}</span>
                } />
                <FormControlLabel value="line" control={<Radio />} label={
                    <span className={GoogleFonts.className}>{t("routeEditor.radioLine")}</span>
                } />
            </RadioGroup>
        </FormControl>
    );
}

export function AutocueCheck({bottomMode,GoogleFonts,routeOpt,setRouteOpt,t}:{bottomMode:boolean,GoogleFonts:typeEachLnag,routeOpt:typeRouteOpt,setRouteOpt:React.Dispatch<React.SetStateAction<typeRouteOpt>>,t:any}) {
    const handleChangeAutocue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRouteOpt((prev)=>({...prev,...{autocue:event.target.checked}}));
    }
    return (
        <FormGroup>
            <FormControlLabel control={<Checkbox checked={routeOpt.autocue} onChange={handleChangeAutocue} sx={{paddingLeft:(bottomMode?'0px':'auto'),paddingRight:(bottomMode?'5px':'auto')}} />} label={
                <span className={GoogleFonts.className} style={{fontSize:(bottomMode?'0.95em':'1em')}}>{t("routeEditor.checkAutocue")}</span>
            } sx={{margin:'0px auto'}} />
        </FormGroup>
    );
}
