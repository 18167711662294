import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useTranslation } from "react-i18next";

import L, { Map as LeafletMap } from "leaflet";
import 'leaflet.vectorgrid';
import postscribe from 'postscribe';
import * as React from 'react';
import { AttributionControl, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';
import 'maplibre-gl';
import 'maplibre-gl-leaflet';
import 'maplibre-gl/dist/maplibre-gl.css';

import { adjustFontPosition, getLanguageObj } from '../../System/Locales';
import { SessionContext, baseUri, sessionIO } from '../../System/Session';

//タイルレイヤーの型
type typeTileSystem = {
    name:string;
    nameDisp:string;
    attr:string;
    mode:'png'|'pmtiles'|'pbf'|'json'; // 'pmtiles' と 'pbf' は同じ
    url:string;
    copy:string;
};

//タイルレイヤー定義
const tileLayerArr:typeTileSystem[] = [
    {
        name:'default',
        nameDisp:'標準マップ',
        attr:'',
        mode:'json',
        url:'https://tiles.cycroute.com/styles/basic/style.json',
        copy:"<a href='https://leafletjs.com/' target='_blank'>Leaflet</a> | &copy; <a href='https://openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>"
    },{
        name:'bicycle',
        nameDisp:'自転車マップ',
        attr:'',
        mode:'json',
        url:'https://tiles.cycroute.com/styles/basicbicycle/style.json',
        copy:"<a href='https://leafletjs.com/' target='_blank'>Leaflet</a> | &copy; <a href='https://openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>"
    },{
        name:'english',
        nameDisp:'標準マップ(英語)',
        attr:'',
        mode:'json',
        url:'https://tiles.cycroute.com/styles/basicen/style.json',
        copy:"<a href='https://leafletjs.com/' target='_blank'>Leaflet</a> | &copy; <a href='https://openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>"
    },{
        name:'protomaps',
        nameDisp:'Protomaps',
        attr:'',
        mode:'pbf',
        url:'https://tiles.cycroute.com/data/simple/{z}/{x}/{y}.pbf',
        copy:"<a href='https://leafletjs.com/' target='_blank'>Leaflet</a> | <a href='https://protomaps.com/' target='_blank'>Protomaps</a> &copy; <a href='https://openstreetmap.org/copyright' target='_blank'>OpenStreetMap</a>"
    }
];

declare global {
    interface Window {
        protomapsL: any;
    }
}

//pmtiles用のスクリプト
// const protomapsLeafletUri = 'https://unpkg.com/protomaps-leaflet@latest/dist/protomaps-leaflet.min.js';
const protomapsLeafletUri = `${window.location.protocol}//${window.location.host}/js/protomaps-leaflet.min.js`;

export default function CycrouteTiles({map}:{
    map:LeafletMap|null
}){
    const {sessionInfo} = React.useContext(SessionContext);
    const [tileLayer,setTileLayer] = React.useState<typeTileSystem|null>(null);
    React.useEffect(()=>{
        const newLayer = tileLayerArr.filter((one)=>{
            return (one.name === sessionInfo.map)
        })
        setTileLayer((newLayer.length===0)?tileLayerArr[0]:newLayer[0])
        // eslint-disable-next-line
    },[sessionInfo.map]);
    return (
        <React.Fragment>
            {tileLayer !== null &&
                <React.Fragment>
                    {tileLayer.mode === 'png' && <CycroutePngTiles tileLayer={tileLayer} />}
                    {(tileLayer.mode === 'pmtiles' || tileLayer.mode === 'pbf') &&
                        <CycroutePmtiles tileLayer={tileLayer} map={map} />
                    }
                    {tileLayer.mode === 'json' && <CycrouteMapLibre tileLayer={tileLayer} map={map} />}
                </React.Fragment>
            }
        </React.Fragment>
    )
}

function CycroutePmtiles({tileLayer,map}:{
    tileLayer:typeTileSystem,
    map:LeafletMap|null
}){
    const [scriptLoaded, setScriptLoaded] = React.useState(false);
    React.useEffect(()=>{
        if (!scriptLoaded) {
            postscribe(
                '#root',
                `<script src=${protomapsLeafletUri}></script>`,{
                    done:()=>{
                        setScriptLoaded(true);
                    }
                }
            );
        }
        // eslint-disable-next-line
    },[]);
    React.useEffect(()=>{
        if (map && scriptLoaded && window.protomapsL) {
                const layer = window.protomapsL.leafletLayer({ url:tileLayer.url, theme: 'cycroute' });
            // console.log(layer);
            layer.addTo(map);
            return () => {
                map.removeLayer(layer);
            };
        }
        // eslint-disable-next-line
    },[map,scriptLoaded]);
    return <AttributionControl position="bottomright" />;
}

function CycroutePngTiles({tileLayer}:{
    tileLayer:typeTileSystem
}){
    return (
        <React.Fragment>
            <TileLayer attribution={tileLayer.attr} url={tileLayer.url} />
            <AttributionControl position="bottomright" prefix={tileLayer.copy} />
        </React.Fragment>
    )
}

function CycrouteMapLibre({tileLayer,map}:{
    tileLayer:typeTileSystem,
    map:LeafletMap|null
}){
    React.useEffect(() => {
        if(map){
            const maplibreLayer = (L as any).maplibreGL({
                style:tileLayer.url
            }).addTo(map);
            return () => {
                map.removeLayer(maplibreLayer);
            };
        }
        // eslint-disable-next-line
    }, [map,tileLayer]);
    // return null;
    return (
        <AttributionControl position="bottomright" prefix={tileLayer.copy} />
    )
};

export function CycrouteTilePulldown(){
    const {t} = useTranslation();
    const {sessionInfo,setSessionInfo} = React.useContext(SessionContext);
    const GoogleFont = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整
    const handleChangeTile = (e:SelectChangeEvent) => {
        const map = e.target.value;
        setSessionInfo((prev)=>({...prev,...{map:map}}));
        if(sessionInfo.hasSession){
            let mySession = new sessionIO();
            let req = baseUri + "setmap?map=" + encodeURIComponent(map);
            fetch(
                req,mySession.cookieEnabled?{}:{mode:'cors',headers:mySession.getHeader()}
            ).then(res => {
                // console.log('HTTP CODE : ' + res.status);
                return res.json();
            }).then(content => {
                // console.log('content : ',content);
            }).catch(err => {
                console.log('err : ',err);
            }).finally(() => {
            });
        }
    }
    return (
        <React.Fragment>
            <Box sx={{position:'absolute',top:'8px',left:'8px',width:'auto',height:'auto',textAlign:'left',zIndex:2100}}>
                <FormControl fullWidth sx={{color:'#000000',backgroundColor:'transparent'}} size="small">
                    <Select label="" value={sessionInfo.map} sx={{backgroundColor:'#ffffff','.MuiSelect-select':{padding:'3px 8px'}}} inputProps={{MenuProps:{MenuListProps:{sx:{color:'#000000',backgroundColor:'#ffffff'}}}}} onChange={handleChangeTile}>
                        {tileLayerArr.map((one,index) => {return (
                            <MenuItem key={index} value={one.name} sx={{color:'#000000',backgroundColor:'#ffffff'}}>
                                <span className={GoogleFont.className} style={{...styleBtnText,...{fontSize:'0.92em'}}}>{t(`common.tiles.${one.name}`)}</span>
                            </MenuItem>
                        )})}
                    </Select>
                </FormControl>
            </Box>
        </React.Fragment>
    )
}