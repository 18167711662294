import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

import { adjustFontPosition, getLanguageObj } from '../System/Locales';
import { SessionContext, baseUri, compareUID, sessionIO, syncSession, typeSession } from '../System/Session';
import { themeBtnAll } from '../UI/RouteEditor/RouteEditorColumnRight';
import { logoStyle } from '../UI/Signinup';
import { removeDB } from '../db/dbio';

import Google from '../image/oauth_logo_google.png';

export default function ButtonGoogleOauth({accept}:{accept:boolean}){
    const location = useLocation();
    const {t} = useTranslation();
    const {sessionInfo} = React.useContext(SessionContext);
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const styleBtnText = adjustFontPosition(sessionInfo.language); //フォントごとの上下位置微調整

    const handleClick = () => {
        window.location.href = getGoogleUrl();
    }
    return (
        <ThemeProvider theme={themeBtnAll}>
            <Button onClick={handleClick} sx={{marginTop:'15px'}} fullWidth variant="contained" size="large" className={GoogleFonts.className} disabled={!accept}>
                <img src={Google} style={logoStyle} alt='Google' />
                {location.pathname === '/signup' &&
                    <span style={styleBtnText}>{t('signup.btnWithGoogle')}</span>
                }
                {location.pathname === '/signin' &&
                    <span style={styleBtnText}>{t('signin.btnWithGoogle')}</span>
                }
            </Button>
        </ThemeProvider>
    );
};

function getGoogleUrl(){
    const GoogleEndpoint = {
        url:process.env.REACT_APP_OAUTH_GOOGLE_URL as string,
        clientId:process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_ID as string,
        redirect:process.env.REACT_APP_OAUTH_GOOGLE_REDIRECT as string,
        accessType:process.env.REACT_APP_OAUTH_GOOGLE_ACCESS_TYPE as string,
        responseType:process.env.REACT_APP_OAUTH_GOOGLE_RESPONSE_TYPE as string,
        scope:process.env.REACT_APP_OAUTH_GOOGLE_SCOPE as string,
        state:process.env.REACT_APP_OAUTH_GOOGLE_STATE as string,
    };
    const GoogleUrl = GoogleEndpoint.url
    + "?client_id=" + GoogleEndpoint.clientId
    + "&redirect_uri=" + encodeURIComponent(GoogleEndpoint.redirect)
    + "&access_type=" + GoogleEndpoint.accessType
    + "&response_type=" + GoogleEndpoint.responseType
    + "&scope=" + encodeURIComponent(GoogleEndpoint.scope)
    + "&state=" + GoogleEndpoint.state;
    return GoogleUrl;
}

function loginCycrouteWithGoogle(code:string,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>,
    sessionInfo:typeSession,
    setSessionInfo:React.Dispatch<React.SetStateAction<typeSession>>,
    navigate:NavigateFunction
){
    setBackdrop(true);
    console.log("code",code);
    let newKeys = {
        datetimeFormat:"datetime_format",
        defaultLocation:"default_location",
        disableDoubleClick:"disable_double_click",
        hasSession:"has_session",
        isLefty:"is_lefty",
        noAds:"no_ads",
        routeColor:"route_color",
        skipStraight:"skip_straight",
        timedOut:"timed_out"
    };
    let sessionData = renameKeys(sessionInfo, newKeys);
    // console.log("sessionData",sessionData);
    let mySession = new sessionIO();
    let req = baseUri + "oauthgoogle?code=" + code;
    fetch(
        req,mySession.cookieEnabled?{}:{
            mode:'cors',
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body: JSON.stringify(sessionData)
        }
    ).then(res => {
        // console.log('HTTP CODE : ' + res.status);
        return res.json();
    }).then(content => {
        // console.log('content : ',content);
        setBackdrop(false);
        if(content.success){
            let mySession = new sessionIO();
            mySession.save(content.sessionid);
            if(!compareUID(content.uid)){ //別ユーザがログインしたら一旦 indexed DB 削除
                removeDB();
            }
            syncSession(content,setSessionInfo);
            navigate('/')
        }else{
            navigate('/signin')
        }
    }).catch(err => {
        console.log('err : ',err);
        setBackdrop(false);
        navigate('/signin')
    }).finally(() => {
    });

}

export function CallbackGoogle(){
    //背景切り替え
    if(isMobile&&!isTablet){
        document.body.classList.add('cycroute-mobile');
        document.body.classList.remove('cycroute-pc');
    }else{
        document.body.classList.add('cycroute-pc');
        document.body.classList.remove('cycroute-mobile');
    }
    const {sessionInfo,setBackdrop,setSessionInfo} = React.useContext(SessionContext);
    const navigate = useNavigate();
    const GoogleFonts = getLanguageObj(sessionInfo.language);
    const search = useLocation().search;
    const [code,setCode] = React.useState<string>("");
    const [state,setState] = React.useState<string>("");
    const [incorrect,setIncorrect] = React.useState<boolean>(false);
    const getParams = () => {
        const query = new URLSearchParams(search);
        const newCode = query.get('code')??"";
        const newState = query.get('state')??"";
        setCode(newCode);
        setState(newState);
    }
    React.useEffect(() => {
        getParams();
        // eslint-disable-next-line
    },[])
    React.useEffect(() => {
        if((code !== "") && (state !== "")){
            const fixedState = process.env.REACT_APP_OAUTH_GOOGLE_STATE as string;
            if(state !== fixedState){
                //不正なリクエストのメッセージを出す
                setIncorrect(true);
            }else{
                console.log("code",code);
                console.log("state",state);
                loginCycrouteWithGoogle(code,setBackdrop,sessionInfo,setSessionInfo,navigate);
            }
        }
        // eslint-disable-next-line
    },[code,state])
    return (
        <React.Fragment>
            {incorrect &&
                <React.Fragment>
                    <span className={`cycroute-serious-text ${GoogleFonts.className}`} style={{fontSize:'2em'}}>Incorrect request.</span>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

function renameKeys(obj:any,newKeys:any) {
    const keyValues = Object.keys(obj).map(key => {
        const newKey = newKeys[key] || key;
        return { [newKey]: obj[key] };
    });
    return Object.assign({},...keyValues);
}
