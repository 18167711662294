//広告ブロックされているかチェック
export default async function checkAdBlocker(): Promise<boolean> {
    const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js'
    try {
        await fetch(new Request(googleAdUrl));
        return false;
    } catch (e) {
        return true;
    }
}

// // 使用例
// checkAdBlocker().then((isBlocked) => {
//     if (isBlocked) {
//         console.log('広告ブロッカーが検出されました');
//     } else {
//         console.log('広告ブロッカーは検出されませんでした');
//     }
// });
