import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import * as React from 'react';
import { isMobile, isTablet } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { CSSProperties } from 'styled-components';
import '../UI/Map/fontawesome.css';

import { adsHeight } from '../UI/Ads';
import { SessionContext } from './Session';

const URL = './version.json';

export type typeVersion = {
    major:number;
    minor:number;
    build:number;
}

export const localVersion:typeVersion = {
    major:Number(process.env.REACT_APP_VERSION_MAJOR??0),
    minor:Number(process.env.REACT_APP_VERSION_MINOR??0),
    build:Number(process.env.REACT_APP_VERSION_BUILD??0),
}

export type typeVersionObj = {
    local:typeVersion;
    remote:typeVersion;
    upgradable:boolean;
    alert:boolean;
}

const defaultSpanStyle = {

} as CSSProperties;

const boxPos = {
    top:10,
    left:10
}

const defaultBoxStyle = {
    zIndex:9999,
    fontSize:'1.1em',
    position:'fixed',
    left:boxPos.left,
    top:boxPos.top,
    backgroundColor:'#F9F3DF',
    padding:'10px 14px',
    border:'1px solid #8A8166',
    borderRadius:'6px',
    boxShadow:'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
};

const iconStyle = {
    position:'relative',
    top:'1px',
    marginRight:'5px',
    color:'#CE553B'
} as CSSProperties;

export default function Version(
    {style=defaultSpanStyle,className=""}:
    {style?:CSSProperties,className?:string}){
    const {version,setVersion} = React.useContext(SessionContext);
    const handleClick = () => {
        setVersion((prev)=>({...prev,...{alert:!prev.alert}}))
    }
    return (
        <React.Fragment>
            {!version.upgradable &&
                <React.Fragment>
                    <span className={className} style={style}>
                        <span>Version {version.local.major}.{version.local.minor}.{version.local.build}</span>
                    </span>
                </React.Fragment>
            }
            {version.upgradable &&
                <React.Fragment>
                    <span className={className} style={{...style,...{cursor:'pointer'}}} onClick={handleClick}>
                        <i style={iconStyle} className="fa-solid fa-triangle-exclamation"></i>
                        <span>Version {version.local.major}.{version.local.minor}.{version.local.build}</span>
                    </span>
                </React.Fragment>
            }
        </React.Fragment>
    )
}


const linkStyleBase = {
    textDecoration:'underline',
    textUnderlineOffset:'1px',
    cursor:'pointer',
} as CSSProperties;

const linkStyle = {...linkStyleBase,...{
    color:'#0000FF'
}} as CSSProperties;

const linkStyleHover = {...linkStyleBase,...{
    color:'red'
}} as CSSProperties;

export function VersionAlert(
    {style=defaultBoxStyle,message="",className=""}:
    {style?:SxProps,message?:string,className?:string}){
    const {t} = useTranslation();
    const {sessionInfo,version,setVersion} = React.useContext(SessionContext);
    const [hoverClose,setHoverClose] = React.useState<boolean>(false)
    const [hoverReload,setHoverReload] = React.useState<boolean>(false)
    const handleClose = () => {
        setVersion((prev)=>({...prev,...{alert:false}}))
    }
    const handleReload = () => {
        window.location.reload();
    }
    React.useEffect(() => {
        (async () => {
            //キャッシュ対策のランダムURL
            const data = await fetch(URL + '?uuid=' + getUID())
                .then(res => res.json())
                const upgradable = ((version.local.major !== data.major)||(version.local.minor !== data.minor)||(version.local.build !== data.build));
                setVersion((prev)=>({...prev,...{remote:data,upgradable:upgradable,alert:upgradable}}))
        })()
        // eslint-disable-next-line
    },[])
    
    if(!sessionInfo.noAds){
        if(isMobile && !isTablet){
            style = {...style,...{top:(adsHeight.small + boxPos.top)}}
        }else{
            style = {...style,...{top:(adsHeight.large + boxPos.top)}}
        }
    }
    let boxMessage = "";
    if(message===''){
        boxMessage = t('versionUp.message')
    }else{
        boxMessage = message;
    }
    return (
        <React.Fragment>
            {version.alert &&
                <Box sx={style} className={className}>
                    {boxMessage}
                    <Box>
                        {version.local.major}.{version.local.minor}.{version.local.build}
                        &nbsp;<i style={{position:'relative',top:'1px'}} className="fa-solid fa-caret-right"></i>&nbsp;
                        {version.remote.major}.{version.remote.minor}.{version.remote.build}
                    </Box>
                    <Box sx={{fontSize:'0.8em'}}>
                        <span style={(hoverClose?linkStyleHover:linkStyle)} onClick={handleClose} onMouseEnter={()=>setHoverClose(true)} onMouseLeave={()=>setHoverClose(false)}>{t('versionUp.btnClose')}</span>
                        &emsp;
                        <span style={(hoverReload?linkStyleHover:linkStyle)} onClick={handleReload} onMouseEnter={()=>setHoverReload(true)} onMouseLeave={()=>setHoverReload(false)}>{t('versionUp.btnReload')}</span>
                    </Box>
                </Box>
            }
        </React.Fragment>
    )
}

function getUID(){
    const date = new Date();
    const dateString = date.toISOString();
    const UID = dateString.replace(/[^0-9a-zA-Z]/gi,'') + uuidv4();
    return UID;
}

function uuidv4() {
    return "10000000100040008000100000000000".replace(/[018]/g, c =>
        ((+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & 15)) >> (+c / 4)).toString(16)
    );
}
